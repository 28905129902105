import React from 'react'
import TermsAndConditionsComp from '../../components/TermsAndConditions'
import MyDiv from './termsAndConditions.style'

const TermsAndConditions = React.memo((props) => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
  return (<MyDiv>
    <TermsAndConditionsComp />
  </MyDiv>)
})

export default TermsAndConditions
