import * as PaymentServices from '../../services/paymentServices'
import * as actionTypes from './actionsType'

const orderProcessRequest = () => {
  return {
    type: actionTypes.ORDER_PROCESS_REQUEST,
  }
}

const orderProcessFailure = (error) => {
  return {
    type: actionTypes.ORDER_PROCESS_FAILURE,
    payload: error,
  }
}

const orderProcessSuccess = (data) => {
  return {
    type: actionTypes.ORDER_PROCESS_SUCCESS,
    payload: data,
  }
}

export const orderProcessAction = (lang, codPaymentOption) =>
  async(dispatch) => {
    dispatch(orderProcessRequest())
    try {
      const responseData = await PaymentServices.orderProcess(lang, codPaymentOption)
      if (responseData?.status?.toUpperCase() === 'SUCCESS') {
        dispatch(orderProcessSuccess(responseData))
      } else {
        dispatch(orderProcessFailure(responseData))
      }
    } catch (error) {
      dispatch(orderProcessFailure(error))
    }
  }
const redirectionPaymentRequest = (data) => {
  return {
    type: actionTypes.REDIRECTION_PAYMENT_REQUEST,
    payload: data,
  }
}
export const redirectionPayment = () =>
  async(dispatch) => {
    dispatch(redirectionPaymentRequest('MO'))

  }
const paymentStatusRequest = (data) => {
  return {
    type: actionTypes.PAYMENT_STATUS_REQUEST,
    payload: data,
  }
}
export const paymentStatus = (data) =>
  async(dispatch) => {
    if (data === 'success') {dispatch(paymentStatusRequest(data))} else if (data === 'error') {
      dispatch(paymentStatusRequest(data))
    }

  }
const cleanUpPaymentRedirectionRequest = () => {
  return {
    type: actionTypes.CLEAN_UP_PAYMENT_REDIRECTION_REQUEST,
  }
}

export const cleanUpPaymentRedirection = () =>
  async(dispatch) => {
    dispatch(cleanUpPaymentRedirectionRequest())
  }

const cleanUpPaymentStatusRequest = () => {
  return {
    type: actionTypes.CLEAN_UP_PAYMENT_STATUS_REQUEST,
  }
}
export const cleanUpPaymentStatus = () =>
  async(dispatch) => {
    dispatch(cleanUpPaymentStatusRequest())
  }

const cleanUpPaymentFormRequest = () => {
  return {
    type: actionTypes.CLEAN_UP_PAYMENT_FORM_REQUEST,
  }
}


export const cleanUpPaymentForm = () =>
  async(dispatch) => {
    dispatch(cleanUpPaymentFormRequest())
  }

