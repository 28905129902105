import React, {useEffect, useState} from 'react'
import {} from '@material-ui/core/styles'
import {Grid, CircularProgress} from '@material-ui/core'
import * as userServices from '../../../services/userServices'
import {getLabelsBasedOnLanguage} from '../../../constants/common'
import MyDiv from './orders.style'

export const Orders = (props) => {
  const [ordersList, setOrdersList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    setIsLoading(true)
    userServices.getOrdersHistory(props.code).then((res) => {
      if (res.status === 'success') {
        setOrdersList(res.orders)
      }
    }).catch((e) => {
      console.log(e.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }, [])
  useEffect(() => {
    return () => {
      props.cleanUp()
    }
  }, [])
  return (
    <MyDiv>
      {(isLoading) && <div className="spinner">
        <CircularProgress style={{height: '35px', width: '35px', zIndex: 1000,
          position: 'absolute', top: '50%', left: '50%'}}
        />
      </div>
      }
      <div className="orderTitle">{getLabelsBasedOnLanguage('profile.myOrders.title', props.code)}</div>
      <div className="ordersList">
        {
          ordersList.length ? ordersList.map((order, index) => {
            const date = new Date(`${order.created_at} GMT`)
            let dayMonth = date.getDate()
            let dateString = dayMonth < 10 ? `0${dayMonth}` : dayMonth
            dayMonth = date.getMonth() + 1
            dateString += dayMonth < 10 ? `/0${dayMonth}/` : dayMonth
            dateString += date.getFullYear()
            return (
              <div key={`order_key_${index}`} className="orderRow orderMobile">
                <Grid container spacing={2}>
                  <Grid item xs={1} sm={2}>
                    {dateString}
                  </Grid>
                  <Grid item xs={2} sm={4}>
                    ID:{order.pos_order_id}
                  </Grid>
                  <Grid item xs={2} sm={3}>
                    €{parseFloat(order.total_amount).toFixed(2)}
                  </Grid>
                  <Grid item xs={2} sm={3}>
                    {order.type_of_order.toString() === '1' ? getLabelsBasedOnLanguage('cart.delivery', props.code)
                      : getLabelsBasedOnLanguage('cart.takeAway', props.code)}
                  </Grid>
                </Grid>
              </div>
            )
          }) : null
        }
      </div>
    </MyDiv>
  )
}

export default Orders
