import styled from 'styled-components'

const MyDiv = styled.div `
background-color: #FFFFFF;
padding-left: 97px;
padding-right: 97px;
text-align: left;
margin-top: 70px;
display: flex;
@media(max-width:767px){
    padding: 0px 15px;
  }
  @media(min-width:768px) and (max-width:1023px){
    padding: 10px 25px;
  }
.settingsList{
  width: 100%;
  display: inline-grid;
  height: fit-content;
  .label{
    //font-family: Proxima Nova;
    font-size: 18px;
    font-weight: 500;
    color: #262728;
    padding: 10px 51px;
    background-color: #F1F1F1;
    border-radius: 10px 10px 0 0;
    height: fit-content;
    @media(max-width:767px){
      padding: 15px 25px;
    }
    @media(min-width:768px) and (max-width:1023px){
      padding: 15px 25px;
    }
  }
  span{
    //-family: Proxima Nova;
    font-size: 18px;
    font-weight: 400;
    color: #000000;
    cursor: pointer;
    padding: 10px 51px;
     border: 1px solid #eee;
    height: fit-content;
    @media(max-width:767px){
      padding: 15px 25px;
      border: 1px solid #eee;
    }
    @media(min-width:768px) and (max-width:1023px){
      padding: 15px 25px;
    }
  }
  .selected{
    background-color: #ED4E53;
    color: #FFFFFF;
  }
  
}
.component{
  margin-left: 50px;
  width: 100%;
  @media(max-width:767px){
    margin-left: 0px;
    margin-top: 20px;
  }
  @media(min-width:768px) and (max-width:1023px){
    margin-left: 0px;
    margin-top: 20px;
  }
}
`
export default MyDiv
