import React from 'react'
import {
  Switch,
  Route, BrowserRouter,
} from 'react-router-dom'

import AppLayout from '../../config/layouts'
import * as routesNames from '../../constants/routes'
import {
  // SignIn,
  PaymentResponse,
  PaymentGateway,
  Settings,
  ProductPage,
  AboutUs,
  TermsAndConditions,
  PrivacyPolicy,
  ContactUs,
} from '..'
const Routes = () => {
  return (
    <BrowserRouter>
      <AppLayout>
        <Switch>
          <Route exact path={routesNames.LANDING} render={(props) => <ProductPage {...props} title="Navigate elsewhere" />} component={ProductPage} />
          <Route exact path={routesNames.PROFILE} component={Settings} />
          <Route exact path={routesNames.PAYMENTGATEWAY} component={PaymentGateway} />
          <Route exact path={routesNames.PAYMENTRESPONSE} component={PaymentResponse} />
          <Route exact path={routesNames.ABOUT_US} component={AboutUs} />
          <Route exact path={routesNames.TERMS_AND_CONDITIONS} component={TermsAndConditions} />
          <Route exact path={routesNames.PRIVACY_POLICY} component={PrivacyPolicy} />
          <Route exact path={routesNames.CONTACT_US} component={ContactUs} />
        </Switch>
      </AppLayout>
    </BrowserRouter>
  )
}

export default Routes
