import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Grid, Typography} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import {getLabelsBasedOnLanguage} from '../../../../constants/common'
import CustomInput from '../../../../components/inputs/textbox'
import CustomButton from '../../../../components/inputs/button'
// import SnackbarComponent from '../../../../components/feedback/snackbar/snackbar'
import ChangePasswordFormStyled from './changePassword.styles'


const ChangePasswordForm = (props) => {
  const {code} = props
  const [form, setForm] = useState({
    oldpassword: '',
    newpassword: '',
    confirmpassword: '',
  })
  useEffect(() => {
    return () => {
      props.cleanUp()
    }
  }, [])
  const handleChange = (e) => {
    let data = {
      ...form,
      [e.target.name]: e.target.value,
    }
    setForm(data)
  }

  const handleSignIn = () => {
    props.submit(form)
  }

  return (
    <ChangePasswordFormStyled>
      {props.isloading ? <div className="spinner">
        <CircularProgress style={{height: '40px', width: '40px', position: 'fixed', top: '50%', left: '49%'}} />
      </div> : null
      }
      <form>
        {/* <SnackbarComponent
          message={msg}
          severity={status}
          duration={1000}
          open={msg.length > 0}
          close={props.closeMsg}
        /> */}
        <Grid container>
          <Grid item lg={1} md={1} sm={1} xs={1} />
          <Grid item lg={10} md={10} sm={10} xs={10}>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12} className="center_element">
                <Typography
                  variant="h4"
                >
                  {getLabelsBasedOnLanguage('profilechangepassword.title', code)}
                </Typography>
                <br />
                <br />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} className="center_element">
                <Typography
                  variant="subtitle1"
                >
                  {getLabelsBasedOnLanguage('profilechangepassword.subtitle', code)}
                </Typography>
                <br />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <CustomInput
                  label={getLabelsBasedOnLanguage('inputs.labels.oldpassword', code)}
                  type="password"
                  name="oldpassword"
                  value={form.oldpassword}
                  multiple={false}
                  onChange={handleChange}
                  error={null}
                />
                {props.error && <span className="error">{props.error.old_password}</span>}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <CustomInput
                  label={getLabelsBasedOnLanguage('inputs.labels.newpassword', code)}
                  type="password"
                  name="newpassword"
                  value={form.newpassword}
                  multiple={false}
                  onChange={handleChange}
                  error={null}
                />
                {props.error && <span className="error">{props.error.new_password}</span>}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <CustomInput
                  label={getLabelsBasedOnLanguage('inputs.labels.confirmpassword', code)}
                  type="password"
                  name="confirmpassword"
                  value={form.confirmpassword}
                  multiple={false}
                  onChange={handleChange}
                  error={null}
                />
                {props.error && <span className="error">{props.error.confirm_new_password}</span>}
                <br />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <CustomButton
                  label={getLabelsBasedOnLanguage('inputs.buttons.savecontinue', code)}
                  onClick={handleSignIn}
                  size="large"
                  color="primary"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={1} />
        </Grid>
      </form>
    </ChangePasswordFormStyled>
  )
}

ChangePasswordForm.propTypes = {
  view: PropTypes.func,
  submit: PropTypes.func,
  code: PropTypes.string,
  status: PropTypes.string,
  msg: PropTypes.string,
  cleanUp: PropTypes.func,
  closeMsg: PropTypes.func,
  isloading: PropTypes.bool,
}

export default ChangePasswordForm
