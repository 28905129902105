import {createTheme} from '@material-ui/core/styles'


const appTheme = createTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif',
    ].join(','),
    fontSize: 14,
  },
  shape: {
    borderRadius: 6,
  },
  palette: {
    type: 'light', /// dark
    background: {
      default: '#fff',
    },
    primary: {
      main: '#ED4E53',
    },
    secondary: {
      main: '#FFFFFF',
    },
  },
  background: {
    paper: '#fff',
    default: '#fff',
  },
})


export default appTheme
