import * as actionsType from '../actions/actionsType'

const INITIAL_STATE = {
  orderprocess: {
    data: null,
    error: null,
    loading: false,
  },
  redirection: {
    type: null,
  },
  paymentstatus: {
    status: null,
  },
}

const paymentReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionsType.ORDER_PROCESS_REQUEST:
      return {
        ...state,
        orderprocess: {
          ...state.orderprocess,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionsType.ORDER_PROCESS_FAILURE:
      return {
        ...state,
        orderprocess: {
          ...state.orderprocess,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionsType.ORDER_PROCESS_SUCCESS:
      return {
        ...state,
        orderprocess: {
          ...state.orderprocess,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionsType.CLEAN_UP_PAYMENT_REDIRECTION_REQUEST:
      return {
        ...state,
        redirection: {
          type: null,
        },
      }
    case actionsType.CLEAN_UP_PAYMENT_STATUS_REQUEST:
      return {
        ...state.paymentstatus,
      }
    case actionsType.CLEAN_UP_PAYMENT_FORM_REQUEST:
      return {
        ...state,
        orderprocess: {
          ...state.orderprocess,
          error: null,
          data: null,
        },
      }
    case actionsType.REDIRECTION_PAYMENT_REQUEST:
      return {
        ...state,
        redirection: {
          type: action.payload,
        },
      }
    case actionsType.PAYMENT_STATUS_REQUEST:
      return {
        ...state,
        paymentstatus: {
          status: action.payload,
        },
      }
    default:
      return state
  }
}

export default paymentReducers
