/* eslint-disable max-len */
import React from 'react'
import {Carousel} from 'react-responsive-carousel'
import bannerImage4 from '../../images/IMG_1170.jpg'
import bannerImage5 from '../../images/IMG_1348.jpg'
import BannerStyled from './banner.style'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

function Banner(props) {
  function createBanner() {
    return (
      <Carousel interval={4500} autoPlay stopOnHover={false} infiniteLoop showArrows={false} showThumbs={false} showStatus={false}>
        <div>
          <img alt="Banner" src={bannerImage4} className="bannerImg" />
        </div>
        <div>
          <img alt="Banner" src={bannerImage5} className="bannerImg" />
        </div>
      </Carousel>
    )
  }

  return (
    <BannerStyled>
      {createBanner()}
    </BannerStyled>
  )
}

export default Banner
