import styled from 'styled-components'

const MyDiv = styled.div `
.toggleBtn{
  border-radius: 20px;
  width: 115px;
  text-align: center;
  padding: 7px 7px;
  display: inline-block;
  cursor: pointer;
  border: 1px solid #70707020;
  margin-right: 14px;
  //font-family: Proxima Nova;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  @media(max-width:767px){
    width: 100px;
    margin-right: 0px;
    font-size: 14px;
  }
}
.scroll {
  max-height: 545px;
  overflow-x: auto;
  padding: 4px 13px;
}
.scroll::-webkit-scrollbar {
  width: 8px;
}
.product-card-list{
  display:block!important;
}
.optionsGrid{
  background-color: #F9F9F9;
  border-radius: 20px;
  margin-top: 20px;
  padding: 2px 0 10px 22px;
}
.scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgba(0,0,0,0.3);
  border-radius: 20px;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
.addOrder{
  background-color: #ED4E53;
  border: none;
  color: #FFFFFF;
}

.productTitle{
  // font-family: Proxima Nova;
  font-size: 22px;
  font-weight: 600;
  color: #000000;
  display: block;
}
.productDescription{
  // font-family: Proxima Nova;
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  display: block;
}
.priceRow{
  margin-top: 46px;
  margin-bottom: 20px;
  display: flex;
  .incremental{
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select:none;
    user-select:none;
    -o-user-select:none;
    width: 98px;
    height: 32px;
    border-radius: 20px;
    border: 1px solid #70707020;
    text-align: center;
    margin-right: 28px;
    .incrementalIcon{
      cursor: pointer;
      width: 14px;
      height: 100%;
      display: inline-block;
      //font-family: Proxima Nova;
      font-size: 20px;
      font-weight: 400;
      color: #000000;
    }
    .incrementalQty{
      width: 50px;
      height: 100%;
      display: inline-block;
      border: 1px solid #70707020;
      border-top: 0;
      border-bottom: 0;
      //font-family: Proxima Nova;
      font-size: 16px;
      font-weight: 400;
      color: #000000;
      line-height: 2;
    }
  }
  .productPrice{
    //font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    width: 98px;
    height: 32px;
    border-radius: 20px;
    border: 1px solid #70707020;
    text-align: center;
    padding-top: 4px;
  }
}
.attributesOptions{
  height: 100%;
  margin-top: 41px;
  .attributesPadding{
    padding-left: 23px;
  }
  .attributeKey{
    //font-family: Proxima Nova;
    font-size: 20px;
    font-weight: 600;
    color: #000000;
  }
  .attributes{
    height: calc(100% - 30px - 30px - 25px);
    margin-top: 15px;
    width: 100%;
    padding-right: 22px;
  }
  .addOn{
    height:310px!important;
    overflow-x:auto;
  }
  .attributePrice{
    float: right;
    margin-top: 5px;
    font-size: 16px!important;
    font-weight: 400!important;
    color: #000000!important;
  }
  .attributeLabel{
    font-size: 16px!important;
    font-weight: 400!important;
    color: #000000!important;
  }
  span{
    //font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 400;
    color: #ED4E53;
  }
  svg{
    font-size: 1rem;
  }
}
`
export default MyDiv
