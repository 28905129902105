/* eslint-disable import/namespace */
import * as actionTypes from '../actions/actionsType'
const INITIAL_STATE = {
  addresses: {
    data: null,
    loading: false,
    error: null,
  },
}

const userReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.USER_ADDRESS_GET_REQUEST:
      return {
        ...state,
        addresses: {
          ...state.addresses,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.USER_ADDRESS_GET_SUCCESS:
      return {
        ...state,
        addresses: {
          ...state.addresses,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.USER_ADDRESS_GET_FAILURE:
      return {
        ...state,
        addresses: {
          ...state.addresses,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.USER_ADDRESS_CREATE_REQUEST:
      return {
        ...state,
        addresses: {
          ...state.addresses,
          loading: true,
          error: null,
        },
      }
    case actionTypes.USER_ADDRESS_CREATE_SUCCESS:
      return {
        ...state,
        addresses: {
          ...state.addresses,
          data: state.addresses.data ? [...state.addresses.data, action.payload] : [...action.payload],
          loading: false,
          error: null,
        },
      }
    case actionTypes.USER_ADDRESS_CREATE_FAILURE:
      return {
        ...state,
        addresses: {
          ...state.addresses,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.USER_ADDRESS_UPDATE_REQUEST:
      return {
        ...state,
        addresses: {
          ...state.addresses,
          loading: true,
          error: null,
        },
      }
    case actionTypes.USER_ADDRESS_UPDATE_SUCCESS:
    {
      const updatedAddress = action.payload
      const address = state.addresses.data
      let addressList = null
      if (address) {
        addressList = address.map((address) => {
          if (address.id === updatedAddress.id) {
            address = updatedAddress
          } else {
            address.is_primary = updatedAddress.is_primary ? 0 : address.is_primary
          }
          return address
        })
      } else {
        addressList = [{
          ...updatedAddress,
        }]
      }
      return {
        ...state,
        addresses: {
          ...state.addresses,
          data: addressList,
          loading: false,
          error: null,
        },
      }
    }
    case actionTypes.USER_ADDRESS_UPDATE_FAILURE:
      return {
        ...state,
        addresses: {
          ...state.addresses,
          loading: false,
          error: action.payload,
        },
      }
    default:
      return state
  }
}

export default userReducers
