export const USER_SIGIN_REQUEST = 'USER_SIGIN_REQUEST'
export const USER_SIGIN_FAILURE = 'USER_SIGIN_FAILURE'
export const USER_SIGIN_SUCCESS = 'USER_SIGIN_SUCCESS'

export const USER_SIGNUP_REQUEST = 'USER_SIGNUP_REQUEST'
export const USER_SIGNUP_FAILURE = 'USER_SIGNUP_FAILURE'
export const USER_SIGNUP_SUCCESS = 'USER_SIGNUP_SUCCESS'
export const USER_AUTH_VERIFICATUON_TYPE = 'USER_AUTH_VERIFICATUON_TYPE'

export const VERIFY_OTP_REQUEST = 'VERIFY_OTP_REQUEST'
export const VERIFY_OTP_FAILURE = 'VERIFY_OTP_FAILURE'
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS'

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST'
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'


export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST'
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'

export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST'
export const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE'
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS'

export const CLEAN_UP_STATE_REQUEST = 'CLEAN_UP_STATE_REQUEST'

export const USER_SIGNOUT_REQUEST = 'USER_SIGNOUT_REQUEST'
export const USER_SIGNOUT_FAILURE = 'USER_SIGNOUT_FAILURE'
export const USER_SIGNOUT_SUCCESS = 'USER_SIGNOUT_SUCCESS'

export const USER_SESSION_REQUEST = 'USER_SESSION_REQUEST'
export const USER_SESSION_FAILURE = 'USER_SESSION_FAILURE'
export const USER_SESSION_SUCCESS = 'USER_SESSION_SUCCESS'

export const CHANGE_PHONE_NUMBER_REQUEST = 'CHANGE_PHONE_NUMBER_REQUEST'
export const CHANGE_PHONE_NUMBER_FAILURE = 'CHANGE_PHONE_NUMBER_FAILURE'
export const CHANGE_PHONE_NUMBER_SUCCESS = 'CHANGE_PHONE_NUMBER_SUCCESS'

export const UPDATE_PROFILE_IMAGE_REQUEST = 'UPDATE_PROFILE_IMAGE_REQUEST'
export const UPDATE_PROFILE_IMAGE_FAILURE = 'UPDATE_PROFILE_IMAGE_FAILURE'
export const UPDATE_PROFILE_IMAGE_SUCCESS = 'UPDATE_PROFILE_IMAGE_SUCCESS'
