import React, {useState} from 'react'
import {Modal, Grid, FormControlLabel, Checkbox, IconButton} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
//import {makeStyles} from '@material-ui/core/styles'
import {getLabelsBasedOnLanguage} from '../../../../constants/common'
import MyDiv from './productCustomization.style'
import './custom.css'
// const useStyles = makeStyles((theme) => ({
//   paper: {
//     position: 'absolute',
//     overflow: 'auto',
//     width: '50%',
//     height: '90%',
//     backgroundColor: theme.palette.background.paper,
//     outline: 'none',
//     boxShadow: theme.shadows[5],
//     padding: '68px',
//     borderRadius: '20px',
//   },
//   root: {
//     display: 'flex',
//     alignItems: 'center',
//   },
//   wrapper: {
//     margin: theme.spacing(1),
//     marginTop: '20px',
//     position: 'relative',
//   },
//   buttonProgress: {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     marginTop: -12,
//     marginLeft: -12,
//   },
// }))

const ProductCustomizations = React.memo((props) => {
  const [customizations, setCustomizations] = useState({
    selected_attributes: props.product.selected_attributes,
    selectedAttributesList: props.product.selectedAttributesList || [],
    qty: parseInt(props.product.qty, 10),
    price: parseFloat(props.product.price) / parseInt(props.product.qty, 10),
  })
  const [showPreferences, setShowPreferences] = useState(false)
  //const classes = useStyles()
  const onCancel = () => {
    props.onCancel(null)
  }
  const onAddToCartClick = () => {
    props.onAddToCartClick({
      id: props.product.id,
      qty: customizations.qty,
      price: customizations.price,
      selectedIndex: props.product.selectedIndex,
      selected_attributes: customizations.selected_attributes,
      selectedAttributesList: customizations.selectedAttributesList,
    })
  }
  const incrementQty = (increment) => {
    const qty = customizations.qty + increment
    if (qty > 0) {
      setCustomizations({
        ...customizations,
        qty,
      })
    }
  }

  const onAttributesChange = (event, attribute, attributeGroup) => {
    const selectedAttributes = [...customizations.selected_attributes]
    const selectedAttributesList = [...customizations.selectedAttributesList]
    let price = customizations.price
    if (event.target.checked) {
      if (attributeGroup.selectOnlyOne) {
        attributeGroup.attributes.map((attributeItem, attributeItemIndex) => {
          if (selectedAttributes.includes(attributeItem.attribute_id)) {
            const index = selectedAttributes.findIndex((el) => el === attributeItem.attribute_id)
            const indexNames = selectedAttributesList.findIndex((el) => el.attribute_id === attributeItem.attribute_id)
            selectedAttributes.splice(index, 1)
            selectedAttributesList.splice(indexNames, 1)
            price = price - (attributeItem.price ? attributeItem.price : 0)
          }
        })
      }
      selectedAttributes.push(attribute.attribute_id)
      selectedAttributesList.push(attribute)
      price = price + (attribute.price ? attribute.price : 0)
    } else {
      const index = selectedAttributes.findIndex((el) => el === attribute.attribute_id)
      const indexNames = selectedAttributesList.findIndex((el) => el.attribute_id === attribute.attribute_id)
      selectedAttributes.splice(index, 1)
      selectedAttributesList.splice(indexNames, 1)
      price = price - (attribute.price ? attribute.price : 0)
    }
    setCustomizations({
      ...customizations,
      selected_attributes: [...selectedAttributes],
      selectedAttributesList: [...selectedAttributesList],
      price,
    })
  }
  const showCategoryDescription = () => {
    if (props.product && props.product.selectedType && props.product.selectedType.type
      && props.product.selectedType.included_fries) {
      return true
    }
    return false
  }
  const drawPreferencesRow = (attributeGroup, index, showHR) => {
    return (<div key={`attribute_group_${index}`}>
      {showHR && attributeGroup.attributes && attributeGroup.attributes.length ? <hr /> : null}
      {
        attributeGroup.attributes && attributeGroup.attributes.length && attributeGroup.attributes.map((attribute, attributeIndex) => {
          !showPreferences && setShowPreferences(true)
          return (
            showCategoryDescription() && attribute.hide_in_portion ? null : <div key={`attribute_group_${index}_${attributeIndex}`}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={customizations.selected_attributes.includes(attribute.attribute_id)}
                    onChange={(event) => onAttributesChange(event, attribute, attributeGroup)}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={<span className="attributeLabel">{attribute.attribute}</span>}
              />
              {attribute.price ? <span className="attributePrice">€{isNaN(attribute.price) ? 0.00 : parseFloat(attribute.price).toFixed(2)}</span> : null}
            </div>
          )
        })
      }
    </div>)
  }
  const drawPreferences = () => {
    let showHR = false
    const showCategoryDes = showCategoryDescription()
    return (
      <div className="attributes scroll addOn">
        {
          props.product.attributeGroups.map((attributeGroup, index) => {
            let attributeInRow = 0
            attributeGroup.attributes && attributeGroup.attributes.length && attributeGroup.attributes.map((attribute) => {
              if (!(showCategoryDes && attribute.hide_in_portion)) {attributeInRow++}
              return attributeInRow
            })
            const preferencesRow = attributeInRow ? drawPreferencesRow(attributeGroup, index, showHR) : null
            if (attributeInRow && !showHR && attributeGroup.attributes && attributeGroup.attributes.length) {
              showHR = true
            }
            return preferencesRow
          })
        }
      </div>
    )
  }
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{alignItems: 'center', justifyContent: 'center'}}
      open
    >
      <div className="mob-popup" style={{
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        position: 'absolute',
        overflow: 'auto',
        height: 'fit-content',
        borderRadius: '20px',
        backgroundColor: '#fff',
        outline: 'none',
      }}
      >
        <IconButton aria-label="close" onClick={() => onCancel()} style={{float: 'right'}}>
          <CloseIcon />
        </IconButton>
        <div className="set-on-devices">
          <div>
            <MyDiv>
              <span className="productTitle">
                {props.product.name}
                {props.product.selectedType && props.product.selectedType.type ? `-${props.product.selectedType.type}` : ''}
                {props.product.selectedSize && props.product.selectedSize.size ? `, ${props.product.selectedSize.size}` : ''}
              </span>
              {showCategoryDescription() && props.cartCategoryDescription && <span className="productDescription">{props.cartCategoryDescription}</span>}
              <Grid container className="product-card-list" spacing={4}>
                <div className="attributesOptions" style={{display: showPreferences ? 'block' : 'none'}}>
                  <span className="attributeKey attributesPadding">{getLabelsBasedOnLanguage('cart.preferences', props.code)}</span>
                  <Grid item xs={12} sm={12} md={12} lg={12} className="optionsGrid">
                    {drawPreferences()}
                  </Grid>
                </div>
              </Grid>
              <div className="priceRow">
                <div className="incremental">
                  <span className="incrementalIcon" onClick={() => incrementQty(-1)}>-</span>
                  <span className="incrementalQty">{customizations.qty}</span>
                  <span className="incrementalIcon" onClick={() => incrementQty(1)}>+</span>
                </div>
                <div className="productPrice">
                  €{(customizations.qty * customizations.price).toFixed(2)}
                </div>
              </div>
              <div>
                <span className="toggleBtn addOrder" onClick={() => onAddToCartClick()}>
                  {getLabelsBasedOnLanguage('cart.addToCart', props.code)}
                </span>
                <span className="toggleBtn" onClick={() => onCancel()}>
                  {getLabelsBasedOnLanguage('cart.cancel', props.code)}
                </span>
              </div>
            </MyDiv>
          </div>
        </div>
      </div>
    </Modal>)
})
export default ProductCustomizations
