import * as ProductServices from '../../services/productServices'
import * as actionTypes from './actionsType'

const getCategoriesRequest = () => {
  return {
    type: actionTypes.PRODUCT_CATEGORIES_REQUEST,
  }
}

const getCategoriesFailure = (error) => {
  return {
    type: actionTypes.PRODUCT_CATEGORIES_FAILURE,
    payload: error,
  }
}

const getCategoriesSuccess = (data) => {
  return {
    type: actionTypes.PRODUCT_CATEGORIES_SUCCESS,
    payload: data,
  }
}

export const getCategoriesAction = (lang) =>
  async(dispatch) => {
    dispatch(getCategoriesRequest())
    try {
      const responseData = await ProductServices.categories(lang)
      if (responseData?.status?.toUpperCase() === 'SUCCESS') {
        dispatch(getCategoriesSuccess(responseData))
      } else {
        dispatch(getCategoriesFailure(responseData))
      }
    } catch (error) {
      dispatch(getCategoriesFailure(error))
    }
  }

const getProductsRequest = () => {
  return {
    type: actionTypes.PRODUCT_LIST_REQUEST,
  }
}

const getProductsFailure = (error) => {
  return {
    type: actionTypes.PRODUCT_LIST_FAILURE,
    payload: error,
  }
}

const getProductsSuccess = (data) => {
  return {
    type: actionTypes.PRODUCT_LIST_SUCCESS,
    payload: data,
  }
}

export const getProductsAction = (categoryID, lang) =>
  async(dispatch) => {
    dispatch(getProductsRequest())
    try {
      const responseData = await ProductServices.products(categoryID, lang)
      if (responseData?.status?.toUpperCase() === 'SUCCESS') {
        dispatch(getProductsSuccess(responseData))
      } else {
        dispatch(getProductsFailure(responseData))
      }
    } catch (error) {
      dispatch(getProductsFailure(error))
    }
  }


