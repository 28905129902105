import React, {useEffect, useState} from 'react'
import qs from 'qs'
import {connect} from 'react-redux'
import {useHistory, withRouter} from 'react-router-dom'
import {bindActionCreators} from 'redux'
import {compose} from 'recompose'
import Alert from '@material-ui/lab/Alert'
import {ActionCreators} from '../../../redux/actions'
import * as routesNames from '../../../constants/routes'
import './paymentResponse.css'
export const PaymentResponse = (props) => {
  const [paymentMessage, setPaymentMessage] = useState('Verifying Payment...')
  const [paymentStatus, setPaymentStatus] = useState('info')
  const history = useHistory()
  useEffect(() => {
    let queryString = qs.parse(props.location?.search, {ignoreQueryPrefix: true})
    const {status, invoiceNo} = queryString
    if (status === 'success' && invoiceNo) {
      setPaymentMessage('Your order placed successfully')
      setPaymentStatus('success')
      props.actions.redirectionPayment()
      props.actions.paymentStatus(status)
      setTimeout(() => {
        history.push(routesNames.PROFILE)
      }, 1000)
    }
    if (status === 'failure' && invoiceNo) {
      setPaymentMessage('Transaction Failed')
      setPaymentStatus('error')
      props.actions.paymentStatus('error')
      setTimeout(() => {
        history.push(routesNames.LANDING)
      }, 1000)
    }
  }, [])
  return (
    <div className="page-loaderPayment">
      <Alert severity={paymentStatus} className="txtPayment">{paymentMessage}</Alert>
    </div>

  )
}
const mapDispatchToProps = (dispath) => ({
  actions: bindActionCreators(ActionCreators, dispath),
})
export default
React.memo(compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(PaymentResponse))
