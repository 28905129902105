import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import {CircularProgress, Button} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  withGradient: {
    margin: theme.spacing(2, 0, 1),
    backgroundImage: 'linear-gradient(to right, #203fbc 0%, #3251ce 51%, #a1c4fd 100%)',
    borderRadius: '3px',
    textTransform: 'none',
  },
  withoutGradient: {
    margin: theme.spacing(2, 0, 1),
    borderRadius: '3px',
    textTransform: 'none',
  },
  label: {
    padding: '4px',
  },
}))

const CustomButton = (props) => {
  const classes = useStyles()

  return (
    <Button
      fullWidth
      color={props.color || 'primary'}
      variant={props.variant || 'contained'}
      size={props.size || 'medium'}
      className={(props.gradient ? classes.withGradient : classes.withoutGradient)}
      onClick={props.onClick}
    >
      {
        props.loading ?
          <CircularProgress color="primary" size={32} />
          :
          <div className={classes.label}>
            {props.label}
          </div>
      }
    </Button>
  )
}

CustomButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
}

export default (CustomButton)

