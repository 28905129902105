import styled from 'styled-components'

const CategorySectionStyled = styled.div `
    box-shadow: 0px 3px 6px #00000016;
    background-color: #FFFFFF; 
    .cat_div {
        cursor: pointer;
        display: inline-block;
        font-size: 0.75rem;
        padding: 10px;
        margin-top: 20px;
    }

    .cat_div img {
        padding: 0px;
        border: 5px solid ${(props) => props.theme.palette.primary.main};
        width: 100px;
        height: 100px;
        border-radius:50%;
    }

    .selected_category {
        border-bottom: 2px solid ${(props) => props.theme.palette.primary.main};
    }
`
export default CategorySectionStyled
