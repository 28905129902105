import React from 'react'
import PropTypes from 'prop-types'
import OtpInput from 'react-otp-input'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  inputOTPBox: {
    width: '3rem !important',
    height: '3rem',
    margin: '0 0.5rem',
    fontSize: '2rem',
    borderRadius: '6px',
    color: '#fff',
    backgroundColor: '#ED4E53',
    border: 'none',
  },
  inputOTPSixDigitBox: {
    width: '3rem !important',
    height: '3rem',
    margin: '0 0.5rem',
    fontSize: '1rem',
    borderRadius: '6px',
    color: '#fff',
    backgroundColor: '#ED4E53',
    border: 'none',
  },
}))

export const ConfirmOTP = (props) => {
  const classes = useStyles()
  return (
    <React.Fragment>
      <OtpInput
        value={props.value}
        onChange={props.onChange}
        numInputs={props.digit}
        inputStyle={props.digit === 4 ? classes.inputOTPBox : classes.inputOTPSixDigitBox}
        placeholder={'&#9679&#9679&#9679&#9679'}
      />
    </React.Fragment>
  )
}

ConfirmOTP.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  digit: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.object,
}
export default (ConfirmOTP)
