import React, {useState, useEffect, useRef} from 'react'
import {connect, useSelector} from 'react-redux'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined'
import {withRouter} from 'react-router-dom'
import {bindActionCreators} from 'redux'
import {Dialog, Grid, DialogContent, DialogTitle, IconButton, makeStyles,
  FormControl, Select, MenuItem, CircularProgress} from '@material-ui/core'
import {Close} from '@material-ui/icons'
import EditIcon from '@material-ui/icons/Edit'
import {compose} from 'recompose'
import {ActionCreators} from '../../../redux/actions'
import {fetchErrorMsg, getLabelsBasedOnLanguage} from '../../../constants/common'
import AuthModal from '../../Authentication/authmodal.styles'
import SnackbarComponent from '../../../components/feedback/snackbar/snackbar'
import VerifyOtpForm from '../../../components/forms/verifyotp'
import MyDiv from './profile.styles'
import ChangePasswordForm from './ChangePassword/changePassword'
import ChangePhoneNumberForm from './ChangePhoneNumber/changePhoneNumber'
const useStyles = makeStyles((theme) => ({
  closeBtn: {
    margin: '-16px -24px 0 0',
    float: 'right',
  },
  uploadinput: {
    display: 'none',
  },
}),
)

export const Profile = (props) => {
  const [user, setUser] = useState(null)
  const classes = useStyles()
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const [view, setView] = useState(0)
  const [isPasswordModal, setPassword] = useState(false)
  const masterState = useSelector((state) => state.masterState)
  const authState = useSelector((state) => state.authState)
  const uploadinput = useRef(null)

  const handleImageChange = (e) => {
    let file = e.target.files[0]
    let reader = new FileReader()
    reader.onloadend = () => {
      props.actions.updateProfileImageAction(file, props.code)
    }
    if (file) {
      reader.readAsDataURL(file)
    }
  }

  const onClickUpload = () => {
    uploadinput.current.click()
  }


  useEffect(() => {
    if (authState?.session?.data) {
      setUser(authState.session.data)
    }
  }, [authState.session])
  useEffect(() => {
    if (authState?.updateprofileimage?.data) {
      setMessage(authState.updateprofileimage?.data?.message)
      setStatus('success')
    }
    if (authState?.updateprofileimage?.error) {
      let msg = fetchErrorMsg(authState?.changePassword?.error?.message)
      setMessage(msg)
      setStatus('error')
    }
  }, [authState.updateprofileimage])
  useEffect(() => {
    if (authState?.changePassword?.data) {
      setMessage(authState.changePassword.data)
      setStatus('success')
      setTimeout(() => {
        setPassword(false)
      }, 500)
    }
    if (authState?.changePassword?.error?.message !== 'Invalid request.' && authState?.changePassword?.error?.status === 'failure') {
      let msg = fetchErrorMsg(authState?.changePassword?.error?.message)
      console.log(msg)
      setMessage(msg)
      setStatus('error')
    }
  }, [authState.changePassword])

  useEffect(() => {
    if (authState?.changePhoneNumber?.data) {
      setMessage(authState.changePhoneNumber.data)
      setStatus('success')
      setTimeout(() => {
        setView(2)
      }, 500)
    }
    if (authState?.changePhoneNumber?.error?.message !== 'Invalid request.' && authState?.changePhoneNumber?.error?.status === 'failure') {
      let msg = fetchErrorMsg(authState?.changePhoneNumber?.error?.message)
      console.log(msg)
      setMessage(msg)
      setStatus('error')
    }
  }, [authState.changePhoneNumber])

  useEffect(() => {
    if (authState?.verify_otp_request?.data) {
      if (authState.validation.type === 1) {
        setTimeout(() => {
          setPassword(false)
        }, 1000)
      }
    }
  }, [authState.verify_otp_request])

  const open = (selectedView) => {
    setPassword(true)
    setView(selectedView)
  }
  const handleClose = () => {
    setPassword(false)
  }
  const handleChangePassword = (form) => {
    let type = 1
    props.actions.changePassword(form, type, props.code)
  }
  const handleCleanUp = () => {
    setTimeout(() => {
      setMessage('')
      setStatus('')
    }, 2500)
    props.actions.cleanUpState()
  }
  const handleSnackBarClose = () => {
    setTimeout(() => {
      setMessage('')
      setStatus('')
    }, 2500)
    props.actions.cleanUpState()
  }
  const onSystemLanguageChange = (languageId) => {
    props.actions.setUserLanguage(languageId, props.code)
  }
  const handleChangePhoneNumber = (form) => {
    props.actions.changePhoneNumber(form, props.code)
  }
  const handleOtpVerification = (otp) => {
    props.actions.verifyOtpAction(otp, authState.validation.type, props.code)
  }
  const selectView = () => {
    switch (view) {
      case 0:
        return (
          <ChangePasswordForm
            submit={handleChangePassword}
            code={props.code}
            msg={message}
            status={status}
            closeMsg={handleSnackBarClose}
            cleanUp={handleCleanUp}
            isloading={authState?.changePassword?.loading}
            error={authState?.changePassword?.error?.errors}
          />
        )
      case 1:
        return (
          <ChangePhoneNumberForm
            submit={handleChangePhoneNumber}
            code={props.code}
            msg={message}
            status={status}
            closeMsg={handleSnackBarClose}
            cleanUp={handleCleanUp}
            isloading={authState?.changePhoneNumber?.loading}
            error={authState?.changePhoneNumber?.error?.errors}
          />
        )
      case 2:
        return (
          <VerifyOtpForm
            submit={handleOtpVerification}
            code={props.code}
            msg={message}
            status={status}
            closeMsg={handleSnackBarClose}
            cleanUp={handleCleanUp}
            error={authState?.verify_otp_request?.error?.errors}
            isLoading={authState?.verify_otp_request?.loading}
          />
        )
      default:
        return (
          <div>Profile Form</div>
        )
    }
  }

  const getInitials = (name, surname) => {
    let initials = ''
    if (name) {
      initials = name[0].toUpperCase()
    }
    if (surname) {
      initials += surname[0].toUpperCase()
    }
    return initials
  }

  return (
    <MyDiv>
      <SnackbarComponent
        message={message}
        severity={status}
        duration={2500}
        open={message.length > 0}
        close={handleSnackBarClose}
      />
      {(authState.session.loading || authState.updateprofileimage.loading) && <div className="spinner">
        <CircularProgress style={{height: '40px', width: '40px', zIndex: 1000, position: 'absolute',
          top: '50%', left: '50%'}}
        />
      </div>
      }
      <Grid container >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div className="profileTitle">{getLabelsBasedOnLanguage('profile.myProfile.title', props.code)}</div>
        </Grid>
        {
          user ?
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className="profileDetails">
                <div className="profileImage">
                  <Grid container >
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <div className="profileDiv">
                        <div style={{display: 'flex'}}>
                          {
                            user.profile_image ? <img src={user.profile_image} className="proImg" />
                              : <span className="proImg initials">{getInitials(user.name, user.surname)}</span>
                          }

                          <div className={classes.upload} onClick={onClickUpload}>
                            <div className="upload-photo">
                              <CameraAltOutlinedIcon />
                            </div>
                            <input type="file" className={classes.uploadinput} multiple ref={uploadinput}
                              onChange={(e) => handleImageChange(e)}
                            />
                          </div>
                        </div>
                        <div className="profileRight">
                          <div className="fullName">{user.name} {user.surname}</div>
                          <div className="changePassDiv">
                            <span className="changePassword" onClick={() => open(0)}>
                              {getLabelsBasedOnLanguage('profile.myProfile.changePassword', props.code)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    {/* <Grid item xs={12} sm={3} md={4} lg={4}>
                      <div className="changePassDiv">
                        <span className="changePassword" onClick={() => open(0)}>
                          {getLabelsBasedOnLanguage('profile.myProfile.changePassword', props.code)}
                        </span>
                      </div>
                    </Grid> */}
                  </Grid>
                </div>
                <div className="profileRow">
                  <label>{getLabelsBasedOnLanguage('profile.myProfile.name', props.code)}</label>
                  <div className="profileData">{user.name}</div>
                  <hr />
                </div>
                <div className="profileRow">
                  <div className="phoneNumber">
                    <label>{getLabelsBasedOnLanguage('profile.myProfile.phoneNumber', props.code)}</label>
                    <div className="profileData">{user.phone}
                      <EditIcon className="edit-number" onClick={() => open(1)} />
                      <div className="floatRight">
                        {user.is_verified_phone ? <span>
                          <CheckCircleIcon style={{color: '#00C47A', height: 15, width: 15}} /> {getLabelsBasedOnLanguage('profile.myProfile.verified', props.code)}
                        </span> : getLabelsBasedOnLanguage('profile.myProfile.not', props.code)}
                      </div>
                    </div>
                    <hr />
                  </div>
                  <div className="language">
                    <label>{getLabelsBasedOnLanguage('profile.myProfile.systemLanguage', props.code)}</label>
                    <FormControl style={{width: '-webkit-fill-available', outline: 'none', border: '0px',
                      fontSize: '15px', fontWeight: 500, color: '#0F1350', marginLeft: '20px'}}
                    >
                      <Select
                        value={user.language_id}
                        displayEmpty
                        disableUnderline
                        inputProps={{'aria-label': 'Without label'}}
                        onChange={(event) => onSystemLanguageChange(event.target.value)}
                        style={{width: '-webkit-fill-available', outline: 'none', border: '0px',
                          fontSize: '15px', fontWeight: 500, color: '#0F1350'}}
                      >
                        {
                          masterState.languages.data && masterState.languages.data.map((lang) => (
                            <MenuItem value={lang.id} key={`lang_${lang.id}`}>
                              {lang.language.split('')[0].toUpperCase() + lang.language.split('').splice(1).join('')}
                            </MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                    <hr />
                  </div>
                </div>
              </div> </Grid> : null
        }
      </Grid>
      <AuthModal>
        <Dialog
          open={isPasswordModal}
          onClose={handleClose}
          maxWidth="xs"
        >
          <DialogTitle>
            <IconButton className={classes.closeBtn} onClick={handleClose}>
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {selectView()}
          </DialogContent>
        </Dialog>
      </AuthModal>
    </MyDiv>
  )
}
const mapDispatchToProps = (dispath) => ({
  actions: bindActionCreators(ActionCreators, dispath),
})

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(Profile)
