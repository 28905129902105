
import {english, greek} from '../config/languages'
const moment = require('moment')

export const dateFormatter = (date, formatType) => {
  return moment(date).format(formatType)
}

export const getThumbnail = (assets) => {

  let url = 'https://images.unsplash.com/photo-1552158875-de73c16fea5d?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=750&q=80'

  if (!assets || !Array.isArray(assets) || assets.length === 0) {
    return url
  }

  let images = assets.filter((x) => x.asset_type === 'image')

  if (!images || images.length === 0) {
    return url
  }

  return images[0].asset_url
}


export const getEventBanner = (assets) => {

  let url = 'https://images.unsplash.com/photo-1552158875-de73c16fea5d?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=750&q=80'

  if (!assets || !Array.isArray(assets) || assets.length === 0) {
    return url
  }

  let images = assets.filter((x) => x.asset_type === 'image')

  if (!images || images.length === 0) {
    return url
  }

  return images[0].asset_url
}

export const getStatusLabel = (status) => {
  let label = ''
  switch (status.toUpperCase()) {
    case 'DRAFT':
      label = 'Draft'
      break
    case 'PUBLISH':
      label = 'Published'
      break
    case 'CANCEL':
      label = 'Cancelled'
      break
    default:
      label = ''
      break
  }
  return label
}

const getLangLabel = (obj, key) => {
  let label = obj
  let keyArr = key.split('.')
  for (let x of keyArr) {
    label = label[x]
  }
  return label
}

export const getLabelsBasedOnLanguage = (key, code) => {
  let label = ''
  switch (code) {
    case 'EL':
      label = getLangLabel(greek, key)
      break
    default:
      label = getLangLabel(english, key)
      break
  }
  return label
}


export const fetchErrorMsg = (errors) => {
  let err = ''
  if (typeof errors === 'string') {
    err = errors
  } else if (errors instanceof Array) {
    err = errors.toString()
  } else if (errors instanceof Object) {
    let arr = []
    for (let x of Object.keys(errors)) {
      if (errors[x] instanceof String) {
        arr.push(errors[x])
      } else if (errors[x] instanceof Array) {
        arr.push(errors[x].toString())
      }
    }
    err = arr.toString()
  }
  return err
}
