import styled from 'styled-components'

const MyDiv = styled.div `
.profileTitle{
  //font-family: Proxima Nova;
  font-size: 22px;
  font-weight: 700;
  color: #000000;
  padding: 13px 66px 12px 66px;
  border-radius: 10px 10px 0 0;
  background-color: #F1F1F1;
  @media(max-width:767px){
    padding: 15px 25px;
  }
  @media(min-width:768px) and (max-width:1023px){
    padding: 15px 25px;
  }
}
.upload-photo{
  background: #fff;
  width: 45px;
  height: 45px;
  position: relative;
    top: 65%;
    right: 85%;
  border-radius: 50%;
  box-shadow: 1px 2px 12px 2px rgba(0,0,0,0.1);
}
.upload-photo .MuiSvgIcon-root{
  margin-left:12px;
  margin-top:12px;
}
.profileDetails{
  .profileRow{
    max-width: 750px;
    padding-left: 66px;
    @media(max-width:767px){
      padding-left:0px;
    }
    @media(min-width:768px) and (max-width:1023px){
      padding-left:25px;
    }
    label{
     // font-family: Proxima Nova;
      font-size: 12px;
      font-weight: 400;
      color: #000000;
    }
    .phoneNumber{
      width: 50%;
      float: left;
      @media(max-width:767px){
        width: 100%;
      }
    }
    .language{
      width: 50%;
      padding-left: 50px;
      @media(max-width:767px){
        width: 100%;
        padding-left: 0px;
      }
      float: right;
      input[type=*], select {
        width: -webkit-fill-available;
        margin-left: 20px;
        margin-top: 7.5px;
        outline: none;
        border: 0px;
        font-size: 15px;
        font-weight: 500;
        color: #0F1350;
      }
      .MuiInput-underline:before{
        content: none;
      }
      hr{
        margin: 2px;
      }
    }
    .profileData{
      margin-left: 20px;
      margin-top: 5px;
      // margin-right: 70px;
      //font-family: Proxima Nova;
      font-size: 15px;
      font-weight: 500;
      color: #0F1350;
      @media(max-width:767px){
        margin-right: 0px;
      }
      .floatRight{
        float: right;
      }
    }
    hr{
      width: 100%;
      border: 0;
      border-top: 1px solid #707070;
    }
  }
  .edit-number{
    margin-top: -5px;
    margin-left: 15px;
    position: absolute;
    cursor:pointer;
  }
  .profileImage{
    padding-left: 66px;
    margin-top: 11px;
    margin-bottom: 55px;
    display: flow-root;
    width: 100%;
    @media(max-width:767px){
      padding-left:0px;
          margin-top: 30px;
    margin-bottom: 30px;
     }
     @media(min-width:768px) and (max-width:1023px){
      padding-left:25px;
    }
    .proImg{
      height: 126px;
      width: 126px;
      border: 1px solid #000000;
      border-radius: 50%;
      text-align: center;
      line-height: 0;
      @media(max-width:767px){
        height: 110px;
        width: 110px;
      }
    }
    .proImg.initials{
      line-height: 118px;
      font-size: 36px;
    }
    .profileDiv{
      display: flex;
      width: 100%;
    }
    .profileRight{
      padding-right: 20px;
      margin-top: 37px;
      width: 100%;
      display: flow-root;
      .fullName{
        float: left;
        font-size: 38px;
        font-weight: 500;
        color: #0F1350;
        text-transform: capitalize;
        @media(max-width:767px){
          font-size: 22px;
          margin-left: 20px;
        }
        @media(min-width:768px) and (max-width:1023px){
          font-size: 30px;
        }
      }
      .changePassDiv{
        float: right;
        margin-top: 10px;
        .changePassword{
          //font-family: Proxima Nova;
          font-size: 14px;
          font-weight: 500;
          color: #000000;
          float: right;
          border: 1px solid #C4D2DF;
          cursor: pointer;
          border-radius: 5px;
          height: fit-content;
          padding: 6px 15px;
        }
      }

    }
  }
}
.spinner {
  position: absolute!important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #bdbdbd7a;
  z-index: 1000;
}
`
export default MyDiv
