import React from 'react'
import Proptypes from 'prop-types'
import _ from 'lodash'
// import {ToggleButton, ToggleButtonGroup} from '@material-ui/lab'
import StyledToggleButtonType from './togglebuttontype.styles'

const CustomToggleButtonType = (props) => {
  const setClasses = (typeId, productId) => {
    let className = 'toggle-btn'
    if (productId === props?.selected?.category_id && typeId === props?.selected?.type_id) {
      className = `${className} selected`
    }
    return className
  }
  return (
    <StyledToggleButtonType>
      {_.map(props?.buttons, (btn, index) => {
        return (
          <div className={setClasses(btn?.type_id, btn?.category_id)} onClick={() => props.onChange(btn)} key={`toggle_btn${index + 1}`}>
            {btn?.type}
          </div>
        )
      })}
    </StyledToggleButtonType>
  )
}

CustomToggleButtonType.propTypes = {
  selected: Proptypes.object,
  theme: Proptypes.object,
  onChange: Proptypes.func,
  buttons: Proptypes.array,
}
export default CustomToggleButtonType
