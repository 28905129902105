import React, {useState, useEffect} from 'react'
import {useSelector, connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {compose} from 'recompose'
import {withRouter} from 'react-router-dom'
import {FormControl, Select, MenuItem, CircularProgress} from '@material-ui/core'
import {ActionCreators} from '../../../redux/actions'
import {getLabelsBasedOnLanguage} from '../../../constants/common'
import MyDiv from './address.style'
export const Addresses = (props) => {
  const masterState = useSelector((state) => state.masterState)
  const [userAddresses, setUserAddresses] = useState([])
  const [editingAddress, setEditingAddress] = useState({})
  const [error, setError] = useState(null)
  const userInfoState = useSelector((state) => state.userState)
  useEffect(() => {
    if (userInfoState?.addresses?.data) {
      setUserAddresses(userInfoState.addresses.data)
    }
    if (userInfoState?.addresses?.error?.errors) {
      setError({
        address1: userInfoState.addresses.error.errors.address1 && userInfoState.addresses.error.errors.address1[0],
        address2: userInfoState.addresses.error.errors.address2 && userInfoState.addresses.error.errors.address2[0],
      })
    } else if (userInfoState.addresses.loading === false) {
      setEditingAddress({})
    }
  }, [userInfoState.addresses])
  const onMakeAddressPrimary = (addressObj) => {
    if (addressObj.is_primary) {
      return
    } else {
      props.actions.makeAddressPrimary({
        ...addressObj,
      }, props.code)
    }
  }
  const drawAddress = (addressObj) => {
    let address = ''
    if (addressObj.address1) {
      address = addressObj.address1
    }
    if (addressObj.address2) {
      address = address.trim().length ? `${address} ${addressObj.address2}` : addressObj.address2
    }
    if (addressObj.post_code) {
      address += `, ${addressObj.post_code}`
    }
    if (addressObj.city_id && masterState.cities.data) {
      const city = masterState.cities.data.find((el) => el.id === addressObj.city_id)
      if (city) {
        address += `, ${city.city}`
      }
    }
    return (
      <div className="addressText">
        {address} <span className={addressObj.is_primary ? 'toggleBtn' : 'toggleBtn makePrimary'}
          onClick={() => onMakeAddressPrimary(addressObj)}
        >
          {addressObj.is_primary ? getLabelsBasedOnLanguage('profile.myAddresses.primary', props.code)
            : getLabelsBasedOnLanguage('profile.myAddresses.makePrimary', props.code)}
        </span>
      </div>
    )
  }

  const onChange = (key, value) => {
    setEditingAddress({
      ...editingAddress,
      [key]: value,
    })
  }

  const onAddressClick = (address, count) => {
    setError(null)
    if (address) {
      setEditingAddress({
        ...address,
        city_id: address.city_id || masterState.cities.data ? masterState.cities.data[0].id : null,
        country_id: address.country_id || masterState.countries.data ? masterState.countries.data[0].id : null,
        count: count,
      })
    } else {
      setEditingAddress({
        address1: '',
        address2: '',
        post_code: masterState.postalCodes.data ? masterState.postalCodes.data[0].postcode : null,
        city_id: masterState.cities.data ? masterState.cities.data[0].id : null,
        country_id: masterState.countries.data ? masterState.countries.data[0].id : null,
        is_primary: false,
        isNew: true,
      })
    }
  }

  const onCancelAddress = () => {
    setEditingAddress({})
    setError(null)
  }
  const onSaveAddress = () => {
    setError(null)
    if (editingAddress.isNew) {
      props.actions.createUserAddressAction({
        ...editingAddress,
      }, props.code)
    } else {
      props.actions.updateUserAddressAction({
        ...editingAddress,
      }, props.code)
    }
  }

  const addressDetailsBox = () => {
    return (
      <div className="addMobileSet">
        <hr />
        <div className="addressDetailsBoxMain">
          <span className="addressUpdateTitle">
            {editingAddress.isNew ? getLabelsBasedOnLanguage('profile.myAddresses.newAddress', props.code)
              : `${getLabelsBasedOnLanguage('profile.myAddresses.updateAddress', props.code)} ${editingAddress.count}`}
          </span>
          <div className="addressDetailsRow">
            <label>{getLabelsBasedOnLanguage('profile.myAddresses.address1', props.code)}<sup>*</sup></label>
            <input className="inputBox" type="text" onChange={(e) => onChange('address1', e.target.value)}
              value={editingAddress.address1}
            />
          </div>
          {error && error.address1 && <div className="addressDetailsRow errorAddress">
            <label />
            <span>{error.address1}</span>
          </div>
          }
          <div className="addressDetailsRow">
            <label>{getLabelsBasedOnLanguage('profile.myAddresses.address2', props.code)}<sup>*</sup></label>
            <input className="inputBox" type="text" onChange={(e) => onChange('address2', e.target.value)}
              value={editingAddress.address2}
            />
          </div>
          {error && error.address2 && <div className="addressDetailsRow errorAddress">
            <label />
            <span>{error.address2}</span>
          </div>
          }
          <div className="addressDetailsRow">
            <label>{getLabelsBasedOnLanguage('profile.myAddresses.postalCode', props.code)}<sup>*</sup></label>
            <FormControl>
              <Select
                style={{width: 225}}
                value={editingAddress.post_code}
                displayEmpty
                inputProps={{'aria-label': 'Without label'}}
                onChange={(event) => onChange('post_code', event.target.value)}
              >
                {
                  masterState.postalCodes.data && masterState.postalCodes.data.map((postalCode) => (
                    <MenuItem value={postalCode.postcode} key={`postalCode_${postalCode.id}`}>
                      {postalCode.postcode}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </div>
          <div className="addressDetailsRow">
            <label>{getLabelsBasedOnLanguage('profile.myAddresses.city', props.code)}</label>
            <FormControl>
              <Select
                style={{width: 225}}
                value={editingAddress.city_id}
                displayEmpty
                inputProps={{'aria-label': 'Without label'}}
                onChange={(event) => onChange('city_id', event.target.value)}
              >
                {
                  masterState.cities.data && masterState.cities.data.map((city) => (
                    <MenuItem value={city.id} key={`city_${city.id}`}>{city.city}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </div>
          <div className="addressDetailsRow">
            <label>{getLabelsBasedOnLanguage('profile.myAddresses.country', props.code)}</label>
            <FormControl>
              <Select
                style={{width: 225}}
                value={editingAddress.country_id}
                displayEmpty
                inputProps={{'aria-label': 'Without label'}}
                onChange={(event) => onChange('country_id', event.target.value)}
              >
                {
                  masterState.countries.data && masterState.countries.data.map((country) => (
                    <MenuItem value={country.id} key={`country_${country.id}`}>{country.country}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </div>
          {
            editingAddress.isNew ? <div className="addressDetailsRow">
              <label>{getLabelsBasedOnLanguage('profile.myAddresses.isPrimary', props.code)}</label>
              <input type="checkbox" onChange={(event) => onChange('is_primary', event.target.checked)} />
            </div> : null
          }
        </div>
        <div className="actionsList">
          <span className="toggleBtn cancelToggleBtn" onClick={() => onCancelAddress()}>
            {getLabelsBasedOnLanguage('profile.myAddresses.cancel', props.code)}
          </span>
          <span className="toggleBtn" onClick={() => onSaveAddress()}>{editingAddress.isNew ?
            getLabelsBasedOnLanguage('profile.myAddresses.saveAddress', props.code)
            : getLabelsBasedOnLanguage('profile.myAddresses.updateAddress', props.code)}
          </span>
        </div>
      </div>
    )
  }

  return (
    <>
      <MyDiv>
        {(userInfoState.addresses.loading) && <div className="spinner">
          <CircularProgress style={{height: '35px', width: '35px', zIndex: 1000, position: 'absolute', top: '50%'}} />
        </div>
        }
        <div className="profileTitle">{getLabelsBasedOnLanguage('profile.myAddresses.title', props.code)}</div>
        <div className="addressList">
          {
            userAddresses.map((userAddress, index) => (
              <div className="mobileAddList" key={`user_address_${index}`}>
                {
                  editingAddress.id === userAddress.id ? addressDetailsBox() : <div>
                    {index !== 0 ? <hr /> : null}
                    <span className="addressText underline" onClick={() => onAddressClick(userAddress, index + 1)}>
                      {getLabelsBasedOnLanguage('profile.myAddresses.address', props.code)} {index + 1}
                    </span>
                    {drawAddress(userAddress)}
                  </div>
                }
              </div>
            ))
          }
          {
            editingAddress.isNew ? addressDetailsBox() : <div className="addNewAddress"
              onClick={() => onAddressClick(null)}
            >
              <div className="addIcon">+</div>
              <div className="addTitle">{getLabelsBasedOnLanguage('profile.myAddresses.newAddress', props.code)}</div>
            </div>
          }
        </div>
      </MyDiv>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(Addresses)

