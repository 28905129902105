export const meta = {
  login: 'Σύνδεση',
  logoutMenu: 'Αποσύνδεση',
  profileMenu: 'Προφίλ',
  restaurant: 'εστιατόριο',
  home: 'Αρχική',
  aboutus: 'Σχετικά με εμάς',
  userlogin: 'Σύνδεση χρήστη',
  signin: {
    title: 'καλως ΗΡΘΑΤΕ',
    subtitle: 'Συνδεθείτε χρησιμοποιώντας τον αριθμό τηλεφώνου σας για να συνεχίσετε',
    subtitle1: 'Ξεχάσατε τον κωδικό',
    subtitle2: 'Δεν έχετε λογαριασμό',
  },
  signup: {
    title: 'καλως ΗΡΘΑΤΕ',
    subtitle: 'Συνδεθείτε χρησιμοποιώντας τον αριθμό τηλεφώνου σας για να συνεχίσετε',
    subtitle1: 'Ξεχάσατε τον κωδικό',
    subtitle2: 'Δεν έχετε λογαριασμό',
    and: 'και',
    termservice: 'Οροι χρήσης',
    privacypolicy: 'Πολιτική απορρήτου',
  },
  forgotpassword: {
    title: 'ξεχάστε τον κωδικό πρόσβασης',
    subtitle: 'Εισαγάγετε τον αριθμό τηλεφώνου σας και θα στείλουμε ένα OTP για να το επαναφέρετε',
  },
  verifyotp: {
    title: 'Επαλήθευση τηλεφώνου',
    subtitle: 'Εισαγάγετε τον κωδικό επαλήθευσης SMS. Να έχεις ένα λογαριασμό?',
  },
  changepassword: {
    title: 'νέος κωδικός πρόσβασης',
    subtitle: 'Εισαγάγετε τον νέο κωδικό πρόσβασής σας',
  },
  changephonenumber: {
    title: 'Αλλαγή αριθμού τηλεφώνου',
    subtitle: 'Εισαγάγετε τον νέο αριθμό τηλεφώνου σας',
  },
  profilechangepassword: {
    title: 'Άλλαξε κωδικό',
    subtitle: 'Εισαγάγετε τον νέο κωδικό πρόσβασής σας',
    oldpassword: 'ΠΑΛΙΟΣ ΚΩΔΙΚΟΣ',
    newpassword: 'νέος κωδικός πρόσβασης',
  },
  product: {
    description: 'Περιγραφή',
    ingredients: 'Αλλεργιογόνα',
    type: 'τύπος',
    size: 'Μέγεθος',
  },
  inputs: {
    labels: {
      phone: 'Τηλέφωνο',
      password: 'Κωδικός πρόσβασης',
      name: 'Ονομα',
      surname: 'Επώνυμο',
      confirmpassword: 'Επιβεβαίωση Κωδικού',
      oldpassword: 'ΠΑΛΙΟΣ ΚΩΔΙΚΟΣ',
      newpassword: 'νέος κωδικός πρόσβασης',
    },
    buttons: {
      signin: 'Συνδεθείτε',
      signup: 'Κανω ΕΓΓΡΑΦΗ',
      send: 'Στείλετε',
      verify: 'Επαληθεύστε το τηλέφωνό μου',
      savecontinue: 'Αποθήκευση και συνέχεια',
    },
  },
  orderLabel: 'Στο καλάθι',
  cart: {
    postalcode: 'Ταχυδρομικός Κώδικας',
    delivery: 'Delivery',
    takeAway: 'Take away',
    changeAddress: 'Αλλαγή διεύθυνσης',
    clearCart: 'Διαγραφή όλων',
    note: 'Κατά μέσο όρο οι παραδόσεις πραγματοποιούνται μεταξύ 40 λεπτών έως 1 ώρας.',
    orderTotal: 'Σύνολο παραγγελίας',
    deliveryCost: 'Κόστος παράδοσης',
    placeOrder: 'Πληρωμή',
    addToCart: 'Προσθήκη',
    cancel: 'Ακύρωση',
    emptyCart: 'Ό, τι λαχταράτε, απλώς προσθέστε το στο καλάθι!',
    otherCharges: 'Αλλα εξοδα',
    preferences: 'Προτιμήσεις',
    termsAndConditions: 'Έχω διαβάσει και αποδέχομαι τους',
    termsAndConditionsLink: 'όροι χρήσης',
    with: 'Με',
    without: 'Χωρις',
    withSalad: 'Με σαλάτα',
    noSalad: 'Χωρις σαλάτα',
    comments: 'Σχόλια',
    freeDelivery: 'Δωρεάν μεταφορά',
    COD: 'Μετρητά',
    online: 'Πληρωμή μέσω JCC',
  },
  postalCodeCheck: {
    success: 'Παραδίδουμε σε αυτόν τον ταχυδρομικό κώδικα με χρέωση',
    error: 'Προς το παρόν δεν παραδίδουμε σε αυτόν τον ταχυδρομικό κώδικα.',
  },
  paymentStatusCheck: {
    success: 'Η παραγγελία σας πραγματοποιήθηκε με επιτυχία.',
    error: 'Λυπούμαστε, η συναλλαγή σας απέτυχε. ΠΑΡΑΚΑΛΩ προσπαθησε ξανα.',
  },
  profile: {
    mainSettings: 'Κύριες ρυθμίσεις',
    myProfile: {
      title: 'Το Προφίλ μου',
      name: 'Ονομα',
      phoneNumber: 'Αριθμός τηλεφώνου',
      verified: 'Επαληθεύτηκε',
      not: 'Δεν επαληθεύεται',
      systemLanguage: 'Γλώσσα συστήματος',
      changePassword: 'Αλλαξε κωδικό',
    },
    myAddresses: {
      title: 'Οι διευθύνσεις μου',
      updateAddress: 'Ενημέρωση διεύθυνσης',
      newAddress: 'Νέα διεύθυνση',
      address1: 'Διεύθυνση',
      address2: 'Περιοχή',
      postalCode: 'Ταχυδρομικός Κώδικας',
      city: 'Πόλη',
      country: 'Χώρα',
      cancel: 'Ακύρωση',
      saveAddress: 'Αποθήκευση διεύθυνσης',
      primary: 'Πρωταρχικός',
      makePrimary: 'Κάντε το πρωτεύον',
      isPrimary: 'Είναι πρωταρχικό',
      address: 'Διεύθυνση',
    },
    myOrders: {
      title: 'Οι παραγγελίες μου',
    },
  },
  restroTimings: {
    restOfTheDay: 'Το εστιατόριο έκλεισε για σήμερα.',
    closeUntil: 'Το εστιατόριο είναι κλειστό μέχρι τις',
  },
  aboutUsPage: {
    p1: 'Η ιστορία μας ξεκίνησε το 2010, όταν και είχαμε τη διαχείριση της καφετέριας πισίνας σε μια γειτονιά της Λευκωσίας.' +
      ' Εκεί οι πελάτες μας ζητούσαν επίμονα να συμπεριλάβουμε στον κατάλογο των προϊόντων μας και τα σουβλάκια,' +
      ' έτσι με κάθε επιφύλαξη επιχειρήσαμε να ικανοποιήσουμε την απαίτηση τους. Ξεκινήσαμε δειλά - δειλά με μια φτηνή' +
      ' ψησταριά υγραερίου και σερβίραμε σουβλάκια τις Κυριακές. Η ανταπόκριση του κόσμου ήταν πρωτόγνωρη και πέρα από' +
      ' κάθε προσδοκία! Μέσα σε αντιξοότητες μάθαμε από τα λάθη μας και με σκληρή δουλειά εξελιχθήκαμε σιγά – σιγά.' +
      ' Μέσα στα επόμενα 5 χρόνια το σουβλάκι μας άρχισε να αποκτά φήμη στη γύρω περιοχή και οι ικανοποιημένοι πελάτες μας επισκέπτονταν ξανά και ξανά.',
    p2: 'Με εφόδιο την εμπειρία μας αυτή, τον Ιούλιο του 2015 αποφασίσαμε να κάνουμε το επόμενο βήμα που ήταν η δημιουργία' +
      ' του πρώτου μας σουβλατζίδικου στην Ακρόπολη. Οι συνθήκες της εποχής και πάλι ήταν δύσκολες, όμως το ’’για σουβλάκι…' +
      ' έτσι απλά’’ λατρεύτηκε για την ξεχωριστή ποιότητα των φαγητών του, αλλά και τα αγνά του υλικά. Φτιάξαμε ένα' +
      ' σουβλατζίδικο διαφορετικό από τα συνηθισμένα, αφού κάθε μέρα επιλέγουμε το καλύτερο για τον κόσμο μας και το παραδίδουμε στην πόρτα τους καθημερινά μεσημέρι και βράδυ.',
    p3: 'Σύντομα το κατάστημα στην Ακρόπολη αποδείχθηκε πολύ μικρό για να στεγάσει τη νέα λατρεία των Λευκωσιατών και όχι' +
      ' μόνο, έτσι το Σεπτέμβριο του 2018 δημιουργήσαμε το δεύτερο ψητοπωλείο μας στη Μετοχίου 41Β στον Άγιο Ανδρέα.' +
      ' Με αυτό τον τρόπο ολόκληρη η Λευκωσία απ’ άκρη σ’ άκρη μπορεί να απολαμβάνει τη νοστιμιά, την ευγένεια και την' +
      ' εξυπηρέτηση ενός ανώτερου επιπέδου, που προσφέρει το ’’για σουβλάκι… έτσι απλά’’! Προς τα τέλη του 2018' +
      ' εγκαταλείψαμε το μικρό κατάστημα στην Ακρόπολη και αφοσιωθήκαμε στη βελτίωση του νέου μας μαγαζιού,' +
      ' που βρίσκεται σε ένα σημείο πολύ βολικό για τη γρήγορη εξυπηρέτηση ολόκληρης της Λευκωσίας. Φτιάξαμε ένα όμορφο' +
      ' χώρο dine in που συνεχώς αναβαθμίζεται και εκεί το κοινό μπορεί να απολαμβάνει το αγαπημένο του σουβλάκι,' +
      ' σεφταλιά ή κοτόπουλο σουβλάκι. Η σπεσιαλιτέ του ψητοπωλείου μας είναι η τεράστια χοιρινή μπριζόλα, ενώ πρέπει οπωσδήποτε να δοκιμάσετε τα σπιτικά μας μπιφτέκια.',
  },
  termsOfUse: 'Οροι χρήσης',
  privacyPolicy: 'Πολιτική απορρήτου',
  contactUs: {
    menu: 'Επικοινωνήστε μας',
    address: 'Διεύθυνση:',
    phone: 'Τηλέφωνο:',
    email: 'Διεύθυνση ηλεκτρονικού:',
  },
}
