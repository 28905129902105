import React, {useState, useEffect} from 'react'
import {connect, useSelector} from 'react-redux'
import {bindActionCreators} from 'redux'
import {compose} from 'recompose'
import {withRouter, useHistory} from 'react-router-dom'
import SearchIcon from '@material-ui/icons/Search'
import {Edit as EditIcon, Delete as DeleteIcon, FastfoodRounded as FastFoodIcon, Close as CloseIcon} from '@material-ui/icons'
import {CircularProgress, Snackbar, FormControlLabel, Checkbox, Modal, IconButton} from '@material-ui/core'
import {Alert} from '@material-ui/lab'
import moment from 'moment'
import * as routesNames from '../../../constants/routes'
import * as ProductServices from '../../../services/productServices'
import {ActionCreators} from '../../../redux/actions'
import AuthenticationModal from '../../../views/Authentication'
import {getLabelsBasedOnLanguage} from '../../../constants/common'
import TermsAndConditionsComp from '../../TermsAndConditions'
import ProductCustomizations from './productCustomization'
import AddressSelector from './addressSelector'
import MyDiv from './productCart.style'

const deliveryOptionsEnum = {
  delivery: 1,
  takeAway: 2,
}

const paymentOptionsEnum = {
  COD: 1,
  online: 2,
}

const ProductCart = React.memo((props) => {
  const [showMessage, setShowMessage] = React.useState({
    message: '',
    type: 'success',
    open: false,
  })
  const history = useHistory()
  const config = useSelector((state) => state.configState)
  const userInfoState = useSelector((state) => state.userState)
  const authState = useSelector((state) => state.authState)
  const paymentState = useSelector((state) => state.paymentState)
  const masterState = useSelector((state) => state.masterState)
  const productState = useSelector((state) => state.productState)
  const [products, setProducts] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [searchingPostalCode, setSearchingPostalCode] = useState(false)
  const [commentDefault, setCommentDefault] = useState(props.cartCost.comments)
  const [editingProduct, setEditingProduct] = useState(null)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const [isAuthModal, setAuthModal] = useState(false)
  const [searchData, setSearchData] = useState('')
  const [cartCategoryDescription, setCartCategoryDescription] = useState(null)
  const [termsAndConditions, setTermsAndConditions] = useState(false)
  const [termsAndConditionsModal, setTermsAndConditionsModal] = useState(false)
  const insertCartItem = (product) => {
    const selectedAttributes = []
    const selectedAttributesList = []
    product.selectedType && product.selectedType.attributes && product.selectedType.attributes.length &&
    product.selectedType.attributes.map((attribute) => {
      selectedAttributes.push(attribute.attribute_id)
      selectedAttributesList.push(attribute)
    })
    setEditingProduct({
      ...product.product,
      price: parseFloat(product.product.price) +
        (product.selectedType && product.selectedType.price ? product.selectedType.price : 0) +
        (product.selectedSize && product.selectedSize.price ? product.selectedSize.price : 0),
      attributeGroups: product.attributeGroups,
      selected_attributes: selectedAttributes,
      selectedAttributesList: selectedAttributesList,
      qty: product.qty,
      selectedIndex: 'new',
      selectedSize: product.selectedSize,
      selectedType: product.selectedType,
    })
    if (productState.categories.data && productState.categories.data.categories) {
      const productCategory = productState.categories.data.categories.find((category) => category.id === product.product.category_id)
      productCategory && productCategory.description && setCartCategoryDescription(productCategory.description)
    }
  }
  const createCartItem = (product) => {
    const currentEditingProduct = {...editingProduct}
    setEditingProduct(null)
    setCartCategoryDescription(null)
    setIsLoading(true)
    ProductServices.createCart({
      type_of_order: props.cartCost.typeOfOrder || deliveryOptionsEnum.delivery,
      payment_type: props.cartCost.paymentType || paymentOptionsEnum.COD,
      product_id: product.id,
      category_id: currentEditingProduct.category_id,
      category_type_price_id: currentEditingProduct.selectedType.type_id,
      category_size_price_id: currentEditingProduct.selectedSize.size_id,
      quantity: product.qty,
      attributes: product.selectedAttributesList || [],
    }, props.code).then((res) => {
      if (res.status === 'success') {
        const primaryAddress = userInfoState.addresses.data &&
          userInfoState.addresses.data.find((el) => el.is_primary === 1)
        let cartCost = {
          totalAmount: parseFloat(res.total_amount),
          deliveryCost: parseFloat(res.delivery_amount),
          typeOfOrder: res.type_of_order,
          paymentType: res.payment_type,
          cartAddressId: res.address_id,
          otherCharges: [{
            label: res.plastic_bag_label,
            charge: res.plastic_bag_charge ? parseFloat(res.plastic_bag_charge) : 0,
          }, {
            label: res.total_tax_label,
            charge: isNaN(res.total_tax_charge) ? 0 : parseFloat(res.total_tax_charge),
          }],
        }
        if (primaryAddress && !cartCost.cartAddressId) {
          ProductServices.updateCartAddress(primaryAddress, props.code).then((resUpdateAdd) => {
            if (resUpdateAdd.status === 'success') {
              cartCost = {
                ...cartCost,
                totalAmount: parseFloat(resUpdateAdd.total_amount),
                deliveryCost: parseFloat(resUpdateAdd.delivery_amount),
                typeOfOrder: resUpdateAdd.type_of_order,
                paymentType: resUpdateAdd.payment_type,
                cartAddressId: resUpdateAdd.address_id,
              }
            }
          }).catch((e) => {
            console.log(e.message)
          })
        }
        props.onNewItemAdded({
          product: {
            ...currentEditingProduct,
            price: res.item.price,
          },
          cartItemId: res.item.id,
          qty: res.item.quantity,
          attributes: res.attributes || [],
          cartCost: {...cartCost},
          category_size_price_id: res.item.category_size_price_id,
          category_type_price_id: res.item.category_size_price_id,
        })
      }
    }).catch((e) => {
      console.log(e.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const handleCloseAlert = () => {
    setShowMessage({
      ...showMessage,
      open: false,
    })
    props.actions.cleanUpPaymentForm()
  }
  const onDeliveryOptionChange = (option) => {
    if (option === deliveryOptionsEnum.delivery && !props.cartCost.cartAddressId) {
      setShowMessage({
        type: 'error',
        open: true,
        message: 'No delivery address selected yet.',
      })
      return
    }
    setIsLoading(true)
    ProductServices.updateDeliveryOption(option, props.code).then((res) => {
      if (res.status === 'success') {
        props.updateDeliveryOption({
          cartCost: {
            totalAmount: parseFloat(res.total_amount),
            deliveryCost: parseFloat(res.delivery_amount),
            typeOfOrder: res.type_of_order,
            otherCharges: [{
              label: res.plastic_bag_label,
              charge: res.plastic_bag_charge ? parseFloat(res.plastic_bag_charge) : 0,
            }, {
              label: res.total_tax_label,
              charge: isNaN(res.total_tax_charge) ? 0 : parseFloat(res.total_tax_charge),
            }],
          },
        })
      }
    }).catch((e) => {
      console.log(e.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const onPaymentOptionChange = (option) => {
    setIsLoading(true)
    ProductServices.updatePaymentOption(option, props.code).then((res) => {
      if (res.status === 'success') {
        props.updateDeliveryOption({
          cartCost: {
            totalAmount: parseFloat(res.total_amount),
            deliveryCost: parseFloat(res.delivery_amount),
            paymentType: res.payment_type,
          },
        })
      }
    }).catch((e) => {
      console.log(e.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const updateComment = (e) => {
    if (e.target.value !== props.cartCost.comments) {
      ProductServices.updateComments(e.target.value, props.code).then((res) => {
        if (res.status === 'success') {
          props.onCommentUpdate(res.comments)
        }
      }).catch((e) => {
        console.log(e.message)
      })
    }
  }

  const checkPostalCode = () => {
    if (searchData && searchData.length > 0) {
      setSearchingPostalCode(true)
      ProductServices.checkPostalCode(searchData, props.code).then((res) => {
        if (res.status.toUpperCase() === 'SUCCESS') {
          setShowMessage({
            message: `${getLabelsBasedOnLanguage('postalCodeCheck.success', props.code)} ` +
              `${parseFloat(res.post_code.delivery_charge).toFixed(2)} €.`,
            type: 'success',
            open: true,
          })
        } else {
          setShowMessage({
            message: getLabelsBasedOnLanguage('postalCodeCheck.error', props.code),
            type: 'error',
            open: true,
          })
        }
      }).catch((e) => {
        setShowMessage({
          message: getLabelsBasedOnLanguage('postalCodeCheck.error', props.code),
          type: 'error',
          open: true,
        })
      }).finally(() => {
        setSearchingPostalCode(false)
      })
    }
  }

  const handleLoginBtnClick = (val) => {
    setAuthModal(val)
  }
  const checkRestroIsOpen = () => {
    const currentDate = moment()
    let isOpen = false
    let message = null
    masterState.restroTimings.data && masterState.restroTimings.data.map((timeObj) => {
      if (isOpen || message) {
        return
      }
      const startTime = timeObj.startTime.split(':')
      const startHours = parseInt(startTime[0], 10)
      const startMinutes = parseInt(startTime[1], 10)
      const startDate = moment().set('hours', startHours).set('minutes', startMinutes)
      const endTime = timeObj.endTime.split(':')
      const endHours = parseInt(endTime[0], 10)
      const endMinutes = parseInt(endTime[1], 10)
      const endDate = moment().set('hours', endHours).set('minutes', endMinutes)
      if (currentDate.isBefore(startDate)) {
        const minutesMsg = startMinutes > 0 ? `:${startMinutes}` : ''
        message = startHours === 12 ? `12${minutesMsg}pm` : startHours > 12 ? `${startHours % 12}${minutesMsg}pm` :
          `${startHours}${minutesMsg}am`
        message = `${getLabelsBasedOnLanguage('restroTimings.closeUntil', props.code)} ${message}.`
      } else if (currentDate.isAfter(startDate) && currentDate.isBefore(endDate)) {
        isOpen = true
      }
      return isOpen
    })
    if (masterState.restroTimings.data && masterState.restroTimings.data.length && !isOpen && !message) {
      message = `${getLabelsBasedOnLanguage('restroTimings.restOfTheDay', props.code)}`
    }
    return {
      isOpen,
      message,
    }
  }
  const onPlaceOrderClick = () => {
    if (!products.length || !termsAndConditions) {
      return
    }
    const canPlaceOrder = checkRestroIsOpen()
    if (canPlaceOrder.isOpen && authState.session.data) {
      setIsLoading(true)
      props.actions.orderProcessAction(config.language.code, paymentOptionsEnum.COD)
    } else if (canPlaceOrder.isOpen === false) {
      setShowMessage({
        message: canPlaceOrder.message,
        type: 'error',
        open: true,
      })
    } else {
      setAuthModal(true)
    }
  }

  useEffect(() => {
    setCommentDefault(props.cartCost.comments)
  }, [props.cartCost.comments])
  useEffect(() => {
    const data = paymentState?.orderprocess?.data
    if (data) {
      data.payment_type && data.payment_type === paymentOptionsEnum.COD ?
        window.location.href = data.url : history.push(routesNames.PAYMENTGATEWAY)
    }
    if (paymentState?.orderprocess?.error?.status === 'failure') {
      if (paymentState.orderprocess.error?.errors?.url) {
        window.location.href = paymentState.orderprocess.error.errors.url
      } else {
        setIsLoading(false)
        setShowMessage({
          message: paymentState?.orderprocess?.error?.errors,
          type: 'error',
          open: true,
        })
        setIsLoading(false)
      }
    }
  }, [paymentState?.orderprocess])
  useEffect(() => {
    let newAddedProduct = null
    const productList = []
    props.products && props.products.map((product) => {
      if (product.isNew) {
        newAddedProduct = product
      } else if (product.product && product.product.attributeGroups) {
        const selectedAttributesIds = []
        const selectedAttributes = []
        const saladAttributes = []
        const saladAttributeGroup = product.product.attributeGroups.find((attributeGroup) => attributeGroup.isSalad && attributeGroup.attributes && attributeGroup.attributes.length)
        const saladAttributesList = []
        const noSaladAttributes = []
        if (saladAttributeGroup && saladAttributeGroup.attributes && saladAttributeGroup.attributes.length) {
          saladAttributeGroup.attributes.map((attribute) => {
            return saladAttributesList.push(attribute)
          })
        }
        product.selected_attributes.map((attribute) => {
          if (saladAttributeGroup && saladAttributeGroup.attributes && saladAttributeGroup.attributes.length) {
            const saladAttribute = saladAttributeGroup.attributes.find((saladAttributeItem) =>
              saladAttributeItem.attribute_id.toString() === attribute.attribute_id.toString())
            if (saladAttribute) {
              saladAttributes.push(attribute.attribute)
              const findSelectedSaladIndex = saladAttributesList.findIndex((selectedSaladAttribute) =>
                selectedSaladAttribute.attribute_id.toString() === saladAttribute.attribute_id.toString())
              findSelectedSaladIndex > -1 && saladAttributesList.splice(findSelectedSaladIndex, 1)
            } else {
              selectedAttributes.push(attribute.attribute)
            }
          } else {
            selectedAttributes.push(attribute.attribute)
          }
          selectedAttributesIds.push(attribute.attribute_id)
          return selectedAttributes
        })
        let saladLabel = null
        if (saladAttributeGroup && saladAttributeGroup.attributes && saladAttributeGroup.attributes.length) {
          if (saladAttributes.length === saladAttributeGroup.attributes.length) {
            saladLabel = getLabelsBasedOnLanguage('cart.withSalad', props.code)
          } else if (saladAttributes.length === 0) {
            saladLabel = getLabelsBasedOnLanguage('cart.noSalad', props.code)
          } else if (saladAttributes.length < saladAttributeGroup.attributes.length / 2) {
            saladLabel = `${getLabelsBasedOnLanguage('cart.with', props.code)} ${saladAttributes.join(', ')}`
          } else {
            saladAttributesList.map((noSaladAttribute) => {
              return noSaladAttributes.push(noSaladAttribute.attribute)
            })
            saladLabel = `${getLabelsBasedOnLanguage('cart.without', props.code)} ${noSaladAttributes.join(', ')}`
          }
        }
        productList.push({
          ...product.product,
          qty: product.qty,
          cartItemId: product.cartItemId,
          selectedAttributesList: product.selected_attributes || [],
          selected_attributes: selectedAttributesIds,
          selectedAttributesNames: selectedAttributes,
          saladAttributesNames: saladLabel,
          selectedSize: product.product.selectedSize,
          selectedType: product.product.selectedType,
        })
      }
    })
    setProducts([...productList])
    if (productList.length && userInfoState.addresses.data && !props.cartCost.cartAddressId) {
      const primaryAddress = userInfoState.addresses.data.find((el) => el.is_primary === 1)
      ProductServices.updateCartAddress(primaryAddress, props.code).then((res) => {
        if (res.status === 'success') {
          props.onCartAddressUpdate({
            cartCost: {
              totalAmount: parseFloat(res.total_amount),
              deliveryCost: parseFloat(res.delivery_amount),
              typeOfOrder: res.type_of_order,
              paymentType: res.payment_type,
              cartAddressId: res.address_id,
              otherCharges: [{
                label: res.plastic_bag_label,
                charge: res.plastic_bag_charge ? parseFloat(res.plastic_bag_charge) : 0,
              }, {
                label: res.total_tax_label,
                charge: isNaN(res.total_tax_charge) ? 0 : parseFloat(res.total_tax_charge),
              }],
            },
          })
        }
      }).catch((e) => {
        console.log(e.message)
      })
    }
    newAddedProduct && insertCartItem(newAddedProduct)
  }, [props.products])

  const onEditProduct = (selectedProduct, index) => {
    if (selectedProduct) {
      setEditingProduct({
        ...selectedProduct,
        selectedIndex: index,
      })
      if (productState.categories.data && productState.categories.data.categories) {
        const productCategory = productState.categories.data.categories.find((category) => category.id === selectedProduct.category_id)
        productCategory && productCategory.description && setCartCategoryDescription(productCategory.description)
      }
    } else {
      setEditingProduct(null)
      setCartCategoryDescription(null)
      props.onCancelNewItem()
    }
  }
  const onClearCart = () => {
    setIsLoading(true)
    ProductServices.deleteCart(props.code).then((res) => {
      if (res.status === 'success') {
        props.onClearCart()
      }
    }).catch((e) => {
      console.log(e.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }
  const onDeleteProduct = (selectedProduct) => {
    if (props.products.length === 1) {
      onClearCart()
    } else {
      setIsLoading(true)
      ProductServices.deleteCartItem(selectedProduct.cartItemId, props.code).then((res) => {
        if (res.status === 'success') {
          const plasticBag = props.cartCost && props.cartCost.otherCharges && props.cartCost.otherCharges[0] ? props.cartCost.otherCharges[0] : {}
          props.onOrderDelete(selectedProduct.cartItemId, {
            totalAmount: parseFloat(res.total_amount),
            deliveryCost: parseFloat(res.delivery_amount),
            otherCharges: [{
              label: plasticBag.label,
              charge: plasticBag.charge,
            }, {
              label: res.total_tax_label,
              charge: isNaN(res.total_tax_charge) ? 0 : parseFloat(res.total_tax_charge),
            }],
          })
        }
      }).catch((e) => {
        console.log(e.message)
      }).finally(() => {
        setIsLoading(false)
      })
    }
  }
  const onAddToCartClick = (customizedProductObj) => {
    if (customizedProductObj.selectedIndex === 'new') {
      createCartItem(customizedProductObj)
      return
    }
    setEditingProduct(null)
    setCartCategoryDescription(null)
    setIsLoading(true)
    const index = customizedProductObj.selectedIndex
    ProductServices.updateCartItem({
      type_of_order: props.cartCost.typeOfOrder,
      paymentType: props.cartCost.payment_type,
      quantity: customizedProductObj.qty,
      cartItemId: products[index].cartItemId,
      attributes: customizedProductObj.selectedAttributesList,
    }, props.code).then((res) => {
      if (res.status === 'success') {
        const plasticBag = props.cartCost && props.cartCost.otherCharges && props.cartCost.otherCharges[0] ? props.cartCost.otherCharges[0] : {}
        props.onItemUpdate({
          cartItemId: res.item.id,
          qty: res.item.quantity,
          price: res.item.price,
          attributes: res.attributes || [],
          cartCost: {
            totalAmount: parseFloat(res.total_amount),
            deliveryCost: parseFloat(res.delivery_amount),
            otherCharges: [{
              label: plasticBag.label,
              charge: plasticBag.charge,
            }, {
              label: res.total_tax_label,
              charge: isNaN(res.total_tax_charge) ? 0 : parseFloat(res.total_tax_charge),
            }],
          },
        })
      }
    }).catch((e) => {
      console.log(e.message)
    }).finally(() => {
      setIsLoading(false)
    })
    setProducts(products)
  }

  const onUpdateAddress = (address) => {
    // props.actions.updateUserAddressAction(address)
    setIsLoading(true)
    ProductServices.updateCartAddress(address, props.code).then((res) => {
      if (res.status === 'success') {
        props.onCartAddressUpdate({
          cartCost: {
            totalAmount: parseFloat(res.total_amount),
            deliveryCost: parseFloat(res.delivery_amount),
            typeOfOrder: res.type_of_order,
            paymentType: res.payment_type,
            cartAddressId: res.address_id,
            otherCharges: [{
              label: res.plastic_bag_label,
              charge: res.plastic_bag_charge ? parseFloat(res.plastic_bag_charge) : 0,
            }, {
              label: res.total_tax_label,
              charge: isNaN(res.total_tax_charge) ? 0 : parseFloat(res.total_tax_charge),
            }],
          },
        })
      }
    }).catch((e) => {
      console.log(e.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }
  const handleSnackBarClose = () => {
    setTimeout(() => {
      setMessage('')
      setStatus('')
    }, 2500)
  }
  const handleCleanUp = () => {
    setTimeout(() => {
      setMessage('')
      setStatus('')
    }, 2500)
    props.actions.cleanUpState()
  }
  const onKeyUp = (event) => {
    if (event.keyCode === 13) {
      checkPostalCode()
    }
  }
  return (<MyDiv>
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{alignItems: 'center', justifyContent: 'center'}}
      open={termsAndConditionsModal}
    >
      <div style={{
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        position: 'absolute',
        overflow: 'auto',
        height: '80vh',
        borderRadius: '20px',
        backgroundColor: '#fff',
        outline: 'none',
      }}
      >
        <IconButton aria-label="close" onClick={() => setTermsAndConditionsModal(false)} style={{float: 'right'}}>
          <CloseIcon />
        </IconButton>
        <div className="set-on-devices">
          <TermsAndConditionsComp />
        </div>
      </div>
    </Modal>
    <Snackbar
      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      open={showMessage.open} autoHideDuration={6000} onClose={handleCloseAlert}
    >
      <Alert onClose={handleCloseAlert} severity={showMessage.type}>
        {showMessage.message}
      </Alert>
    </Snackbar>
    {(userInfoState.addresses.loading || isLoading) && <div className="spinner">
      <CircularProgress style={{height: '25px', width: '25px', zIndex: 1000, position: 'absolute', top: '50%'}} />
    </div>
    }
    <div className="inputPostalDiv">
      <input type="text" onChange={(e) => setSearchData(e.target.value)} value={searchData} onKeyUp={(e) => onKeyUp(e)}
        placeholder={getLabelsBasedOnLanguage('cart.postalcode', props.code)}
      />
      {searchingPostalCode &&
      <div className="search_loader"><CircularProgress size={32} /></div>
      }
      <div className="search_icon">
        <SearchIcon onClick={checkPostalCode} />
      </div>
    </div>
    <div className="cart">
      <div className="scroll">
        {
          products && products.length && userInfoState.addresses && userInfoState.addresses.data ?
            <AddressSelector addresses={userInfoState.addresses.data} onUpdateAddress={onUpdateAddress}
              cartAddressId={props.cartCost.cartAddressId} code={props.code}
            /> : null
        }
        {
          editingProduct && <ProductCustomizations product={editingProduct} cartCategoryDescription={cartCategoryDescription}
            onCancel={onEditProduct} onAddToCartClick={onAddToCartClick} code={props.code}
          />
        }
        {
          products && products.length ? <div>
            <div className="poRow">
              <span className="clearCart" onClick={() => onClearCart()}>
                {getLabelsBasedOnLanguage('cart.clearCart', props.code)}
              </span>
            </div>
            <div>
              {
                products.map((product, index) => (
                  <div key={`product_order_${index}`} className="productOrder">
                    <div className="poRow poPadding" style={{marginTop: '20px', minHeight: '22px'}}>
                      <div className="poLeft">
                        <span className="poName">{product.qty}x {product.name}
                          {product.selectedType && product.selectedType.type ? `-${product.selectedType.type}` : ''}
                          {product.selectedSize && product.selectedSize.size ? `, ${product.selectedSize.size}` : ''}
                        </span>
                        {
                          product.saladAttributesNames ? <span> {product.saladAttributesNames}</span> : null
                        }
                        {
                          product.selectedAttributesNames.length ? <span>
                            +{product.selectedAttributesNames.join(' +')}
                          </span> : null
                        }
                      </div>
                      <div className="poRight">
                        <span className="poPrice">€{(product.price).toFixed(2)}</span>
                        <div className="actions">
                          <div className="actionIcon editIcon" onClick={() => onEditProduct(product, index)}>
                            <EditIcon />
                          </div>
                          <div className="actionIcon deleteIcon">
                            <DeleteIcon onClick={() => onDeleteProduct(product)} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
            <div className="poRow poPadding">
              <span className="notice">{getLabelsBasedOnLanguage('cart.note', props.code)}</span>
            </div>
            <div className="poRow poPadding deliveryOption">
              <span className={props.cartCost.typeOfOrder.toString() === deliveryOptionsEnum.delivery.toString()
                ? 'toggleBtn addOrder' : 'toggleBtn'} onClick={() => onDeliveryOptionChange(deliveryOptionsEnum.delivery)}
              >{getLabelsBasedOnLanguage('cart.delivery', props.code)}</span>
              <span className={props.cartCost.typeOfOrder.toString() === deliveryOptionsEnum.takeAway.toString() ? 'toggleBtn addOrder' : 'toggleBtn'}
                onClick={() => onDeliveryOptionChange(deliveryOptionsEnum.takeAway)}
              >{getLabelsBasedOnLanguage('cart.takeAway', props.code)}</span>
            </div>
            <div className="poRow poPadding deliveryOption">
              <span className={props.cartCost.paymentType.toString() === paymentOptionsEnum.COD.toString()
                ? 'toggleBtn addOrder' : 'toggleBtn'} onClick={() => onPaymentOptionChange(paymentOptionsEnum.COD)}
              >{getLabelsBasedOnLanguage('cart.COD', props.code)}</span>
              <span className={props.cartCost.paymentType.toString() === paymentOptionsEnum.online.toString() ? 'toggleBtn addOrder' : 'toggleBtn'}
                onClick={() => onPaymentOptionChange(paymentOptionsEnum.online)}
              >{getLabelsBasedOnLanguage('cart.online', props.code)}</span>
            </div>
            <div className="orderPricing poRow poPadding">
              {
                props.cartCost.typeOfOrder.toString() === deliveryOptionsEnum.delivery.toString() ? <div>
                  <span className="label">{getLabelsBasedOnLanguage('cart.deliveryCost', props.code)}</span>
                  <span className="cost">
                    {
                      props.cartCost.deliveryCost === 0 ? getLabelsBasedOnLanguage('cart.freeDelivery', props.code) :
                        `€${(props.cartCost.deliveryCost).toFixed(2)}`
                    }
                  </span>
                </div> : null
              }
              <div>
                <span className="label">{getLabelsBasedOnLanguage('cart.otherCharges', props.code)}</span>
              </div>
              <div className="otherCharges">
                {
                  props.cartCost.otherCharges.map((otherCharge, index) => {
                    return (
                      <div key={`otherCharge_${index}`}>
                        <span className="label">{otherCharge.label}</span>
                        <span className="cost">€{(otherCharge.charge).toFixed(2)}</span>
                      </div>
                    )
                  })
                }
              </div>
              <div className="orderTotal">
                <span className="label">{getLabelsBasedOnLanguage('cart.orderTotal', props.code)}</span>
                <span className="cost">€{(props.cartCost.totalAmount).toFixed(2)}</span>
              </div>
            </div>
            <div className="poRow textArea">
              <textarea type="textarea" defaultValue={commentDefault} onBlur={(e) => updateComment(e)}
                placeholder={getLabelsBasedOnLanguage('cart.comments', props.code)}
              />
            </div>
          </div> : <div className="emptyCart">
            <div>
              <FastFoodIcon />
            </div>
            <h3>
              {getLabelsBasedOnLanguage('cart.emptyCart', props.code)}
            </h3>
          </div>
        }
      </div>
      <div className="poRow termsAndConditions">
        <FormControlLabel
          style={{height: '10px', marginBottom: '10px'}}
          control={
            <Checkbox
              name="terms and conditions"
              color="primary"
              checked={termsAndConditions}
              onChange={() => setTermsAndConditions(!termsAndConditions)}
            />
          }
          label={<span>
            {`${getLabelsBasedOnLanguage('cart.termsAndConditions', props.code)} `}
            <label className="link" onClick={() => setTermsAndConditionsModal(true)}>{getLabelsBasedOnLanguage('cart.termsAndConditionsLink', props.code)}</label>
          </span>}
        />
      </div>
      <div className="poRow deliveryOption placeOrder">
        <span className={products.length && termsAndConditions ? 'toggleBtn addOrder'
          : 'toggleBtn addOrder disabled'} onClick={() => onPlaceOrderClick()}
        >{getLabelsBasedOnLanguage('cart.placeOrder', props.code)}</span>
      </div>
    </div>
    <AuthenticationModal
      open={isAuthModal}
      close={handleLoginBtnClick}
      message={message}
      status={status}
      handleCleanUp={handleCleanUp}
      handleSnackBarClose={handleSnackBarClose}
    />
  </MyDiv>)
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(ProductCart)
