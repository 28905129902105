import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {Grid, Typography} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import ConfirmOTP from '../../ConfirmOTP'
import CustomButton from '../../inputs/button'
import {getLabelsBasedOnLanguage} from '../../../constants/common'
// import SnackbarComponent from '../../../components/feedback/snackbar/snackbar'
import VerifyOtpFormStyled from './verifyotp.styles'

const VerifyOtpForm = (props) => {
  const {code} = props
  const [otp, setOTP] = useState('')
  useEffect(() => {
    return () => {
      props.cleanUp()
    }
  }, [])
  const handleChange = (e) => {
    setOTP(e)
  }

  const changeView = (n) => {
    props.view(n)
  }

  const handleOtpVerification = () => {
    props.submit(otp)
  }

  return (
    <VerifyOtpFormStyled>
      <form>
        {/* <SnackbarComponent
          message={msg}
          severity={status}
          duration={1000}
          open={msg.length > 0}
          close={props.closeMsg}
        /> */}
        {props.isLoading ? <div className="spinner">
          <CircularProgress style={{height: '40px', width: '40px', position: 'fixed', top: '50%', left: '49%'}} />
        </div> : null
        }
        <Grid container>
          <Grid item lg={1} md={1} sm={1} xs={1} />
          <Grid item lg={10} md={10} sm={10} xs={10}>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12} className="center_element">
                <Typography
                  variant="h4"
                >
                  {getLabelsBasedOnLanguage('verifyotp.title', code)}
                </Typography>
                <br />
                <br />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} className="center_element">
                <Typography
                  variant="subtitle1"
                >
                  {`${getLabelsBasedOnLanguage('verifyotp.subtitle', code)}?`} <span className="forgotpassword" onClick={() => changeView(0)}>
                    {getLabelsBasedOnLanguage('inputs.buttons.signin', code)}</span>
                </Typography>
                <br />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ConfirmOTP
                  value={otp}
                  label="OTP"
                  digit={4}
                  onChange={handleChange}
                  // className: PropTypes.object
                />
              </Grid>
              {props.error && <span className="error">{props.error.otp}</span>}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <CustomButton
                  label={getLabelsBasedOnLanguage('inputs.buttons.verify', code)}
                  onClick={handleOtpVerification}
                  size="large"
                  color="primary"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={1} />
        </Grid>
      </form>
    </VerifyOtpFormStyled>
  )
}

VerifyOtpForm.propTypes = {
  view: PropTypes.func,
  submit: PropTypes.func,
  code: PropTypes.string,
  msg: PropTypes.string,
  status: PropTypes.string,
  closeMsg: PropTypes.func,
  cleanUp: PropTypes.func,
}

export default VerifyOtpForm
