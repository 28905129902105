import React, {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {Modal, FormControlLabel, Checkbox} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {getLabelsBasedOnLanguage} from '../../../../constants/common'
import MyDiv from './addressSelector.style'

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    //width: 500,
    backgroundColor: theme.palette.background.paper,
    outline: 'none',
    boxShadow: theme.shadows[5],
    padding: '68px',
    borderRadius: '20px',
  },
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    marginTop: '20px',
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

const ProductCustomizations = React.memo((props) => {
  const classes = useStyles()
  const masterState = useSelector((state) => state.masterState)
  const [defaultAddress, setDefaultAddress] = useState(null)
  const [selectedAddress, setSelectedAddress] = useState({})
  const [open, setOpen] = useState(false)
  useEffect(() => {
    const primaryAddress = props.addresses && props.addresses.find((el) => el.id === props.cartAddressId)
    setDefaultAddress(primaryAddress || null)
    primaryAddress && setSelectedAddress(primaryAddress)
  }, [props.cartAddressId])

  const drawAddress = (addressObj) => {
    let address = ''
    if (addressObj.address1) {
      address = addressObj.address1
    }
    if (addressObj.address2) {
      address = address.trim().length ? `${address} ${addressObj.address2}` : addressObj.address2
    }
    if (addressObj.city_id && masterState && masterState.cities && masterState.cities.data) {
      const city = masterState.cities.data.find((el) => el.id === addressObj.city_id)
      if (city) {
        address += `, ${city.city}`
      }
    }
    if (addressObj.post_code) {
      address += ` ${addressObj.post_code}`
    }
    return address
  }

  const toggleModal = (toggleValue) => {
    setOpen(toggleValue)
  }

  const onCancelAddressChange = () => {
    setSelectedAddress(defaultAddress)
    toggleModal(false)
  }

  const onAddressChange = (event, address) => {
    if (event.target.checked) {
      setSelectedAddress(address)
    }
  }

  const onUpdateAddress = () => {
    toggleModal(false)
    props.onUpdateAddress({
      ...selectedAddress,
    })
  }

  return (
    <div>
      {
        defaultAddress && <MyDiv>
          <div className="addressDetails">
            <div className="addressLeft">
              <div>{drawAddress(defaultAddress)}</div>
            </div>
            <div className="addressRight">
              <span className="toggleBtn addOrder" onClick={() => toggleModal(true)}>
                {getLabelsBasedOnLanguage('cart.changeAddress', props.code)}
              </span>
            </div>
          </div>
          {/* <div className="postCode">
            <span>{defaultAddress.post_code}</span>
          </div> */}
        </MyDiv>
      }
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{alignItems: 'center', justifyContent: 'center'}}
        open={open}
      >
        <div style={{
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }} className={classes.paper}
        >
          <MyDiv>
            <span className="changeTitle">{getLabelsBasedOnLanguage('cart.changeAddress', props.code)}</span>
            {
              props.addresses && props.addresses.map((address, addressIndex) => {
                return (
                  <div key={`change_address_${addressIndex}`}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={address.id === selectedAddress.id}
                          onChange={(event) => onAddressChange(event, address)}
                          name="addresses"
                          color="primary"
                        />
                      }
                      label={<span>{drawAddress(address)}</span>}
                    />
                  </div>
                )
              })
            }
            <div className="actionsList">
              <span className="toggleBtn" onClick={() => onCancelAddressChange()}>
                {getLabelsBasedOnLanguage('cart.cancel', props.code)}
              </span>
              <span className="toggleBtn addOrder" onClick={() => onUpdateAddress()}>
                {getLabelsBasedOnLanguage('cart.changeAddress', props.code)}
              </span>
            </div>
          </MyDiv>
        </div>
      </Modal>
    </div>)
})
export default ProductCustomizations
