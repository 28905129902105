import * as actionsType from '../actions/actionsType'


const INITIAL_STATE = {
  products: {
    data: null,
    error: null,
    loading: false,
  },
  categories: {
    data: null,
    error: null,
    loading: false,
  },
}

const productReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionsType.PRODUCT_CATEGORIES_REQUEST:
      return {
        ...state,
        categories: {
          ...state.categories,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionsType.PRODUCT_CATEGORIES_FAILURE:
      return {
        ...state,
        categories: {
          ...state.categories,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionsType.PRODUCT_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: {
          ...state.categories,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionsType.PRODUCT_LIST_REQUEST:
      return {
        ...state,
        products: {
          ...state.products,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionsType.PRODUCT_LIST_FAILURE:
      return {
        ...state,
        products: {
          ...state.products,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionsType.PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        products: {
          ...state.products,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    default:
      return state
  }
}

export default productReducers
