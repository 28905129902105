import styled from 'styled-components'

const MyDiv = styled.div `
.toggleBtn{
  border-radius: 20px;
  min-width: 115px;
  text-align: center;
  padding: 7px 16px;
  display: inline-block;
  cursor: pointer;
  border: 1px solid #70707020;
 // font-family: Proxima Nova;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}

.addOrder{
  background-color: #ED4E53;
  border: none;
  color: #FFFFFF;
  @media only screen and (max-width: 767px) {    
    padding:7px 14px;
  }
  @media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px) 
  and (-webkit-min-device-pixel-ratio: 2){
    padding:7px 14px;
    font-size:14px;
  }
}
.changeTitle{
  //font-family: Proxima Nova;
  font-size: 22px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 25px;
  display: block;
}
@media only screen and (max-width: 768px) {
  addressLeft {
    width: 100%;
  }
}
.addressDetails{
  display: flow-root;
  margin: 25px 0px 10px 9px; 
  .addressLeft{
    float: left;
    //font-family: Proxima Nova;
    font-size: 18px;
    font-weight: 400;
    color: #666666;
    width: 60%;
    display: -webkit-box;
    white-space: normal;
    text-align: left;
    @media only screen and (max-width: 767px) {        width: 50%;
    }
    @media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px) 
  and (-webkit-min-device-pixel-ratio: 2){
    width:50%;
  }
  }
  .addressRight{
    float: right;
    @media only screen and (max-width: 767px) {        width: 50%;
      }
      @media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px) 
  and (-webkit-min-device-pixel-ratio: 2){
    width: 50%;
  }
}
.postCode{
  margin: 32px 0px 51px 9px;
  span{
    background-color: #FFFFFF;
    border-radius: 20px;
    padding: 11px 28px;
    //font-family: Proxima Nova;
    font-size: 20px;
    font-weight: 400;
    color: #666666;
    width: 100%;
    display: block;
    text-align: left;
  }
}
.actionsList{
  margin-top: 40px;
  span{
    margin-right: 20px;
  }
}
`
export default MyDiv
