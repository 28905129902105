export function getToken(key) {
  return localStorage.getItem(key)
}

export function setToken(key, value) {
  return localStorage.setItem(key, value)
}

export function removeToken(key) {
  return localStorage.removeItem(key)
}

export const isAuthenticated = (user) => !!user

export const isAllowed = (user, rights) =>
  rights.some((right) => user.rights.includes(right))

export const hasRole = (user, roles) =>
  roles.some((role) => user.roles.includes(role))
