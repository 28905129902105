import styled from 'styled-components'

const MyDiv = styled.div `
position: sticky;
top: 1rem;
z-index: 1;
bottom: auto;
margin-top: 0px;
.inputPostalDiv{
  background-color: #FFFFFF;
  margin-bottom: 10px;
  input[type=text] {
    padding: 14px 28px;
    display: inline-block;
    border: 1px solid #E9E9E9;
    box-sizing: border-box;
    outline: 0;
    border-radius: 20px;
    box-shadow: rgb(0 0 0 / 16%) 0px 3px 24px;
    width: 100%;
  }
}
.search_icon{
  position: absolute;
  right: 28px;
  top: 11px;
  color: #ED4E53;
  cursor: pointer;
}
.search_loader{
  position:absolute;
  left:50%;
  top:5px;
}
.cart {
  background-color: #F9F9F9;
  border-radius: 20px;
  box-shadow: rgb(0 0 0 / 16%) 0px 3px 24px;
  flex-basis: auto;
  max-width: none;
  visibility: visible;
  padding: 13px 4px;
  .emptyCart{
    height: 280px;
    div{
      border-radius: 50%;
      background-color: #00000050;
      margin-top: 75px;
      width: fit-content;
      margin-right: auto;
      margin-left: auto;
      padding: 15px;
      svg{
        font-size: 6rem;
        color: #FFFFFF;
      }
    }
    h3{
      //font-family: Proxima Nova;
      font-size: 18px;
      font-weight: 200;
      color: #00000050;
    }
  }
  .scroll {
    max-height: 515px;
    overflow-x: auto;
    padding: 4px 13px;
  }
  .scroll::-webkit-scrollbar {
    width: 8px;
  }

  .scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0px rgba(0,0,0,0.3);
    border-radius: 20px;
  }

  .scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
  }
  .clearCart{
    display: block;
    float: right;
    //font-family: Proxima Nova;
    font-size: 20px;
    font-weight: 400;
    color: #ED4E53;
    cursor: pointer;
    padding: 0px 22px;
    margin-bottom: 5px;
  }
  .poPadding{
    padding: 0px 10px;
  }
  .termsAndConditions{
    margin-top: 10px;
    padding: 4px 13px;
    text-align: left;
    span{
      font-size: 15px;
      font-weight: 400;
    }
    .link{
      color: #3700B3;
      cursor: pointer;
      z-index: 1000;
      
    }
  }
  @media only screen and (max-width: 768px) {
    poLeft {
      width: 100%;
    }
  }
  .poRow{
    display: inline-block;
    width: 100%;
    .poLeft{
      float: left;
      display: flex;
      display: inline-grid;
      text-align: left;
      width: 60%;
      .poName{
      // font-family: Proxima Nova;
        font-size: 15px;
        font-weight: 600;
        color: #000000;
      }
    }
    .poRight{
      float: right;
      display: flex;
      .poPrice{
      // font-family: Proxima Nova;
        font-size: 15px;
        font-weight: 400;
        color: #000000;
        width: 82px;
        text-align: center;
        
      }
      .actions{
        display: flex;
        .actionIcon{
          font-size: 20px;
          cursor: pointer;
        }
        .deleteIcon{
          color: #ED4E53;
        }
      }
    }
    .poIncludes{
      //font-family: Proxima Nova;
      font-size: 14px;
      font-weight: 400;
      color: #000000;
    }
    .notice{
      //font-family: Proxima Nova;
      font-size: 15px;
      font-weight: 400;
      color: #000000;
      float: left;
      margin: 0 5px;
      text-align: left;
      margin-top: 29px;
    }
  }
  .productOrder{
    min-height: 72px;
    border-bottom: 2px solid #70707050;
    margin: 0 5px;
  }
  .deliveryOption{
    margin-top: 10px;
    .toggleBtn{
      border-radius: 20px;
      width: 170px;
      text-align: center;
      padding: 7px 0px;
      display: inline-block;
      cursor: pointer;
      border: 1px solid #70707020;
      margin-right: 14px;
    //font-family: Proxima Nova;
      font-size: 16px;
      font-weight: 400;
      color: #000000;
      background-color: #FFFFFF;
    }
    
    .addOrder{
      background-color: #ED4E53;
      border: none;
      color: #FFFFFF;
    }
    .disabled{
      background-color: #00000050;
      cursor: not-allowed;
    }
  }
  .orderPricing{
    margin-top: 48px;
    div{
      display: flow-root;
      margin: 0px 5px;
    }
    .label{
    // font-family: Proxima Nova;
      font-size: 15px;
      font-weight: 400;
      color: #000000;
      float: left;
    }
    .cost{
    // font-family: Proxima Nova;
      font-size: 15px;
      font-weight: 400;
      color: #000000;
      float: right;
    }
    .orderTotal{
      margin: 13px 5px;
    }
    .otherCharges{
      margin-left: 20px;
      margin-bottom: 0px;
      div{
        margin-right: 0px;
      }
    }
  }
  .textArea{
    padding: 0px 4px;
    textarea {
      resize: none;
      height: 150px;
      width: 100%;
      border-radius: 20px;
      border: 1px solid #70707050;
      outline: none;
      padding: 13px 18px;
      margin-top: 30px;
    }
  }
  .placeOrder{
    margin-top: 5px;
    margin-bottom: 15px;
  }
}
.spinner {
  position: absolute!important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #bdbdbd7a;
  z-index: 1000;
  border-radius: 20px;
}
`
export default MyDiv
