import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'
import {ThemeProvider as MaterialUiThemeProvider, StylesProvider} from '@material-ui/core/styles'
import {ThemeProvider as StyledThemeProvider} from 'styled-components'
import './index.css'
import App from './views/App/App'
import reportWebVitals from './reportWebVitals'
import theme from './config/theme/theme.config'
import configureStore from './redux/store'

const store = configureStore()

const themeDetails = theme

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <React.StrictMode>
        <StylesProvider>
          <StyledThemeProvider theme={themeDetails}>
            <MaterialUiThemeProvider theme={themeDetails}>
              <App />
            </MaterialUiThemeProvider>
          </StyledThemeProvider>
        </StylesProvider>
      </React.StrictMode>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
