import {combineReducers} from 'redux'

import authReducers from './authReducers'
import productReducers from './productReducers'
import configReducers from './configReducers'
import userReducers from './userReducers'
import masterReducers from './masterReducers'
import paymentReducers from './paymentReducers'
const rootReducer = combineReducers({
  authState: authReducers,
  productState: productReducers,
  configState: configReducers,
  userState: userReducers,
  masterState: masterReducers,
  paymentState: paymentReducers,
})

export default rootReducer
