import apiInstance from '../config/api/axios'

export const signin = async(phone, password, lang) => {
  let config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }
  const data = new FormData()
  data.append('phone', phone)
  data.append('password', password)
  const response = await apiInstance.post(`login?lang=${lang || 'en'}`, data, config)
  return response
}


export const checkSession = async(lang) => {
  const response = await apiInstance.get(`user?lang=${lang || 'en'}`, null)
  return response
}


export const signup = async(form, lang) => {
  let config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }
  const data = new FormData()
  data.append('name', form.name)
  data.append('surname', form.surname)
  data.append('phone', form.phone)
  data.append('password', form.password)
  const response = await apiInstance.post(`register/otp/send?lang=${lang || 'en'}`, data, config)
  return response
}


export const otpVerification = async(otp, otpid, type, lang) => {
  let config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }
  const data = new FormData()
  data.append('otp', otp)
  data.append('otp_id', otpid)
  let url = ''
  if (type === 1) {
    url = `register/otp/verify?lang=${lang || 'en'}`
  } else {
    url = `user/forget-password/otp/verify?lang=${lang || 'en'}`
  }
  const response = await apiInstance.post(url, data, config)
  return response
}


export const forgotPassword = async(form, lang) => {
  let config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }
  const data = new FormData()
  data.append('phone', form.phone)
  const response = await apiInstance.post(`user/forget-password/otp/send?lang=${lang || 'en'}`, data, config)
  return response
}


export const changePassword = async(form, otpId, otp, type, lang) => {
  let response = ''
  let config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }
  if (type === 0) {
    response = await apiInstance.post(`user/password/change?otp_id==${otpId}&otp=${otp}&password=${form.password}&password_confirmation=${form.newpassword}&lang=${lang || 'en'}`, config)
  } else {
    const data = new FormData()
    data.append('old_password', form.oldpassword)
    data.append('new_password', form.newpassword)
    data.append('confirm_new_password', form.confirmpassword)
    response = await apiInstance.post('user/change-password', data, config)
  }

  return response
}
export const changePhoneNumber = async(form, lang) => {
  let config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }
  const data = new FormData()
  data.append('new_phoneno', form.new_phoneno)
  const response = await apiInstance.post(`user/change/mobile?lang=${lang || 'en'}`, data, config)
  return response
}
export const updateProfileImage = async(form, lang) => {
  let config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }
  const data = new FormData()
  data.append('photo', form)
  const response = await apiInstance.post(`user/profile/update?lang=${lang || 'en'}`, data, config)
  return response
}
