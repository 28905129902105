import React, {useState, useEffect} from 'react'
import {Grid, Snackbar} from '@material-ui/core'
import {Alert} from '@material-ui/lab'
import {useSelector, connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {bindActionCreators} from 'redux'
import {compose} from 'recompose'
import {ActionCreators} from '../../redux/actions'
import {getLabelsBasedOnLanguage} from '../../constants/common'
import Orders from './Orders'
import Addresses from './Address'
import MyProfile from './Profile'
import MyDiv from './settings.style'

export const Settings = (props) => {
  const paymentState = useSelector((state) => state.paymentState?.redirection?.type)
  const paymentStatus = useSelector((state) => state.paymentState?.paymentstatus?.status)
  const config = useSelector((state) => state.configState)
  const [selectedTab, setSelectedTab] = useState(paymentState || 'MP')
  const [isSnackbarOpen, setSnackbarData] = useState(false)
  const cleanUp = () => {
    props.actions.cleanUpPaymentRedirection()
  }
  const closeSnackbar = () => {
    setSnackbarData(false)
    props.actions.cleanUpPaymentStatus()
  }
  useEffect(() => {
    if (paymentStatus && paymentStatus.length) {
      setSnackbarData(true)
      props.actions.cleanUpPaymentStatus()
    }
  }, [])
  const tabsList = {
    MP: {
      title: 'myProfile',
      component: <MyProfile code={config.language.code} />,
    },
    MA: {
      title: 'myAddresses',
      component: <Addresses code={config.language.code} />,
    },
    MO: {
      title: 'myOrders',
      component: <Orders code={config.language.code} cleanUp={cleanUp} />,
    },
  }
  return (
    <MyDiv>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        open={isSnackbarOpen} autoHideDuration={6000} onClose={closeSnackbar}
      >
        <Alert onClose={closeSnackbar} severity={paymentStatus}>
          {getLabelsBasedOnLanguage('paymentStatusCheck.success', config.language.code)}
        </Alert>
      </Snackbar>
      <Grid container className="" spacing={4}>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <div className="settingsList">
            <label className="label">{getLabelsBasedOnLanguage('profile.mainSettings', config.language.code)}</label>
            {
              Object.keys(tabsList).map((key) => {
                return (
                  <span key={key} onClick={() => setSelectedTab(key)} className={key === selectedTab ? 'selected' : ''}>
                    {getLabelsBasedOnLanguage(`profile.${tabsList[key].title}.title`, config.language.code)}
                  </span>
                )
              })
            }
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={9} lg={9}>
          <div className="component">
            {
              tabsList[selectedTab].component
            }
          </div>
        </Grid>
      </Grid>
    </MyDiv>
  )
}
const mapDispatchToProps = (dispath) => ({
  actions: bindActionCreators(ActionCreators, dispath),
})
export default
React.memo(compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(Settings))
