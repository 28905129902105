import React, {} from 'react'
import {useSelector} from 'react-redux'
import Grid from '@material-ui/core/Grid'
import {getLabelsBasedOnLanguage} from '../../constants/common'
import MyDiv from './contactUs.style'

const AddressMap = () => {
  return (
    <div className="google-map-code">
      <iframe
        src={'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13046.002899627554!2d33.3480019!3d35.1690705!' +
        '3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa7d4086420b5e780!2sGia%20Souvlaki%20Etsi%20Apla!5e0!3m2!1sen!' +
        '2sin!4v1628575670529!5m2!1sen!2sin'}
        frameBorder="0" style={{border: 0}} allowFullScreen="" aria-hidden="false" tabIndex="0"
      />
    </div>
  )
}

const ContactUs = React.memo((props) => {
  const config = useSelector((state) => state.configState)
  return (<MyDiv>
    <Grid container>
      <Grid item md={2} lg={2} />
      <Grid item xs={12} sm={12} md={8} lg={6}>
        <span className="contactUsTitle">{getLabelsBasedOnLanguage('contactUs.menu', config.language.code)}</span>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item md={2} lg={2} />
          <Grid item xs={12} sm={12} md={4} lg={3} style={{display: 'flex'}}>
            <div className="paragraph">
              <div>
                Gia Souvlaki Etsi Apla
              </div>
              <div>
                <span>{getLabelsBasedOnLanguage('contactUs.address', config.language.code)} </span>
                Metochiou 41b 1101, Nicosia
              </div>
              <div>
                <span>{getLabelsBasedOnLanguage('contactUs.phone', config.language.code)} </span>
                +357 22263400
              </div>
              <div>
                <span>{getLabelsBasedOnLanguage('contactUs.email', config.language.code)} </span>
                info@giasouvlakietsiapla.com
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={5} style={{display: 'flex'}}>
            <div style={{height: '400px', width: '100%', padding: '2rem 0 0 2rem'}}>
              <AddressMap />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </MyDiv>)
})
export default ContactUs
