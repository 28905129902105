import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {Grid, Typography} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import {NavLink} from 'react-router-dom'
import * as routesNames from '../../../constants/routes'
// import SnackbarComponent from '../../../components/feedback/snackbar/snackbar'
import {getLabelsBasedOnLanguage} from '../../../constants/common'
import CustomInput from '../../inputs/textbox'
import CustomButton from '../../inputs/button'
import SignUpFormStyled from './signup.styles'


const SignUpForm = (props) => {
  const {code} = props
  const [form, setForm] = useState({
    name: '',
    surname: '',
    phone: '',
    password: '',
  })
  useEffect(() => {
    return () => {
      props.cleanUp()
    }
  }, [])
  const handleChange = (e) => {
    let data = {
      ...form,
      [e.target.name]: e.target.value,
    }
    setForm(data)
  }

  const changeView = (n) => {
    props.view(n)
  }

  const handleSignUp = () => {
    props.submit(form)
  }

  return (
    <SignUpFormStyled>
      <form>
        {/* <SnackbarComponent
          message={msg}
          severity={status}
          duration={1000}
          open={msg.length > 0}
          close={props.closeMsg}
        /> */}
        {props.isLoading ? <div className="spinner">
          <CircularProgress style={{height: '40px', width: '40px', position: 'fixed', top: '50%', left: '49%'}} />
        </div> : null
        }
        <Grid container>
          <Grid item lg={1} md={1} sm={1} xs={1} />
          <Grid item lg={10} md={10} sm={10} xs={10}>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12} className="center_element">
                <Typography
                  variant="h4"
                >
                  {getLabelsBasedOnLanguage('signup.title', code)}
                </Typography>
                <br />
                <br />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} className="center_element">
                <Typography
                  variant="subtitle1"
                >
                  {getLabelsBasedOnLanguage('signup.subtitle', code)}
                </Typography>
                <br />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <CustomInput
                  label={`${getLabelsBasedOnLanguage('inputs.labels.name', code)}*`}
                  type="text"
                  name="name"
                  value={form.name}
                  multiple={false}
                  onChange={handleChange}
                  error={null}
                />
                {props.error && <span className="error">{props.error.name}</span>}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <CustomInput
                  label={`${getLabelsBasedOnLanguage('inputs.labels.surname', code)}*`}
                  type="text"
                  name="surname"
                  value={form.surname}
                  multiple={false}
                  onChange={handleChange}
                  error={null}
                />
                {props.error && <span className="error">{props.error.surname}</span>}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <CustomInput
                  label={`${getLabelsBasedOnLanguage('inputs.labels.phone', code)}*`}
                  type="number"
                  name="phone"
                  value={form.phone}
                  multiple={false}
                  onChange={handleChange}
                  error={null}
                />
                {props.error && <span className="error">{props.error.phone}</span>}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <CustomInput
                  label={`${getLabelsBasedOnLanguage('inputs.labels.password', code)}*`}
                  type="password"
                  name="password"
                  value={form.password}
                  multiple={false}
                  onChange={handleChange}
                  error={null}
                />
                {props.error && <span className="error">{props.error.password}</span>}
                <br />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography
                  variant="subtitle1"
                  className="forgot_password"
                  onClick={() => changeView(2)}
                >
                  {`${getLabelsBasedOnLanguage('signup.subtitle1', code)}?`}
                </Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <CustomButton
                  label={getLabelsBasedOnLanguage('inputs.buttons.signup', code)}
                  onClick={handleSignUp}
                  size="large"
                  color="primary"
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} className="center_element">
                <Typography
                  variant="subtitle2"
                >
                  {getLabelsBasedOnLanguage('signup.subtitle2', code)} <span className="forgot_password">
                    <NavLink onClick={() => props.close()} to={routesNames.TERMS_AND_CONDITIONS} style={{textDecoration: 'none', color: 'inherit'}}
                      activeStyle={{color: 'inherit'}}
                    >
                      {getLabelsBasedOnLanguage('signup.termservice', code)}
                    </NavLink>
                  </span> {getLabelsBasedOnLanguage('signup.and', code)}&nbsp;
                  <span className="forgot_password">
                    <NavLink onClick={() => props.close()} to={routesNames.PRIVACY_POLICY} style={{textDecoration: 'none', color: 'inherit'}}
                      activeStyle={{color: 'inherit'}}
                    >
                      {getLabelsBasedOnLanguage('signup.privacypolicy', code)}
                    </NavLink>
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={1} />
        </Grid>
      </form>
    </SignUpFormStyled>
  )
}

SignUpForm.propTypes = {
  view: PropTypes.func,
  submit: PropTypes.func,
  code: PropTypes.string,
  msg: PropTypes.string,
  status: PropTypes.string,
  cleanUp: PropTypes.func,
  closeMsg: PropTypes.func,
}

export default SignUpForm
