import * as actionTypes from '../actions/actionsType'
const INITIAL_STATE = {
  countries: {
    data: null,
    loading: false,
    error: null,
  },
  cities: {
    data: null,
    loading: false,
    error: null,
  },
  postalCodes: {
    data: null,
    loading: false,
    error: null,
  },
  languages: {
    data: null,
    loading: false,
    error: null,
  },
  restroTimings: {
    data: [],
    loading: false,
    error: null,
  },
}

const masterReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_COUNTRY_REQUEST:
      return {
        ...state,
        countries: {
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.GET_COUNTRY_SUCCESS:
      return {
        ...state,
        countries: {
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.GET_COUNTRY_FAILURE:
      return {
        ...state,
        countries: {
          ...state.countries,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.GET_CITY_REQUEST:
      return {
        ...state,
        cities: {
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.GET_CITY_SUCCESS:
      return {
        ...state,
        cities: {
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.GET_CITY_FAILURE:
      return {
        ...state,
        cities: {
          ...state.cities,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.GET_POSTAL_CODE_REQUEST:
      return {
        ...state,
        postalCodes: {
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.GET_POSTAL_CODE_SUCCESS:
      return {
        ...state,
        postalCodes: {
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.GET_POSTAL_CODE_FAILURE:
      return {
        ...state,
        postalCodes: {
          ...state.postalCodes,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.GET_LANGUAGES_REQUEST:
      return {
        ...state,
        languages: {
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.GET_LANGUAGES_SUCCESS:
      return {
        ...state,
        languages: {
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.GET_LANGUAGES_FAILURE:
      return {
        ...state,
        languages: {
          ...state.countries,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.GET_RESTRO_TIMING_REQUEST:
      return {
        ...state,
        restroTimings: {
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.GET_RESTRO_TIMING_SUCCESS:
      return {
        ...state,
        restroTimings: {
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.GET_RESTRO_TIMING_FAILURE:
      return {
        ...state,
        restroTimings: {
          ...state.restroTimings,
          loading: false,
          error: action.payload,
        },
      }
    default:
      return state
  }
}

export default masterReducers

