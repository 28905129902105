import React from 'react'
import {useSelector} from 'react-redux'
import MyDiv from './privacyAndPolicy.style'
const PrivacyAndPolicy = React.memo((props) => {
  const config = useSelector((state) => state.configState)
  const drawEnglishPrivacyAndPolicy = () => {
    return (
      <div>
        <span className="tcTitle"><strong>PRIVACY POLICY</strong></span>
        <p>Information on the Processing of Personal Data</p>
        <p>Introduction:</p>
        <p>
          We want to assure you that the protection of our customers' personal data is of the utmost importance.
          For this reason, GIA SOUVLAKI "ETSI APLA" LIMITED ("Grill house") is taking the appropriate measures to protect
          the personal data from loss or breach, alteration, transmission or any other improper processing and to ensure
          that the processing of your personal data is always carried out in accordance with the obligations set forth by
          the legal framework, both by the grill itself and by third parties who process personal data on our behalf.
        </p>
        <p>
          The grill house Gia SOUVLAKI "ETSI APLA" LIMITED, has its headquarters in Nicosia at 41B Metochiou Street,
          1101 Nicosia, email: <a href="void:0">giasouvlakietsiapla@gmail.com</a>, Tel: 22263400,
          website: <a href="https://www.giasouvlakietsiapla.com" target="_blank">www.giasouvlakietsiapla.com</a> for
          the purpose of conducting its business activities, processes personal data of its clients in accordance with the
          current national legislation and the European Regulation 2016/679 for the protection of natural persons against
          the processing of personal data and for the free circulation of this data (General Regulation on Data Protection,
          hereinafter "Regulation"), as applicable.
        </p>
        <p>
          The information that you provide us with is personally identifiable and may be used by us in accordance with the
          Personal Data Protection Act to:
        </p>
        <p className="listData">
          <span className="romanList">(i)</span> identify you and allow you to use our services (for ordering food for delivery and/or take away) or in
          response to any questions,
        </p>
        <p className="listData">
          <span className="romanList">(ii)</span>send you promotional and advertising material about us and our products and services,
        </p>
        <p className="listData">
          <span className="romanList">(iii)</span>exchange of data with our partner companies, business partners and market research
          companies to conduct market research and analysis of our services on our behalf,
        </p>
        <p className="listData">
          <span className="romanList">(iv)</span>support any other purpose that may be stated at the time of collecting your information, without affecting any preferences you may have expressed.
        </p>
        <p className="listData">
          <span className="romanList">(v)</span>prevent fraudulent transactions and theft, and
        </p>
        <p className="listData">
          <span className="romanList">(vi)</span>comply with applicable law and/or government requirements.
        </p>
        <p>
          By participating in our services you acknowledge and agree that we may use your information in these specified
          restrictive circumstances and for the purposes described.
        </p>
        <p>
          What are your rights regarding your personal data?
        </p>
        <p>
          Under local law, you have certain rights with respect to the personal information we collect, use or disclose about
          you. Every individual whose data is processed by GIA SOUVLAKI "ETSI APLA" LIMITED has certain rights, including the following:
        </p>
        <p>
          <strong>Right of access:</strong><br />
          You have the right to be aware and verify the legality of the processing.
          Thus, you have the right to access the data and receive additional information about their processing.
        </p>
        <p>
          <strong>Right of correction:</strong><br />
          You have the right to study, correct, update or modify your personal information by contacting the Data Protection Officer (DPO) of our grill house.
        </p>
        <p>
          <strong>Right of deletion:</strong><br />
          You have the right to request the deletion of your personal data when we process it by giving your consent or when
          we are protecting our legal interests. In all other cases (such as when there is a contract, obligation to process
          personal data imposed by law, public interest), this right is subject to certain restrictions or may not exist, in
          certain circumstances.
        </p>
        <p>
          <strong>Right to restrict processing:</strong><br />
          You have the right to request a restriction on the processing of your personal data in certain cases.
        </p>
        <p>
          <strong>Right to object to processing:</strong><br />
          You have the right to object, at any time, to the processing of your personal data in cases where, as described
          above, it is necessary for the purposes to legitimately pursue as processors, as well as to the processing for
          the purposes of direct marketing and consumer profiling.
        </p>
        <p>
          <strong>Right to withdraw consent:</strong><br />
          Where processing is based on your consent, you have the right to revoke it freely, without affecting the legality
          of the processing, based on your consent before revoking it.
        </p>
        <p>
          To exercise any of your above mentioned rights, you can contact us by post at the following address: 41B Metochiou,
          1101 Nicosia, or by email at: <a href="void:0">giasouvlakietsiapla@gmail.com</a>
        </p>
        <p>
          If any of the above cases should arise, we will make every effort to respond to your request within thirty (30) days of
          when you submit it. This deadline may be extended for an additional sixty (60) days, if deemed necessary, taking into
          account the complexity of the request and the number of requests, in which case we will inform you of the extension
          within the aforementioned deadline of thirty (30) days.
        </p>
        <p>
          <strong>COOKIES</strong><br />
          Cookies are small text files with a small amount of data stored in your web browser or downloaded to your device when
          you visit a website. Cookies are sent to your browser from a website and stored on your computer's hard drive. Like
          many websites, we use cookies to collect information. You can instruct your browser to reject all cookies or to
          indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some parts of our website.
        </p>
        <p>
          <strong>About cookies</strong><br />
        </p>
        <p>
          We have placed our cookies in the following categories to make it easier for you to understand why we need them:
        </p>
        <p>
          A) Strictly necessary cookies: These are required for the operation of our website. They include, for example, cookies that allow you
          to connect to secure areas of our website. Without these cookies, we cannot offer useful navigation of our website.
        </p>
        <p>
          B) Analytics / Performance cookies: These allow us to identify and count the number of users of our site and see
          how those users browse our site. This helps to improve the way our site works, for example, by ensuring that users
          can easily find what they are looking for.
        </p>
        <p>
          Please note, however, that you may not be able to use all the features of our website if cookies are disabled.
        </p>
        <p>
          <strong>Changes to this cookie policy</strong>
        </p>
        <p>
          Our policies and procedures are under constant review. Sometimes, we may update our cookie policy.
          Any such changes will be uploaded to this page.
        </p>
        <p>SECURITY</p>
        <p>
          The grill house is responsible to take the appropriate measures for the safe storage of personal information.
          While we make every effort to safeguard our information systems, no website, mobile application, computer or
          data transmission over the internet or any other public network is guaranteed to be 100% secure.
        </p>
        <p>
          <strong>Retaining personal information</strong>
        </p>
        <p>
          We retain personal information for the time required to fulfill the purposes contained in this Privacy Statement
          and in accordance with the grill house's file retention policies that have been designed to comply with the GDPR,
          specifically in the minimal use of the data and only when the extended period of retention is required or permitted
          by Law.
        </p>
        <p>
          <strong>Changes to the Privacy Policy</strong>
        </p>
        <p>
          We reserve the right to update or change the Privacy Policy at any time. You should periodically review this
          Privacy Policy for any updates that will take effect upon publication. Your continued use of the services after
          the posting of any changes to the Privacy Policy on this page will constitute your acknowledgment of the changes
          and your consent to be bound by the amended Privacy Policy.
        </p>
        <p>
          The Privacy Policy information reflects the current state of data processing on our site. In the event of changes
          to data processing, this information will be updated accordingly. There will always be the most up-to-date version
          of this data protection information on our site so that you are aware of the extent of the data processing on our
          site. We recommend that you always be aware of how we process and protect your personal information.
        </p>
        <p>
          The above information is provided in accordance with Regulation (EU) 2016/679 of the European Parliament and of
          the Council and the provisions of the Cypriot legislation for the protection of personal data that are used within
          this framework.
        </p>
      </div>
    )
  }
  const drawGreekPrivacyAndPolicy = () => {
    return (
      <div>
        <span className="tcTitle"><strong>ΠΟΛΙΤΙΚΗ ΑΠΟΡΡΗΤΟΥ</strong></span>
        <p>Ενημέρωση για την Επεξεργασία Προσωπικών Δεδομένων</p>
        <p>Εισαγωγή:</p>
        <p>
          Θέλουμε να σας διαβεβαιώσουμε ότι η προστασία των προσωπικών δεδομένων των πελατών μας έχει πρωταρχική σημασία.
          Για το λόγο αυτό το ΓΙΑ ΣΟΥΒΛΑΚΙ "ΕΤΣΙ ΑΠΛΑ" ΛΙΜΙΤΕΔ, ("Ψητοπωλείο") λαμβάνει τα κατάλληλα μέτρα για να
          προστατεύσει τα προσωπικά δεδομένα που επεξεργάζεται από απώλεια ή διαρροή, αλλοίωση, διαβίβαση ή την με
          οποιοδήποτε άλλο τρόπο αθέμιτη επεξεργασία και να διασφαλίσει ότι η επεξεργασία των προσωπικών σας δεδομένων
          πραγματοποιείται πάντοτε σύμφωνα με τις υποχρεώσεις που τίθενται από το νομικό πλαίσιο, τόσο από το ίδιο το
          ψητοπωλείο, όσο και από τρίτους που επεξεργάζονται προσωπικά δεδομένα για λογαριασμό μας.
        </p>
        <p>
          Το ψητοπωλείο ΓΙΑ ΣΟΥΒΛΑΚΙ "ΕΤΣΙ ΑΠΛΑ" ΛΙΜΙΤΕΔ, που έχει την έδρα των εργασιών του στην Λευκωσία, οδός Μετοχίου
          41Β, 1101 Λευκωσία, email: <a href="void:0">giasouvlakietsiapla@gmail.com</a>, Τηλ: 22263400,
          website: <a href="https://www.giasouvlakietsiapla.com" target="_blank">www.giasouvlakietsiapla.com</a>
          ενημερώνει ότι, για σκοπούς άσκησης των επιχειρηματικών δραστηριοτήτων του, προβαίνει σε επεξεργασία προσωπικών
          δεδομένων των πελατών του, σύμφωνα με την ισχύουσα εθνική νομοθεσία και τον Ευρωπαϊκό Κανονισμό 2016/679 για την
          προστασία των φυσικών προσώπων έναντι της επεξεργασίας των δεδομένων προσωπικού χαρακτήρα και για την ελεύθερη
          κυκλοφορία των δεδομένων αυτών (Γενικός Κανονισμός για την Προστασία Δεδομένων, εφεξής "Κανονισμός"), όπως ισχύει.
        </p>
        <p>
          Οι συγκεκριμένες πληροφορίες προσωπικής ταυτοποίησης που μπορείτε να μας παρέχετε θα μπορούσαν να χρησιμοποιηθούν
          από εμάς, όπως επιτρέπεται από και σύμφωνα με τον ισχύοντα Νόμο Περί Προστασίας Προσωπικών Δεδομένων για:
        </p>
        <p className="listData">
          <span className="romanList">(i)</span>να σας αναγνωρίσουν και να σας επιτρέψουν να χρησιμοποιήσετε τις υπηρεσίες
          μας (για τη διαχείριση και την παράδοση παραγγελίας ) στην πραγματοποίηση της παράδοσης μιας παραγγελίας ή σε απάντηση
          σε οποιοδήποτε ερώτημα.
        </p>
        <p className="listData">
          <span className="romanList">(ii)</span>να στείλουμε προωθητικές και διαφημιστικές ανακοινώσεις σε εσάς σχετικά με εμάς
          και τα προϊόντα και τις υπηρεσίες μας.
        </p>
        <p className="listData">
          <span className="romanList">(iii)</span>ανταλλαγή δεδομένων με τις συνεργαζόμενες εταιρείες, τους επιχειρηματικούς μας
          εταίρους και τις εταιρείες έρευνας αγοράς για τη διεξαγωγή έρευνας αγοράς και ανάλυσης σχετικά με τις υπηρεσίες μας για
          λογαριασμό μας.
        </p>
        <p className="listData">
          <span className="romanList">(iv)</span>να υποστηρίξουμε κάθε άλλο σκοπό που θα δηλώνεται κατά τη στιγμή της συλλογής
          των πληροφοριών σας, με την επιφύλαξη τυχόν προτιμήσεων που ενδεχομένως έχετε δηλώσει.
        </p>
        <p className="listData">
          <span className="romanList">(v)</span>την πρόληψη δόλιων συναλλαγών και κλοπής, και
        </p>
        <p className="listData">
          <span className="romanList">(vi)</span>για τη συμμόρφωση με το εφαρμοστέο δίκαιο, κυβερνητικό αίτημα.
        </p>
        <p>
          Συμμετέχοντας στις Υπηρεσίες μας αναγνωρίζετε και συμφωνείτε να χρησιμοποιούμε τις πληροφορίες σας σε αυτές
          ένες περιοριστικά περιστάσεις και για τους σκοπούς που περιγράφονται.
        </p>
        <p>
          Ποια είναι τα δικαιώματα σας σε σχέση με τα προσωπικά σας δεδομένα
        </p>
        <p>
          Κάθε φυσικό πρόσωπο τα δεδομένα του οποίου είναι αντικείμενο επεξεργασίας από το ΓΙΑ ΣΟΥΒΛΑΚΙ "ΕΤΣΙ ΑΠΛΑ" ΛΙΜΙΤΕΔ,
          έχει τα ακόλουθα δικαιώματα:
        </p>
        <p>
          Με βάση τον τοπικό Νόμο, έχετε ορισμένα δικαιώματα όσον αφορά τις προσωπικές πληροφορίες που συλλέγουμε,
          χρησιμοποιούμε ή αποκαλύπτουμε ότι σχετίζονται με εσάς, συμπεριλαμβανομένων των κάτωθι δικαιωμάτων:
        </p>
        <p>
          <strong>Right of access:</strong><br />
          You have the right to be aware and verify the legality of the processing.
          Thus, you have the right to access the data and receive additional information about their processing.
        </p>
        <p>
          <strong>Δικαίωμα πρόσβασης:</strong><br />
          Έχετε δικαίωμα να έχετε επίγνωση και να επαληθεύετε τη νομιμότητα της επεξεργασίας. Έτσι λοιπόν, έχετε δικαίωμα
          να έχετε πρόσβαση στα δεδομένα και να λάβετε συμπληρωματικές πληροφορίες σχετικά με την επεξεργασία τους.
        </p>
        <p>
          <strong>Δικαίωμα διόρθωσης:</strong><br />
          Έχετε δικαίωμα να μελετήσετε, να διορθώσετε, να επικαιροποιήσετε ή να τροποποιήσετε τα προσωπικά σας δεδομένα
          ερχόμενοι σε επαφή με τον Υπεύθυνο Προστασίας Δεδομένων (DPO) του ψητοπωλείου μας.
        </p>
        <p>
          <strong>Δικαίωμα διαγραφής:</strong><br />
          Έχετε δικαίωμα να υποβάλετε αίτημα διαγραφής των προσωπικών σας δεδομένων όταν τα επεξεργαζόμαστε με βάση την
          συγκατάθεσή σας ή με προκειμένου να προστατεύσουμε τα έννομα συμφέροντα μας. Σε όλες τις υπόλοιπες περιπτώσεις
          (όπως ενδεικτικά όταν υπάρχει σύμβαση, υποχρέωση επεξεργασίας προσωπικών δεδομένων που επιβάλλεται από το νόμο,
          δημόσιο συμφέρον), το εν λόγω δικαίωμα υπόκειται σε συγκεκριμένους περιορισμούς ή δεν υφίσταται ανάλογα με την
          περίπτωση.
        </p>
        <p>
          <strong>Δικαίωμα περιορισμού της επεξεργασίας:</strong><br />
          Έχετε δικαίωμα να ζητήσετε περιορισμό της επεξεργασίας των προσωπικών σας δεδομένων σε ορισμένες περιπτώσεις.
        </p>
        <p>
          <strong>Δικαίωμα εναντίωσης στην επεξεργασία:</strong><br />
          Έχετε δικαίωμα να εναντιωθείτε ανά πάσα στιγμή στην επεξεργασία των προσωπικών σας δεδομένων στις περιπτώσεις που,
          όπως περιγράφεται παραπάνω, αυτή είναι απαραίτητη για σκοπούς εννόμων συμφερόντων που επιδιώκουμε ως υπεύθυνοι
          επεξεργασίας, καθώς επίσης και στην επεξεργασία για σκοπούς απευθείας εμπορικής προώθησης και κατάρτισης
          καταναλωτικού προφίλ.
        </p>
        <p>
          <strong>Δικαίωμα ανάκλησης της συγκατάθεσης:</strong><br />
          Όπου η επεξεργασία βασίζεται στην συγκατάθεσή σας, έχετε δικαίωμα να την ανακαλέσετε ελεύθερα, χωρίς να θιγεί
          η νομιμότητα της επεξεργασίας που βασίστηκε στη συγκατάθεσή σας προτού την ανακαλέσετε.
        </p>
        <p>
          Για να ασκήσετε οποιοδήποτε από τα παραπάνω δικαιώματά σας μπορείτε να απευθύνεστε στην ταχυδρομική διεύθυνση:
          Μετοχίου 41Β, 1101 Λευκωσία, ή στο email: <a href="void:0">giasouvlakietsiapla@gmail.com</a>
        </p>
        <p>
          Στις ως άνω περιπτώσεις θα καταβάλλουμε κάθε δυνατή προσπάθεια να απαντήσουμε στο αίτημά σας εντός τριάντα (30)
          ημερών, από την υποβολή του. Η εν λόγω προθεσμία μπορεί να παραταθεί για εξήντα (60) επιπλέον ημέρες, εφόσον αυτό
          κριθεί αναγκαίο, λαμβάνοντας υπόψη την πολυπλοκότητα του αιτήματος και του αριθμού των αιτημάτων, οπότε θα σας
          ενημερώσουμε σχετικά εντός της προαναφερθείσας προθεσμίας των τριάντα (30) ημερών.
        </p>
        <p>
          <strong>COOKIES</strong><br />
          Τα cookies είναι μικρά αρχεία κειμένου με μικρό όγκο δεδομένων, που είναι αποθηκευμένα στο πρόγραμμα περιήγησης
          ιστού σας ή έχουν μεταφορτωθεί στην συσκευή σας όταν επισκέπτεστε ένα ιστότοπο. Τα cookies αποστέλλονται στο
          πρόγραμμα περιήγησής σας από έναν ιστότοπο και αποθηκεύονται στον σκληρό δίσκο του υπολογιστή σας.
        </p>
        <p>
          Όπως πολλοί ιστότοποι, χρησιμοποιούμε "cookies" για τη συλλογή πληροφοριών. Μπορείτε να δώσετε εντολή στο πρόγραμμα
          περιήγησής σας να απορρίψει όλα τα cookies ή να υποδείξει πότε αποστέλλεται ένα cookie. Ωστόσο, εάν δεν αποδέχεστε
          cookies, ενδέχεται να μην μπορείτε να χρησιμοποιήσετε μερικά τμήματα του ιστότοπού μας.
        </p>
        <p>
          <strong>Σχετικά με τα cookies</strong><br />
        </p>
        <p>
          Έχουμε τοποθετήσει τα cookies μας στις ακόλουθες κατηγορίες, για να σας διευκολύνουμε να καταλάβετε γιατί τα χρειαζόμαστε:
        </p>
        <p>
          A) Αυστηρώς απαραίτητα cookies: Αυτά απαιτούνται για τη λειτουργία της ιστοσελίδας μας. Περιλαμβάνουν, για
          παράδειγμα, cookies που σας επιτρέπουν να συνδεθείτε σε ασφαλείς περιοχές της ιστοσελίδας μας. Χωρίς αυτά τα
          cookies, δεν μπορούμε να προσφέρουμε αποτελεσματική λειτουργία της ιστοσελίδας μας.
        </p>
        <p>
          B) Cookies ανάλυσης / απόδοσης: Αυτά μας επιτρέπουν να αναγνωρίζουμε και να υπολογίζουμε τον αριθμό των χρηστών του
          ιστότοπού μας και να δούμε πώς οι χρήστες αυτοί περιηγούνται στον ιστότοπό μας. Αυτό βοηθά να βελτιωθεί ο τρόπος με
          τον οποίο λειτουργεί ο ιστότοπός μας, για παράδειγμα, διασφαλίζοντας ότι οι χρήστες μπορούν εύκολα να βρουν αυτό
          που αναζητούν.
        </p>
        <p>
          Λάβετε υπόψη, ωστόσο, ότι ενδέχεται να μην μπορείτε να χρησιμοποιήσετε όλες τις λειτουργίες του ιστότοπού μας αν τα cookies είναι απενεργοποιημένα.
        </p>
        <p>
          <strong>Αλλαγές σε αυτήν την πολιτική cookies</strong>
        </p>
        <p>
          Οι πολιτικές και οι διαδικασίες μας βρίσκονται υπό συνεχή επανεξέταση. Μπορούμε, κατά καιρούς, να ενημερώνουμε την πολιτική μας για τα cookies.
          Τυχόν τέτοιες αλλαγές θα αναρτηθούν σε αυτή τη σελίδα.
        </p>
        <p>ΑΣΦΑΛΕΙΑ</p>
        <p>
          Το ψητοπωλείο δεσμεύεται να λάβει τα κατάλληλα μέτρα για την ασφαλή φύλαξη των προσωπικών πληροφοριών.
          Παρόλο που καταβάλλουμε κάθε δυνατή προσπάθεια για την διαφύλαξη των συστημάτων πληροφοριών μας, δεν είναι
          κανένας ιστότοπος, εφαρμογές κινητής τηλεφωνίας, ηλεκτρονικού υπολογιστή ή μετάδοσης δεδομένων μέσω του διαδικτύου
          ή οποιουδήποτε άλλου δημόσιου δικτύου 100% εγγυημένα ασφαλής.
        </p>
        <p>
          <strong>Διατήρηση προσωπικών πληροφοριών</strong>
        </p>
        <p>
          Διατηρούμε προσωπικές πληροφορίες για το χρονικό διάστημα που είναι απαραίτητο για την εκπλήρωση των σκοπών που
          περιέχονται σε αυτή τη Δήλωση Προστασίας Προσωπικών Δεδομένων και σύμφωνα με τις πολιτικές διατήρησης αρχείων του
          ψητοπωλείου, που έχουν σχεδιαστεί για να συμμορφώνονται με το GDPR και ειδικότερα με την αρχή της ελαχιστοποίησης
          των δεδομένων, εκτός αν απαιτείται ή επιτρέπεται από τον Νόμο η παρατεταμένη περίοδος διατήρησης των.
        </p>
        <p>
          <strong>Αλλαγές στην Πολιτική Απορρήτου</strong>
        </p>
        <p>
          Διατηρούμε το δικαίωμα να ενημερώσουμε ή να αλλάξουμε την Πολιτική Απορρήτου ανά πάσα στιγμή και θα πρέπει να
          ελέγχετε περιοδικά την παρούσα Πολιτική Προστασίας Προσωπικών Δεδομένων για ενημερώσεις που θα τεθούν σε ισχύ
          μετά τη δημοσίευση τους. Η συνεχιζόμενη χρήση της Υπηρεσίας μετά την δημοσίευση οποιωνδήποτε τροποποιήσεων στην
          Πολιτική Απορρήτου σε αυτή τη σελίδα θα συνιστά την αναγνώριση σας για τις τροποποιήσεις και τη συγκατάθεση σας
          να τηρείτε και να δεσμεύσετε από την τροποποιημένη Πολιτική Απορρήτου.
        </p>
        <p>
          Οι πληροφορίες σχετικά με την πολιτική απορρήτου αντικατοπτρίζουν την τρέχουσα κατάσταση της επεξεργασίας
          δεδομένων στον ιστότοπό μας. Σε περίπτωση αλλαγών στην επεξεργασία δεδομένων, αυτές οι πληροφορίες για την προστασία
          δεδομένων θα ενημερωθούν αντίστοιχα. Στον ιστότοπό μας θα υπάρχει πάντα η πιο πρόσφατη έκδοση αυτών των πληροφοριών
          για την προστασία δεδομένων, έτσι ώστε να ενημερώνεστε για το εύρος της επεξεργασίας δεδομένων στον ιστότοπό μας.
          Σας συνιστούμε να είστε πάντα ενήμεροι  για τον τρόπο με τον οποίο επεξεργαζόμαστε και προστατεύουμε τις προσωπικές
          πληροφορίες σας.
        </p>
        <p>
          Η πιο πάνω ενημέρωση σας γίνεται κατ’ εφαρμογή του Κανονισμού (ΕΕ) 2016/679 του Ευρωπαϊκού Κοινοβουλίου και του
          Συμβουλίου και των διατάξεων της κυπριακής νομοθεσίας περί προστασίας δεδομένων προσωπικού χαρακτήρα που
          υιοθετούνται και εφαρμόζονται στο πλαίσιο αυτού.
        </p>
      </div>
    )
  }
  return (<MyDiv>
    { config.language.code === 'EL' ? drawGreekPrivacyAndPolicy() : drawEnglishPrivacyAndPolicy() }
  </MyDiv>)
})

export default PrivacyAndPolicy
