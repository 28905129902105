export const LANDING = '/'
export const USER_ACCOUNT = '/account-details'
export const SIGNIN = '/sign-in'
export const SIGNUP = '/sign-up'
export const PROFILE = '/profile'
export const PAYMENTGATEWAY = '/payment-gateway'
export const PAYMENTRESPONSE = '/payment-response'
export const ABOUT_US = '/about-us'
export const TERMS_AND_CONDITIONS = '/terms-of-use'
export const PRIVACY_POLICY = '/privacy-policy'
export const CONTACT_US = '/contact-us'
