import styled from 'styled-components'

const MyDiv = styled.div `
.orderTitle{
  //font-family: Proxima Nova;
  font-size: 22px;
  font-weight: 700;
  color: #000000;
  padding: 13px 66px 12px 66px;
  border-radius: 10px 10px 0 0;
  background-color: #F1F1F1;
  @media(max-width:767px){
    padding: 15px 25px;
  }
  @media(min-width:768px) and (max-width:1023px){
    padding: 15px 25px;
  }
}
.orderMobile .MuiGrid-spacing-xs-2 > .MuiGrid-item{
  @media(max-width:767px){
    display: inline-table;
  }
  @media(min-width:768px) and (max-width:1023px){
    display: inline-table;
  }
}
.spinner {
  position: absolute!important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #bdbdbd7a;
  z-index: 1000;
  border-radius: 20px;
}
.ordersList{
  //font-family: Arial;
  font-size: 14px;
  font-weight: 400;
  color: #8A8A8F;
  padding-left: 66px;
  @media(max-width:767px){
    padding-left: 15px;
  }
  @media(min-width:768px) and (max-width:1023px){
    padding:0px 25px;
  }
}
.orderRow{
  margin: 43px 0px 14px 0px;
}
`
export default MyDiv
