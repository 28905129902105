import React from 'react'
import Proptypes from 'prop-types'
import _ from 'lodash'
import CategorySectionStyled from './categorysection.styles'

const CategorySection = (props) => {
  return (
    <CategorySectionStyled>
      {_.map(props.categories, (models, index) => {
        return (
          <div className="cat_div" key={index} onClick={() => props.OnSelection(models.id)}>
            <img src={models.fullUrl} alt={'Category'} />
            <br />
            <div className={props.selected === models.id ? 'selected_category' : ''}>{models.name}</div>
          </div>
        )
      })}
    </CategorySectionStyled>
  )
}

CategorySection.propTypes = {
  selected: Proptypes.number,
  theme: Proptypes.object,
  OnSelection: Proptypes.func,
  categories: Proptypes.array,
}

export default CategorySection
