import React, {} from 'react'
import {useSelector} from 'react-redux'
import Grid from '@material-ui/core/Grid'
import {getLabelsBasedOnLanguage} from '../../constants/common'
import bannerImage1 from '../../images/IMG_1151.jpg'
import bannerImage2 from '../../images/IMG_1158.jpg'
import bannerImage3 from '../../images/IMG_1162.jpg'
import MyDiv from './aboutUs.style'

const AboutUs = React.memo((props) => {
  const config = useSelector((state) => state.configState)
  return (<MyDiv>
    <Grid container>
      <Grid md={2} lg={3} />
      <Grid item xs={12} sm={12} md={8} lg={6}>
        <span className="aboutUsTitle">{getLabelsBasedOnLanguage('aboutus', config.language.code)}</span>
      </Grid>
    </Grid>
    <Grid container>
      <Grid item md={2} lg={3} />
      <Grid item xs={12} sm={12} md={8} lg={6}>
        <div className="paragraph">
          <img alt="P1" src={bannerImage1} className="pImage p1" />
          <span>{getLabelsBasedOnLanguage('aboutUsPage.p1', config.language.code)}</span>
        </div>
        <div className="paragraph">
          <img alt="P2" src={bannerImage2} className="pImage p2" />
          <span>{getLabelsBasedOnLanguage('aboutUsPage.p2', config.language.code)}</span>
        </div>
        <div className="paragraph">
          <img alt="P3" src={bannerImage3} className="pImage p3" />
          <span>{getLabelsBasedOnLanguage('aboutUsPage.p3', config.language.code)}</span>
        </div>
      </Grid>
    </Grid>
  </MyDiv>)
})

export default AboutUs
