import styled from 'styled-components'

const StyledHeaderComponent = styled.div `
// flex-grow: 1;
box-shadow: 0px 3px 20px #00000016;

.header-item {
    width: auto;
}
.grow {
    flex-grow: 1;
}
.navigator {
    display: inline-flex;
    float: right;
}
.restroClosingMessage{
    @media(min-width:959px){
        line-height: 3.5rem;
    }
    color: red;
}
.loginButton {
    margin-right: theme.spacing(5),
    @media(max-width:767px){
        margin-right:0;
    }
}
.menuitem {
    display: inline-flex;
    padding: 0;
}
.headerlast { 
    display: inline-block;
    @media(min-width:959px){
        line-height: 3.5rem;
    }
}
.restroPhone{
    font-weight: 600;
    margin-top: 2px;
    color: red;
    margin-right: 8px;
    @media(max-width:959px){
        margin-left: 10px;
    }
}
.logoImg{
    @media(max-width:767px){
        height: 40px;
        width: 108px;
    }
    @media(min-width:767px) and (max-width:959px){
        height: 60px;
        width: 130px;
        padding: 0;
    }
    height: 60px;
    width: 178px;
    padding: 6px 16px;
}
.mobileViewHeaderRow{
    text-align: right;
    padding: 5px 24px 0px 0px;
}
`
export default StyledHeaderComponent
