import * as actionTypes from '../actions/actionsType'


const INITIAL_STATE = {
  signin: {
    data: null,
    loading: false,
    error: null,
  },
  signup: {
    data: null,
    loading: false,
    error: null,
  },
  verify_otp_request: {
    data: null,
    loading: false,
    error: null,
  },
  forgotPassword: {
    data: null,
    loading: false,
    error: null,
  },
  changePassword: {
    data: null,
    loading: false,
    error: null,
  },
  validation: {
    type: 0,
  },
  session: {
    data: null,
    loading: false,
    error: null,
  },
  changePhoneNumber: {
    data: null,
    loading: false,
    error: null,
  },
  updateprofileimage: {
    data: null,
    loading: false,
    error: null,
  },
}

const authReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.USER_SIGIN_REQUEST:
      return {
        ...state,
        signin: {
          ...state.signin,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.USER_SIGIN_SUCCESS:
      return {
        ...state,
        signin: {
          ...state.signin,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.USER_SIGIN_FAILURE:
      return {
        ...state,
        signin: {
          ...state.signin,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.USER_SIGNUP_REQUEST:
      return {
        ...state,
        signup: {
          ...state.signup,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.USER_SIGNUP_SUCCESS:
      return {
        ...state,
        signup: {
          ...state.signup,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.USER_SIGNUP_FAILURE:
      return {
        ...state,
        signup: {
          ...state.signup,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.USER_AUTH_VERIFICATUON_TYPE:
      return {
        ...state,
        validation: {
          type: action.payload,
        },
      }
    case actionTypes.VERIFY_OTP_REQUEST:
      return {
        ...state,
        verify_otp_request: {
          ...state.verify_otp_request,
          data: null,
          loading: true,
          error: null,
        },
        signup: {
          ...state.signup,
          data: null,
        },
        forgotPassword: {
          ...state.forgotPassword,
          data: null,
        },
      }
    case actionTypes.VERIFY_OTP_FAILURE:
      return {
        ...state,
        verify_otp_request: {
          ...state.verify_otp_request,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.VERIFY_OTP_SUCCESS:
      return {
        ...state,
        verify_otp_request: {
          ...state.verify_otp_request,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        forgotPassword: {
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPassword: {
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        changePassword: {
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePassword: {
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.CLEAN_UP_STATE_REQUEST:
      return {
        ...state,
        validation: {
          ...state.validation,
        },
        signin: {
          ...state.signin,
          error: null,
          data: null,
        },
        signup: {
          ...state.signup,
          error: null,
          data: null,
        },
        verify_otp_request: {
          ...state.verify_otp_request,
          error: null,
          data: null,
        },
        forgotPassword: {
          ...state.forgotPassword,
          error: null,
          data: null,
        },
        changePassword: {
          ...state.changePassword,
          error: null,
          data: null,
        },
        changePhoneNumber: {
          ...state.changePhoneNumber,
          data: null,
          error: null,
        },
        updateprofileimage: {
          ...state.updateprofileimage,
          data: null,
          error: null,
        },
      }
    case actionTypes.USER_SIGNOUT_REQUEST:
      return {
        ...state,
        signin: {
          ...state.signin,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.USER_SIGNOUT_SUCCESS:
      return {
        ...state,
        signin: {
          ...state.signin,
          data: null,
          loading: false,
          error: null,
        },
        session: {
          ...state.session,
          data: null,
          loading: false,
          error: null,
        },
      }
    case actionTypes.USER_SIGNOUT_FAILURE:
      return {
        ...state,
        signin: {
          ...state.signin,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.USER_SESSION_REQUEST:
      return {
        ...state,
        session: {
          ...state.session,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.USER_SESSION_SUCCESS:
      return {
        ...state,
        session: {
          ...state.session,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.USER_SESSION_FAILURE:
      return {
        ...state,
        session: {
          ...state.session,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.USER_LANGUAGE_UPDATE_REQUEST:
      return {
        ...state,
        session: {
          ...state.session,
          loading: true,
          error: null,
        },
      }
    case actionTypes.USER_LANGUAGE_UPDATE_SUCCESS:
      return {
        ...state,
        session: {
          ...state.session,
          data: {
            ...state.session.data,
            language_id: action.payload,
          },
          loading: false,
          error: null,
        },
      }
    case actionTypes.USER_LANGUAGE_UPDATE_FAILURE:
      return {
        ...state,
        session: {
          ...state.session,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.CHANGE_PHONE_NUMBER_REQUEST:
      return {
        ...state,
        changePhoneNumber: {
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.CHANGE_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        changePhoneNumber: {
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.CHANGE_PHONE_NUMBER_FAILURE:
      return {
        ...state,
        changePhoneNumber: {
          ...state.changePhoneNumber,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.UPDATE_PROFILE_IMAGE_REQUEST:
      return {
        ...state,
        updateprofileimage: {
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.UPDATE_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        updateprofileimage: {
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.UPDATE_PROFILE_IMAGE_FAILURE:
      return {
        ...state,
        updateprofileimage: {
          ...state.updateprofileimage,
          loading: false,
          error: action.payload,
        },
      }
    default:
      return state
  }
}

export default authReducers
