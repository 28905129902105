import styled from 'styled-components'

const MyDiv = styled.div`
text-align: left;
.tcTitle{
  font-size: 20px;
  @media(max-width:959px){
    padding: 0 20px;
  }
}
p{
  margin-top: 10px;
}
`

export default MyDiv
