import * as authServices from '../../services/authServices'
import * as productServices from '../../services/productServices'
import {setToken, getToken, removeToken} from '../../utilities/authUtils'
import * as userActions from './userActions'
import * as actionTypes from './actionsType'

const signInRequest = () => {
  return {
    type: actionTypes.USER_SIGIN_REQUEST,
  }
}

const signInSuccess = (welcomePage) => {
  return {
    type: actionTypes.USER_SIGIN_SUCCESS,
    payload: welcomePage,
  }
}

const signInFailure = (error) => {
  return {
    type: actionTypes.USER_SIGIN_FAILURE,
    payload: error,
  }
}

const checkSessionRequest = () => {
  return {
    type: actionTypes.USER_SESSION_REQUEST,
  }
}

const checkSessionSuccess = (welcomePage) => {
  return {
    type: actionTypes.USER_SESSION_SUCCESS,
    payload: welcomePage,
  }
}

const checkSessionFailure = (error) => {
  return {
    type: actionTypes.USER_SESSION_FAILURE,
    payload: error,
  }
}
export const checkSession = (lang) =>
  async(dispatch) => {
    if (getToken('token')) {
      dispatch(checkSessionRequest())
      try {
        const responseData = await authServices.checkSession(lang)
        if (responseData) {
          // need to put conditions for refresh token
        }
        if (responseData?.status?.toUpperCase() === 'SUCCESS') {
          dispatch(checkSessionSuccess(responseData.user))
          dispatch(userActions.getUserAddressAction(lang))
        } else {
          dispatch(checkSessionFailure(responseData.errors))
        }
      } catch (error) {
        dispatch(checkSessionFailure(error.message))
      }
    }
  }

const setVerificatioType = (type) => {
  return {
    type: actionTypes.USER_AUTH_VERIFICATUON_TYPE,
    payload: type,
  }
}

const signUpSuccess = (data) => {
  return {
    type: actionTypes.USER_SIGNUP_SUCCESS,
    payload: data,
  }
}

export const signInAction = (phone, password, lang) =>
  async(dispatch) => {
    dispatch(signInRequest())
    try {
      const responseData = await authServices.signin(phone, password, lang)
      if (responseData?.status?.toUpperCase() === 'SUCCESS' && responseData?.user?.token) {
        setToken('token', responseData?.user?.token)
        if (!getToken('cart') && responseData.cart_id) {
          setToken('cart', responseData.cart_id)
        } else {
          await productServices.assignCartToUser(lang)
        }
        dispatch(signInSuccess(responseData))
        dispatch(checkSession(lang))
      } else {
        if (responseData.errors && responseData.errors.otp_id) {
          dispatch(setVerificatioType(1))
          setToken('otp_id', responseData.errors.otp_id)
          dispatch(signUpSuccess({
            message: responseData.message,
            otp_id: responseData.errors.otp_id,
            status: responseData.status,
          }))
        }
        dispatch(signInFailure(responseData))
      }
    } catch (error) {
      dispatch(signInFailure(error))
    }
  }

const signUpRequest = () => {
  return {
    type: actionTypes.USER_SIGNUP_REQUEST,
  }
}

const signUpFailure = (error) => {
  return {
    type: actionTypes.USER_SIGNUP_FAILURE,
    payload: error,
  }
}
export const signUpAction = (userData, lang) =>
  async(dispatch) => {
    dispatch(signUpRequest())
    dispatch(setVerificatioType(1))
    try {
      const responseData = await authServices.signup(userData, lang)
      if (responseData?.status?.toUpperCase() === 'SUCCESS' && responseData?.otp_id) {
        if (responseData && responseData.otp_id) {
          setToken('otp_id', responseData.otp_id)
        }
        dispatch(signUpSuccess(responseData))
      } else {
        dispatch(signUpFailure(responseData))
      }
    } catch (error) {
      dispatch(signUpFailure(error))
    }
  }


const verifyOTPRequest = () => {
  return {
    type: actionTypes.VERIFY_OTP_REQUEST,
  }
}

const verifyOTPSuccess = (data) => {
  return {
    type: actionTypes.VERIFY_OTP_SUCCESS,
    payload: data,
  }
}

const verifyOTPFailure = (error) => {
  return {
    type: actionTypes.VERIFY_OTP_FAILURE,
    payload: error,
  }
}

export const verifyOtpAction = (otp, type, lang) =>
  async(dispatch) => {

    dispatch(verifyOTPRequest())
    try {
      const otpId = getToken('otp_id')
      const responseData = await authServices.otpVerification(otp, otpId, type)
      if (responseData?.status?.toUpperCase() === 'SUCCESS') {
        if (responseData && type === 1) {
          removeToken('otp_id')
          setToken('token', responseData.token)
          dispatch(checkSession(lang))
        } else if (responseData && type === 0) {
          setToken('otp', otp)
          setToken('token', responseData.token)
        }
        dispatch(verifyOTPSuccess(responseData.message))
      } else {
        dispatch(verifyOTPFailure(responseData))
      }
    } catch (error) {
      dispatch(verifyOTPFailure(error))
    }
  }


export const signOut = (email, password) =>
  async(dispatch) => {
    dispatch(signInRequest())
    try {
      dispatch(signInSuccess(null))
      removeToken('token')
      removeToken('role')
    } catch (error) {
      dispatch(signInFailure(error))
    }
  }

const forgotPasswordRequest = () => {
  return {
    type: actionTypes.FORGOT_PASSWORD_REQUEST,
  }
}

const forgotPasswordSuccess = (welcomePage) => {
  return {
    type: actionTypes.FORGOT_PASSWORD_SUCCESS,
    payload: welcomePage,
  }
}

const forgotPasswordFailure = (error) => {
  return {
    type: actionTypes.FORGOT_PASSWORD_FAILURE,
    payload: error,
  }
}

export const forgotPassword = (phone, lang) =>
  async(dispatch) => {
    dispatch(forgotPasswordRequest())
    dispatch(setVerificatioType(0))
    try {
      const responseData = await authServices.forgotPassword(phone, lang)
      if (responseData?.status?.toUpperCase() === 'SUCCESS') {
        if (responseData?.otp_id) {
          setToken('otp_id', responseData.otp_id)
        }
        dispatch(forgotPasswordSuccess(responseData.message))
      } else {
        dispatch(forgotPasswordFailure(responseData))
      }
    } catch (error) {
      dispatch(forgotPasswordFailure(error))
    }
  }


const changePasswordRequest = () => {
  return {
    type: actionTypes.CHANGE_PASSWORD_REQUEST,
  }
}

const changePasswordSuccess = (welcomePage) => {
  return {
    type: actionTypes.CHANGE_PASSWORD_SUCCESS,
    payload: welcomePage,
  }
}

const changePasswordFailure = (error) => {
  return {
    type: actionTypes.CHANGE_PASSWORD_FAILURE,
    payload: error,
  }
}

export const changePassword = (data, type, lang) =>
  async(dispatch) => {
    dispatch(changePasswordRequest())
    try {
      const otpId = getToken('otp_id')
      const otp = getToken('otp')
      let responseData = await authServices.changePassword(data, otpId, otp, type, lang)
      if (responseData?.status?.toUpperCase() === 'SUCCESS') {
        dispatch(changePasswordSuccess(responseData.message))
        removeToken('otp_id')
        removeToken('otp')
      } else {
        dispatch(changePasswordFailure(responseData))
      }
    } catch (error) {
      dispatch(changePasswordFailure(error))
    }
  }

const getProfileRequest = () => {
  return {
    type: actionTypes.GET_PROFILE_REQUEST,
  }
}

const getProfileSuccess = (welcomePage) => {
  return {
    type: actionTypes.GET_PROFILE_SUCCESS,
    payload: welcomePage,
  }
}

const getProfileFailure = (error) => {
  return {
    type: actionTypes.GET_PROFILE_FAILURE,
    payload: error,
  }
}

export const getProfileData = (data) =>
  async(dispatch) => {
    dispatch(getProfileRequest())
    try {
      //   let responseData = await authServices.getProfileData(data)
      let responseData = null
      if (responseData?.status?.toUpperCase() === 'SUCCESS') {
        dispatch(getProfileSuccess(responseData))
      } else {
        dispatch(getProfileFailure(responseData))
      }
    } catch (error) {
      dispatch(getProfileFailure(error))
    }
  }

const cleanUpStateRequest = () => {
  return {
    type: actionTypes.CLEAN_UP_STATE_REQUEST,
  }
}


export const cleanUpState = () =>
  async(dispatch) => {
    dispatch(cleanUpStateRequest())

  }

const logoutRequest = () => {
  return {
    type: actionTypes.USER_SIGNOUT_REQUEST,
  }
}

const logoutSuccess = () => {
  return {
    type: actionTypes.USER_SIGNOUT_SUCCESS,
  }
}

const logoutFailure = (error) => {
  return {
    type: actionTypes.USER_SIGNOUT_FAILURE,
    payload: error,
  }
}


export const logout = () =>
  async(dispatch) => {
    dispatch(logoutRequest())
    try {
      removeToken('token')
      dispatch(logoutSuccess())
      dispatch(userActions.getAddressSuccess(null))
    } catch (error) {
      dispatch(logoutFailure(error))
    }

  }
const changePhoneNumberRequest = () => {
  return {
    type: actionTypes.CHANGE_PHONE_NUMBER_REQUEST,
  }
}

const changePhoneNumberSuccess = (welcomePage) => {
  return {
    type: actionTypes.CHANGE_PHONE_NUMBER_SUCCESS,
    payload: welcomePage,
  }
}

const changePhoneNumberFailure = (error) => {
  return {
    type: actionTypes.CHANGE_PHONE_NUMBER_FAILURE,
    payload: error,
  }
}

export const changePhoneNumber = (data, lang) =>
  async(dispatch) => {
    dispatch(changePhoneNumberRequest())
    dispatch(setVerificatioType(1))
    try {
      let responseData = await authServices.changePhoneNumber(data, lang)
      if (responseData?.status?.toUpperCase() === 'SUCCESS') {
        dispatch(changePhoneNumberSuccess(responseData.message))
        setToken('otp_id', responseData.otp_id)
        dispatch(checkSession(lang))
      } else {
        dispatch(changePhoneNumberFailure(responseData))
      }
    } catch (error) {
      dispatch(changePhoneNumberFailure(error))
    }
  }
const updateProfileImageRequest = () => {
  return {
    type: actionTypes.UPDATE_PROFILE_IMAGE_REQUEST,
  }
}

const updateProfileImageSuccess = (welcomePage) => {
  return {
    type: actionTypes.UPDATE_PROFILE_IMAGE_SUCCESS,
    payload: welcomePage,
  }
}

const updateProfileImageFailure = (error) => {
  return {
    type: actionTypes.UPDATE_PROFILE_IMAGE_FAILURE,
    payload: error,
  }
}

export const updateProfileImageAction = (data, lang) =>
  async(dispatch) => {
    dispatch(updateProfileImageRequest())
    try {
      let responseData = await authServices.updateProfileImage(data, lang)
      if (responseData?.status?.toUpperCase() === 'SUCCESS') {
        dispatch(updateProfileImageSuccess(responseData))
        dispatch(checkSession(lang))
      } else {
        dispatch(updateProfileImageFailure(responseData))
      }
    } catch (error) {
      dispatch(updateProfileImageFailure(error))
    }
  }
