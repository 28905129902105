import React from 'react'
import {useSelector} from 'react-redux'
import MyDiv from './termsAndConditions.style'
const TermsAndConditions = React.memo((props) => {
  const config = useSelector((state) => state.configState)
  const drawEnglishTermsAndConditions = () => {
    return (
      <div>
        <span className="tcTitle"><strong>TERMS OF USE</strong></span>
        <p>PLEASE read the terms of use of this website carefully.</p>
        <p>
          The website <a href="https://www.giasouvlakietsiapla.com" target="_blank">www.giasouvlakietsiapla.com</a> is operated by the grill house known under the brand name GIA SOUVLAKI
          “ETSI APLA” LIMITED, EU 42054a, located at 41B Metochiou Street, 1101 Nicosia, with VAT number 90006629F.
          These Terms and Conditions govern the relationship between us regarding your access to the website.
          By accessing or using the website, you agree to accept these terms which may be amended periodically,
          so please check for update from time to time.
        </p>
        <p>
          The purpose of the website is to provide a simple, convenient and cost-effective service to consumers,
          connecting them to the grill house GIA SOUVLAKI "ETSI APLA" LIMITED, ("Grill house") which offers delivery
          and takeaway services from the grill house. Interactive menus allow customers to create and place orders at the touch
          of a button. Each order is prepared by the grill house, which is responsible for delivering your order to you.
          Rest assured that the steakhouse will do everything to deliver your order within the timeframe specified.
          Please note that entering incorrect personal information may cause problems or delays in delivery,
          so make sure you include your correct address, email address, telephone number and/or other requested items.
        </p>
        <p>
          In the case where we run out of a product or inability of the grill house to complete the order,
          resulting in the failure to fulfill the order, the grill house will contact you coordinate,
          change or correct your order.
        </p>
        <p>
          The services are addressed and intended for use by anyone over 18 years and older.
          People under the age of 18 may not make purchases or other legal acts on this site without
          the agreement/consent of a parent or legal guardian, unless permitted by applicable law.
          By placing an order, you guarantee that you are over eighteen (18) years old or that you have the aforementioned
          agreement/consent.
        </p>
        <p>
          You may not print, copy, reproduce, republish, upload, transmit, show to others, modify or use any material
          from this website without our written consent. We are the sole and exclusive owners of the intellectual copyright
          of the website and its content.
        </p>
        <p>
          The price of each product of our Grill house will be listed on our website.
          Prices include VAT and are in Euros. Payment can either be made by cash or credit card.
        </p>
        <p>
          Note: Payment for delivery via the website and mobile application will be made by credit card.
          For take away, payment can be made either by cash or credit card at the store.
        </p>
        <p>
          <strong>a) With cash</strong><br />
          When your order is given to you, either at the grill house or at the place you have chosen for it to be delivered to you,
          you can pay the employee/distributor in cash.
        </p>
        <p>
          <strong>b) By credit card</strong><br />
          Credit card payment is completely secure and the transaction is conducted immediately by applying a credit balance to
          the purchaser’s card. Those who choose to pay by credit card enter the details of their credit card and,
          when the payment is approved, the grill house is notified about the order. Please note that the website and mobile
          application do not store card details.
        </p>
        <p>
          <strong>Completion of the Order</strong><br />
          In every case when order is completed properly and your card payment has been approved, the Bank from which your card
          was issued will send you a text message (sms) with the charged amount and the grill house will send you a text
          message (sms) that your order has been accepted and will inform you about the time of delivery of your order.
        </p>
        <p>
          If there is a mistake made during payment, the transaction will been recorded by us and your money will not be lost,
          even in cases where we get approval for the transaction on your card and the funds have been frozen. This is an
          automated process which is supervised, so there is no chance for you to lose your money.
        </p>
        <p>
          In case your order is not completed and you have paid by credit card, your money will be refunded from the grill
          house with a new bank transaction or by an employee of the grill house cancelling the digital transaction.
          In these cases, it is necessary for the user to communicate all relevant information to the grill house and that
          the user confirmation, together with the receipt sent by your bank, shows the reference code or approval of your order.
        </p>
        <p>
          We have taken every measure to prepare our website. However, we will not be liable for any errors or omissions in
          connection with its content or for any technical issues you may encounter on our website. If we are informed of any
          inaccuracies on our website, we will make sure to correct them as soon as possible. To the extent permitted by law,
          we do not accept any liability (whether due to negligence or otherwise) for any loss or damage you may suffer or by
          any third party in relation to our website and any website linked to our website in relation to its material.
          This does not affect our liability which, by law, cannot be excluded or limited.
        </p>
        <p>
          We will not be liable if, for any reason, our website is not available at any time or for any period. From time to
          time, we may restrict access to certain parts of our site or the entire site to registered users.
        </p>
        <p>
          We are not responsible for any delays or non-fulfillment of our obligations under this agreement which may arise from
          any cause beyond our control, including any unforeseeable circumstances.
        </p>
        <p>
          To the extent permitted by law, we may modify these terms at any time by posting updated or amended terms and
          conditions on the website. Check frequently for updates or modifications to these terms. Unless otherwise stated
          by us, any modifications will be effective from their date of posting. Use of the website after any modification
          or change in the terms will be deemed as your acceptance of the modified terms, to which you agree to be bound.
        </p>
        <p>
          Upon request, GIA SOUVLAKI "ETSI APLA" LIMITED can provide you with a translation of the Greek version of the terms
          into English. You understand and agree that any translation of the terms into English is for your convenience only
          and that the Greek version will govern the terms of your relationship with the brand name GIA SOUVLAKI "ETSI APLA"
          LIMITED. Moreover, if there are discrepancies between the Greek version and its translated version,
          the Greek version of the terms prevails.
        </p>
        <p>
          If any part or provision of this agreement is deemed to be illegal or unenforceable, it does not affect any other
          parts of the agreement. The remaining provisions and conditions shall not be affected.
        </p>
        <p>
          The Terms of Use, the Privacy Policy and any matters related to this website are governed by and interpreted in
          accordance with laws of the Republic of Cyprus. Any dispute will be resolved or adjudicated exclusively in the Nicosia
          judicial system.
        </p>
      </div>
    )
  }
  const drawGreekTermsAndConditions = () => {
    return (
      <div>
        <span className="tcTitle"><strong>ΟΡΟΙ ΧΡΗΣΗΣ</strong></span>
        <p>ΠΑΡΑΚΑΛΟΥΜΕ διαβάστε προσεκτικά τους όρους χρήσης της παρούσας ιστοσελίδας.</p>
        <p>
          Η ιστοσελίδα <a href="https://www.giasouvlakietsiapla.com" target="_blank">www.giasouvlakietsiapla.com</a>,
          λειτουργεί από το ψητοπωλείο γνωστό με την εμπορική επωνυμία ΓΙΑ ΣΟΥΒΛΑΚΙ "ΕΤΣΙ ΑΠΛΑ" ΛΙΜΙΤΕΔ, ΕΕ 42054a,
          με έδρα την οδό Μετοχίου 41Β, 1101 Λευκωσία και Αρ. Φ.Π.Α. 90006629F. Οι παρόντες Όροι και Προϋποθέσεις διέπουν
          τη σχέση μεταξύ μας όσον αφορά την πρόσβαση σας και τη συμμετοχή σας στην ιστοσελίδα. Με την πρόσβαση ή την χρήση
          της ιστοσελίδας, συμφωνείτε ότι αποδέχεστε τους παρόντες όρους οι οποίοι ενδέχεται να τροποποιούνται περιοδικά και
          παρακαλείστε να τους ελέγχετε κατά διαστήματα.
        </p>
        <p>
          Σκοπός της ιστοσελίδας είναι να παρέχει μια απλή, βολική και οικονομικά αποδοτική υπηρεσία στους καταναλωτές,
          συνδέοντας τους με το ψητοπωλείο ΓΙΑ ΣΟΥΒΛΑΚΙ "ΕΤΣΙ ΑΠΛΑ" ΛΙΜΙΤΕΔ, ("Ψητοπωλείο") που προσφέρει υπηρεσίες
          διανομής (delivery) και παραλαβής από το ψητοπωλείο (take-away). Τα διαδραστικά μενού επιτρέπουν στους πελάτες
          να δημιουργούν και να υποβάλλουν παραγγελίες με το πάτημα ενός κουμπιού. Κάθε παραγγελία παρασκευάζεται από το
          ψητοπωλείο το οποίο  είναι υπεύθυνο για την παράδοση της παραγγελίας σας σε εσάς. Να είστε βέβαιοι ότι το
          ψητοπωλείο θα κάνει τα πάντα για να ανταποκριθεί στον συγκεκριμένο χρόνο παράδοσης της παραγγελίας σας.
          Σημειώστε ότι αν δώσετε εσφαλμένα προσωπικά στοιχεία ενδέχεται να προκαλέσουν προβλήματα ή καθυστερήσεις
          στην παράδοση, οπότε βεβαιωθείτε ότι έχετε συμπεριλάβει την σωστή διεύθυνση σας, την διεύθυνση ηλεκτρονικού
          ταχυδρομείου σας, τον αριθμό του τηλεφώνου σας και/ή άλλου στοιχείου σας ζητηθεί.
        </p>
        <p>
          Σε περίπτωση έλλειψης προϊόντος ή αδυναμίας του ψητοπωλείου να εξυπηρετήσει την παραγγελία και συνεπώς στην
          αδυναμία ολοκλήρωσης της παραγγελίας, το ψητοπωλείο θα επικοινωνήσει μαζί σας για την διευθέτηση, αλλαγή ή διόρθωση
          της παραγγελίας σας.
        </p>
        <p>
          Οι υπηρεσίες απευθύνονται και προορίζονται για χρήση από οποιονδήποτε άνω των 18 ετών. Τα άτομα ηλικίας κάτω
          των 18 ετών δεν επιτρέπεται να πραγματοποιούν αγορές ή άλλες νομικές πράξεις σε αυτόν τον ιστότοπο χωρίς τη
          συναίνεση/συγκατάθεση ενός γονέα ή νόμιμου κηδεμόνα, εκτός εάν αυτό επιτρέπεται από την ισχύουσα νομοθεσία.
          Με την πραγματοποίηση παραγγελίας εγγυάστε ότι είστε άνω των δεκαοκτώ (18) ετών ή ότι έχετε την προαναφερθείσα
          συναίνεση/συγκατάθεση.
        </p>
        <p>
          Δεν δύνασθε να εκτυπώσετε, να αντιγράψετε, να αναπαράγετε, να αναδημοσιεύσετε, να μεταφορτώσετε, να μεταδώσετε,
          να προβάλλετε να τροποποιήσετε ή να χρησιμοποιήσετε οποιοδήποτε υλικό από αυτή την ιστοσελίδα, χωρίς την γραπτή
          συγκατάθεση μας. Είμαστε οι μοναδικοί και αποκλειστικοί κάτοχοι πνευματικών δικαιωμάτων της Ιστοσελίδας και του
          περιεχομένου της.
        </p>
        <p>
          Η τιμή κάθε προϊόντος του Ψητοπωλείου μας θα αναγράφεται στην ιστοσελίδα μας.
          Οι τιμές συμπεριλαμβάνουν Φ.Π.Α. και είναι σε Ευρώ. Η πληρωμή μπορεί να γίνει είτε με μετρητά, είτε με πιστωτική κάρτα.
        </p>
        <p>
          Σημείωση: Η πληρωμή για delivery μέσω της ιστοσελίδας και της εφαρμογής για κινητά θα γίνεται με πιστωτική κάρτα.
          Για take away, η πληρωμή μπορεί να γίνει είτε με μετρητά είτε με πιστωτική κάρτα στο κατάστημα.
        </p>
        <p>
          <strong>α) Με μετρητά</strong><br />
          Κατά την παράδοση της παραγγελίας σας είτε στο ψητοπωλείο, είτε στον χώρο που έχετε διαλέξει να σας παραδοθούν,
          μπορείτε να πληρώσετε με μετρητά στον υπάλληλο – διανομέα.
        </p>
        <p>
          <strong>β) Με πιστωτική κάρτα</strong><br />
          Η πληρωμή με πιστωτική κάρτα είναι απόλυτα ασφαλής, ενώ ταυτόχρονα η προσθήκη του ποσού στο πιστωτικό
          υπόλοιπο του χρήστη γίνεται άμεσα. Ο χρήστης που επιλέγει την πληρωμή με πιστωτική κάρτα, εισάγει τα στοιχεία
          της πιστωτικής του κάρτας και σε περίπτωση που αυτή πάρει έγκριση, ολοκληρώνεται η συναλλαγή του και ενημερώνεται
          το ψητοπωλείο για την παραγγελία του. Σημειώνεται ότι η ιστοσελίδα και η εφαρμογή για τις κινητές συσκευές δεν
          αποθηκεύουν τα στοιχεία της κάρτας.
        </p>
        <p>
          <strong>Ολοκλήρωση Παραγγελίας</strong><br />
          Σε κάθε περίπτωση αν η παραγγελία σας είναι σωστή και έχει εγκριθεί η πληρωμή από την κάρτα σας, η Τράπεζα από την
          οποία έχει εκδοθεί η κάρτα σας, θα αποστείλει γραπτό μήνυμα (sms) με το ποσό της χρέωσης και το ψητοπωλείο θα σας
          αποστείλει γραπτό μήνυμα (sms) ότι έχει γίνει αποδεκτή η παραγγελία σας και θα σας πληροφορεί την ώρα περίπου της
          παράδοσης της παραγγελίας σας.
        </p>
        <p>
          Σε περίπτωση που γίνει κάποιο λάθος κατά την πληρωμή η συναλλαγή έχει καταγραφεί από εμάς και δεν πρόκειται να χαθούν
          τα χρήματά σας (αν βέβαια πάρουμε έγκριση για την κάρτα σας και το ποσό έχει όντως δεσμευθεί). Η διαδικασία είναι
          αυτοματοποιημένη, ελέγχεται και δεν υπάρχει περίπτωση να χαθούν χρήματα.
        </p>
        <p>
          Σε περίπτωση που δεν εξυπηρετηθεί η παραγγελία σας και έχετε πληρώσει με πιστωτική κάρτα, τα χρήματα σάς επιστρέφονται
          από το ψητοπωλείο με νέα τραπεζική συναλλαγή είτε με ακύρωση της Ψηφιακής κατάθεσής από υπάλληλο του ψητοπωλείου.
          Νοείται ότι θα πρέπει να έχει προηγηθεί επικοινωνία μεταξύ του χρήστη με το ψητοπωλείο και την επιβεβαίωση του χρήστη
          με το αποδεικτικό στοιχείο που έχει σταλεί από την Τράπεζα που συνεργάζεστε για να φαίνεται ο κωδικός αναφοράς ή και
          έγκρισης της παραγγελίας σας.
        </p>
        <p>
          Έχουμε λάβει κάθε μέριμνα για την ετοιμασία της Ιστοσελίδας μας. Ωστόσο, δεν θα είμαστε υπεύθυνοι για τυχόν λάθη ή
          παραλείψεις σε σχέση με τέτοιο περιεχόμενο ή για τυχόν τεχνικά προβλήματα που ενδέχεται να αντιμετωπίσετε στον
          Ιστότοπό μας. Αν ενημερωθούμε για τυχόν ανακρίβειες στην Ιστοσελίδα μας, θα φροντίσουμε να το διορθώσουμε το
          συντομότερο δυνατό. Στο βαθμό που επιτρέπεται από το νόμο, αποκλείουμε κάθε ευθύνη (είτε συμβαίνει λόγω αμέλειας ή
          άλλης μορφής) για απώλεια ή ζημία που μπορεί να υποστείτε ή οποιοσδήποτε τρίτος σε σχέση με τον Ιστότοπό μας και
          με οποιονδήποτε ιστότοπο συνδέεται με τον Ιστότοπό μας και σε σχέση με το υλικό του . Αυτό δεν επηρεάζει την ευθύνη
          μας η οποία δεν μπορεί να αποκλειστεί ή να περιοριστεί βάσει του ισχύοντος νόμου.
        </p>
        <p>
          Δεν θα είμαστε υπεύθυνοι εάν, για οποιονδήποτε λόγο, ο Ιστότοπός μας δεν είναι διαθέσιμος ανά πάσα στιγμή ή για
          οποιαδήποτε περίοδο. Από καιρό σε καιρό, ενδέχεται να περιορίσουμε την πρόσβαση σε ορισμένα μέρη του Ιστότοπού
          μας ή σε ολόκληρο τον Ιστότοπό μας σε χρήστες που έχουν εγγραφεί.
        </p>
        <p>
          Δεν είμαστε υπεύθυνοι για τυχόν καθυστέρηση ή μη εκπλήρωση των υποχρεώσεων μας βάσει της παρούσας συμφωνίας,
          οι οποίες προκύπτουν από οποιαδήποτε αιτία  που είναι πέρα από τον έλεγχο μας, συμπεριλαμβανομένων ενδεικτικά,
          τυχόν γεγονότων ανωτέρας βίας.
        </p>
        <p>
          Στο βαθμό που μας επιτρέπει ο Νόμος, μπορούμε να τροποποιήσουμε αυτούς τους όρους οποτεδήποτε δημοσιεύοντας
          ενημερωμένους ή τροποποιημένους όρους και προϋποθέσεις στην ιστοσελίδα. Ελέγχετε συχνά για τυχόν ενημερώσεις
          ή τροποποιήσεις αυτών των όρων. Εκτός αν δηλώνεται διαφορετικά από εμάς, οποιεσδήποτε τροποποιήσεις θα ισχύουν από
          την ημερομηνία δημοσίευσης των τροποποιήσεων. Η χρήση της ιστοσελίδας μετά από οποιαδήποτε τροποποίηση ή αλλαγή
          στους Όρους θα θεωρείται ως ρητή αποδοχή σας σε τέτοιους τροποποιημένους όρους και συμφωνείτε επίσης να δεσμεύεστε
          από αυτούς.
        </p>
        <p>
          Το ΓΙΑ ΣΟΥΒΛΑΚΙ "ΕΤΣΙ ΑΠΛΑ" ΛΙΜΙΤΕΔ μπορεί να παρέχει μετάφραση της Ελληνικής έκδοσης των όρων στην Αγγλική
          γλώσσα. Κατανοείτε και συμφωνείτε ότι οποιαδήποτε μετάφραση των όρων στην Αγγλική γλώσσα είναι μόνο για την
          εξυπηρέτησή σας και ότι η Ελληνική έκδοση θα διέπει τους όρους της σχέσης σας με την εμπορική επωνυμία ΓΙΑ
          ΣΟΥΒΛΑΚΙ "ΕΤΣΙ ΑΠΛΑ" ΛΙΜΙΤΕΔ. Επιπλέον, αν υπάρχουν ασυμφωνίες μεταξύ της Ελληνικής έκδοσης των όρων και της
          μεταφρασμένης έκδοσης της, η Ελληνική έκδοση των όρων υπερισχύει έναντι των άλλων.
        </p>
        <p>
          Εάν κάποια διάταξη της παρούσας συμφωνίας κριθεί ως μη νόμιμη ή ως μη εκτελεστή, δεν επηρεάζεται η ισχύς των
          υπόλοιπων διατάξεων και όρων.
        </p>
        <p>
          Οι Όροι Χρήσης, η Πολιτική Προστασίας Προσωπικών Δεδομένων και οποιοδήποτε θέμα σχετίζεται με την παρούσα ιστοσελίδα
          διέπεται και ερμηνεύεται σύμφωνα με το Κυπριακό Δίκαιο και κάθε διαφορά θα επιλύεται αποκλειστικά στα Δικαστήρια της
          Λευκωσίας.
        </p>
      </div>
    )
  }
  return (<MyDiv>
    { config.language.code === 'EL' ? drawGreekTermsAndConditions() : drawEnglishTermsAndConditions() }
  </MyDiv>)
})

export default TermsAndConditions
