import React from 'react'
import Proptypes from 'prop-types'
import _ from 'lodash'
// import {ToggleButton, ToggleButtonGroup} from '@material-ui/lab'
import StyledToggleButton from './togglebutton.styles'

const CustomToggleButton = (props) => {
  const setClasses = (sizeId, productId) => {
    let className = 'toggle-btn'
    if (productId === props?.selected?.product_id && sizeId === props?.selected?.size_id) {
      className = `${className} selected`
    }
    return className
  }
  return (
    <StyledToggleButton>
      {_.map(props.buttons, (btn, index) => {
        return (
          <div className={setClasses(btn?.size_id, btn?.product_id)} onClick={() => props.onChange(btn, index)} key={`toggle_btn${index + 1}`}>
            {btn?.size ? btn.size : btn}
          </div>
        )
      })}
    </StyledToggleButton>
  )
}

CustomToggleButton.propTypes = {
  selected: Proptypes.object,
  theme: Proptypes.object,
  onChange: Proptypes.func,
  buttons: Proptypes.array,
}

export default CustomToggleButton
