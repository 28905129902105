export const GET_CITY_REQUEST = 'GET_CITY_REQUEST'
export const GET_CITY_FAILURE = 'GET_CITY_FAILURE'
export const GET_CITY_SUCCESS = 'GET_CITY_SUCCESS'

export const GET_COUNTRY_REQUEST = 'GET_COUNTRY_REQUEST'
export const GET_COUNTRY_FAILURE = 'GET_COUNTRY_FAILURE'
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS'

export const GET_POSTAL_CODE_REQUEST = 'GET_POSTAL_CODE_REQUEST'
export const GET_POSTAL_CODE_FAILURE = 'GET_POSTAL_CODE_FAILURE'
export const GET_POSTAL_CODE_SUCCESS = 'GET_POSTAL_CODE_SUCCESS'

export const GET_LANGUAGES_REQUEST = 'GET_LANGUAGES_REQUEST'
export const GET_LANGUAGES_FAILURE = 'GET_LANGUAGES_FAILURE'
export const GET_LANGUAGES_SUCCESS = 'GET_LANGUAGES_SUCCESS'

export const GET_RESTRO_TIMING_REQUEST = 'GET_RESTRO_TIMING_REQUEST'
export const GET_RESTRO_TIMING_FAILURE = 'GET_RESTRO_TIMING_FAILURE'
export const GET_RESTRO_TIMING_SUCCESS = 'GET_RESTRO_TIMING_SUCCESS'
