export const metaData = {
  login: 'Log In',
  logoutMenu: 'Log out',
  profileMenu: 'Profile',
  restaurant: 'Restaurant',
  home: 'Home',
  aboutus: 'About Us',
  userlogin: 'User Login',
  signin: {
    title: 'Welcome',
    subtitle: 'Sign in using your phone number to continue',
    subtitle1: 'Forgot password',
    subtitle2: "Don't have an account",
  },
  signup: {
    title: 'Welcome',
    subtitle: 'Sign in using your phone number to continue',
    subtitle1: 'Forgot password',
    subtitle2: 'By clicking Sign up, you will create an account and agree to ours',
    and: 'and',
    termservice: 'Terms of use',
    privacypolicy: 'Privacy Policy',
  },
  forgotpassword: {
    title: 'Forget password',
    subtitle: 'Enter Your Phone number and will send an OTP to reset it',
  },
  verifyotp: {
    title: 'Phone Verification',
    subtitle: 'Please enter the SMS verification code. Have an account?',
  },
  changepassword: {
    title: 'New Password',
    subtitle: 'Please enter your new password',
  },
  changephonenumber: {
    title: 'Change Phone Number',
    subtitle: 'Please enter your new phone number',
  },
  profilechangepassword: {
    title: 'Change Password',
    subtitle: 'Please enter your new password',
  },
  product: {
    description: 'Description',
    ingredients: 'Allergens',
    type: 'Type',
    size: 'Size',
  },
  inputs: {
    labels: {
      phone: 'Phone',
      password: 'Password',
      name: 'Name',
      surname: 'Surname',
      confirmpassword: 'Confirm password',
      oldpassword: 'Old password',
      newpassword: 'New password',
    },
    buttons: {
      signin: 'Sign in',
      signup: 'Sign up',
      send: 'Send',
      verify: 'Verify my phone',
      savecontinue: 'Save and continue',
    },
  },
  orderLabel: 'order',
  cart: {
    postalcode: 'Postal code',
    delivery: 'Delivery',
    takeAway: 'Take away',
    changeAddress: 'Change address',
    clearCart: 'Clear cart',
    note: 'On average deliveries are made between 40 minutes to 1 hour.',
    orderTotal: 'Order total',
    otherCharges: 'Other charges',
    deliveryCost: 'Delivery cost',
    placeOrder: 'Place order',
    addToCart: 'Add to cart',
    cancel: 'Cancel',
    emptyCart: 'Whatever you\'re craving, just add it to the cart!',
    preferences: 'Preferences',
    termsAndConditions: 'I have read and agreed with',
    termsAndConditionsLink: 'terms of use',
    with: 'With',
    without: 'Without',
    withSalad: 'With salad',
    noSalad: 'No salad',
    comments: 'Comments',
    freeDelivery: 'Free delivery',
    COD: 'Cash payment',
    online: 'Pay online',
  },
  postalCodeCheck: {
    success: 'We deliver to this postal code with a charge of',
    error: 'We are not delivering this postal code at this time.',
  },
  paymentStatusCheck: {
    success: 'Your order placed successfully.',
    error: 'Sorry, your transaction has failed. Please try again.',
  },
  profile: {
    mainSettings: 'Main settings',
    myProfile: {
      title: 'My profile',
      name: 'Name',
      phoneNumber: 'Phone number',
      verified: 'Verified',
      not: 'Not verified',
      systemLanguage: 'System language',
      changePassword: 'Change password',
    },
    myAddresses: {
      title: 'My addresses',
      updateAddress: 'Update address',
      newAddress: 'New address',
      address1: 'Address',
      address2: 'Area',
      postalCode: 'Postal code',
      city: 'City',
      country: 'Country',
      cancel: 'Cancel',
      saveAddress: 'Save address',
      primary: 'Primary',
      makePrimary: 'Make primary',
      isPrimary: 'Is primary',
      address: 'Address',
    },
    myOrders: {
      title: 'My orders',
    },
  },
  restroTimings: {
    restOfTheDay: 'The restaurant is closed for the day.',
    closeUntil: 'The restaurant is closed until',
  },
  aboutUsPage: {
    p1: 'Our story began in 2010, when we managed the café of a community swimming pool in a certain Nicosia neighborhood.' +
      ' Along with the other items that we sold, our customers always asked us for souvlakia.' +
      ' So, intent on pleasing our customers in every way, we got a simple gas grill and started selling souvlaki every Sunday.' +
      ' The customers’ reaction was not only positive, but it exceeded our expectations! In a situation that was less than ideal,' +
      ' we learned from our mistakes and, with a lot of hard work, perfected our product. Within the next five years,' +
      ' our souvlaki became famous in the neighborhood and our customers came back again and again.',
    p2: 'In 2015, with a good amount of experience under our belts, we decided to take the next step,' +
      ' which was to open our first restaurant in the Acropolis area of Nicosia. Starting a new business wasn’t easy,' +
      ' but “Gia Souvlaki… Etsi Apla” became a favorite for many, admired for its high-quality food and fresh,' +
      ' pure ingredients. We made a souvlaki restaurant different from all the rest, choosing to give the best to' +
      ' our customers and to deliver it to them every day for lunch and dinner.',
    p3: 'The demand for our product grew and grew, soon outgrowing our premises in Acropolis.' +
      ' So, in September of 2018, we opened our second grill restaurant, located at Metochiou 41B in Agios Andreas.' +
      'We were finally able to fulfill the needs of all our loyal customers and ever-increasing clientele who enjoy' +
      ' our tasty product and appreciate the high level of service that “Gia Souvlaki… Etsi Apla” offers.' +
      ' Towards the end of 2018, we closed the shop in Acropolis and decided to focus all our energy on the restaurant' +
      ' in Agios Andreas, which is centrally located, able to provide quick service to all of Nicosia.' +
      ' It has a pleasant dining area, which we frequently upgrade for our customers’ best dining experience.' +
      'There, you can enjoy your favorite pork or chicken souvlaki and seftalia. Our house specialty is the giant porkchop and you should definitely try our homemade burgers.',
  },
  termsOfUse: 'Terms of use',
  privacyPolicy: 'Privacy policy',
  contactUs: {
    menu: 'Contact Us',
    address: 'Address:',
    phone: 'Phone:',
    email: 'Email address:',
  },
}
