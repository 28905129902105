import apiInstance from '../config/api/axios'
import {getToken, removeToken} from '../utilities/authUtils'
export const orderProcess = async(lang, codPaymentOption) => {
  const config = {
    header: {
      accept: 'application/json',
    },
  }
  const cartId = getToken('cart')
  const data = new FormData()
  if (cartId) {
    data.append('cart_id', cartId)
  }
  const response = await apiInstance.post(`user/payment-process?lang=${lang || 'en'}`, data, config)
  if (response.status && response.status.toUpperCase() === 'SUCCESS') {
    response.payment_type === codPaymentOption && removeToken('cart')
  }
  return response
}
