import styled from 'styled-components'

const AuthModal = styled.div `
    .auth-modal-title h2{
        float: right;
    }

    .auth-modal-close-btn {
        margin-right: -24px;
        margin-top: -16px;
        float: right;
    }
`

export default AuthModal
