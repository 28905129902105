import * as actionsType from '../actions/actionsType'
import {metaData} from '../../config/languages/english.language'
const INITIAL_STATE = {
  language: {
    code: 'EN',
    meta_data: metaData,
  },
}

const ConfigReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionsType.SET_LANGUAGE_REQUEST:
      return {
        ...state,
        language: {
          ...state.language,
          meta_data: {
            ...action.payload,
          },
          code: action.payload && action.payload.lang_code ? action.payload.lang_code.toUpperCase() : 'EN',
        },
      }
    default:
      return state
  }
}

export default ConfigReducers
