import React from 'react'
import PrivacyAndPolicyComp from '../../components/PrivacyAndPolicy'
import MyDiv from './privacyPolicy.style'

const PrivacyAndPolicy = React.memo((props) => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
  return (<MyDiv>
    <PrivacyAndPolicyComp />
  </MyDiv>)
})

export default PrivacyAndPolicy
