import React, {useEffect, useRef} from 'react'
import {useSelector, connect} from 'react-redux'
import {useHistory, withRouter} from 'react-router-dom'
import {bindActionCreators} from 'redux'
import {compose} from 'recompose'
import {ActionCreators} from '../../../redux/actions'
import * as routesNames from '../../../constants/routes'
import './paymentGateway.css'
export const PaymentGateway = (props) => {
  const history = useHistory()
  const paymentState = useSelector((state) => state.paymentState?.orderprocess?.data?.gatewayDetails)
  let form = useRef()
  useEffect(() => {
    if (paymentState) {
      form.current.submit()
    } else {
      history.push(routesNames.LANDING)
    }
  }, [])
  useEffect(() => {
    return () => {
      props.actions.cleanUpPaymentForm()
    }
  }, [])
  return (
    <div style={{
      top: 0,
      bottom: 0,
      backgroundColor: '#FFFFFF',
      position: 'absolute',
    }}
    >
      <form ref={form} method="post" action={paymentState?.formAction} style={{display: 'none'}}>
        <input type="text" id="Version" name="Version" value={paymentState?.Version} /><br />
        <input type="text" id="MerID" name="MerID" value={paymentState?.MerID} /><br />
        <input type="text" id="AcqID" name="AcqID" value={paymentState?.AcqID} /><br />
        <input type="text" id="MerRespURL" name="MerRespURL" value={paymentState?.MerRespURL} /><br />
        <input type="text" id="PurchaseAmt" name="PurchaseAmt" value={paymentState?.PurchaseAmt} /><br />
        <input type="text" id="PurchaseCurrency" name="PurchaseCurrency" value={paymentState?.PurchaseCurrency} /><br />
        <input type="text" id="PurchaseCurrencyExponent" name="PurchaseCurrencyExponent" value={paymentState?.PurchaseCurrencyExponent} /><br />
        <input type="text" id="OrderID" name="OrderID" value={paymentState?.OrderID} /><br />
        <input type="text" id="CaptureFlag" name="CaptureFlag" value={paymentState?.CaptureFlag} /><br />
        <input type="text" id="Signature" name="Signature" value={paymentState?.Signature} /><br /><br />
        <input type="text" id="SignatureMethod" name="SignatureMethod" value={paymentState?.SignatureMethod} /><br /><br />
        <input type="submit" value="submit" />
      </form>
      <div className="page-loaderPaymentGateway">
        <div className="spinnerPaymentGateway" />
        <div className="txtPaymentGateway">Redirecting to Payment...</div>
      </div>
    </div>
  )
}
const mapDispatchToProps = (dispath) => ({
  actions: bindActionCreators(ActionCreators, dispath),
})
export default
React.memo(compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(PaymentGateway))
