import styled from 'styled-components'

const MyDiv = styled.div `
text-align: left;
padding: 80px 0;
.aboutUsTitle{
  font-size: 20px;
  margin-left: 20px;
  @media(max-width:959px){
    padding: 0 20px;
  }
}
.paragraph{
  display: flex;
  margin-top: 30px;
  @media(max-width:959px){
    padding: 0 20px;
  }
  @media(max-width:767px){
    padding: 0 20px;
    display:block;
  }
  img{
    @media(max-width:767px){
     width:100%;
    margin-left: 0px; 
     margin-right: 0px;
    }
    @media(min-width:768px) and (max-width:959px){
      height: 200px;
      width: 200px;
      object-fit: cover;
    }
    height: 300px;
    width: 300px;
    margin-left: 20px;
    margin-right: 20px;
  }
  span{
    margin-left: 20px;
    margin-right: 20px;
    @media(max-width:767px){
      margin-left: 0px; 
       margin-right: 0px;
    }
  }
}
`
export default MyDiv
