import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {Grid, Typography} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
// import Snackbar from '@material-ui/core/Snackbar'
// import Alert from '@material-ui/lab/Alert'
// import SnackbarComponent from '../../../components/feedback/snackbar/snackbar'
import {getLabelsBasedOnLanguage} from '../../../constants/common'
import CustomInput from '../../inputs/textbox'
import CustomButton from '../../inputs/button'
import SigninFormStyled from './signin.styles'
const SignInForm = (props) => {
  const {code} = props
  const [form, setForm] = useState({
    phone: '',
    password: '',
  })

  useEffect(() => {
    return () => {
      props.cleanUp()
    }
  }, [])
  const handleChange = (e) => {
    let data = {
      ...form,
      [e.target.name]: e.target.value,
    }
    setForm(data)
  }

  const changeView = (n) => {
    props.view(n)
  }

  const handleSignIn = () => {
    props.submit(form)
  }

  return (
    <SigninFormStyled>
      <form>
        {props.isLoading ? <div className="spinner">
          <CircularProgress style={{height: '40px', width: '40px', position: 'fixed', top: '50%', left: '49%'}} />
        </div> : null
        }
        {/* <SnackbarComponent
          message={msg}
          severity={status}
          duration={6000}
          open={msg.length > 0}
          close={props.closeMsg}
        /> */}
        <Grid container>
          <Grid item lg={1} md={1} sm={1} xs={1} />
          <Grid item lg={10} md={10} sm={10} xs={10}>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12} className="center_element">
                <Typography
                  variant="h4"
                >
                  {getLabelsBasedOnLanguage('signin.title', code)}
                </Typography>
                <br />
                <br />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} className="center_element">
                <Typography
                  variant="subtitle1"
                >
                  {getLabelsBasedOnLanguage('signin.subtitle', code)}
                </Typography>
                <br />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <CustomInput
                  label={`${getLabelsBasedOnLanguage('inputs.labels.phone', code)}*`}
                  type="number"
                  name="phone"
                  value={form.phone}
                  multiple={false}
                  onChange={handleChange}
                  error={null}
                />
                {props.error && <span className="error">{props.error.phone}</span>}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <CustomInput
                  label={`${getLabelsBasedOnLanguage('inputs.labels.password', code)}*`}
                  type="password"
                  name="password"
                  value={form.password}
                  multiple={false}
                  onChange={handleChange}
                  error={null}
                />
                {props.error && <span className="error">{props.error.password}</span>}
                <br />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography
                  variant="subtitle1"
                  className="forgot_password"
                  onClick={() => changeView(2)}
                >
                  {`${getLabelsBasedOnLanguage('signin.subtitle1', code)}?`}
                </Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <CustomButton
                  label={getLabelsBasedOnLanguage('inputs.buttons.signin', code)}
                  onClick={handleSignIn}
                  size="large"
                  color="primary"
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} className="center_element">
                <Typography
                  variant="subtitle2"
                >
                  {`${getLabelsBasedOnLanguage('signin.subtitle2', code)}?`} <span className="forgot_password" onClick={() => changeView(1)}>
                    {getLabelsBasedOnLanguage('inputs.buttons.signup', code)}</span>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={1} />
        </Grid>
      </form>
    </SigninFormStyled>
  )
}

SignInForm.propTypes = {
  view: PropTypes.func,
  submit: PropTypes.func,
  code: PropTypes.string,
  msg: PropTypes.string,
  status: PropTypes.string,
  cleanUp: PropTypes.func,
  closeMsg: PropTypes.func,
  isLoading: PropTypes.bool,
  error: PropTypes.any,
}

export default SignInForm
