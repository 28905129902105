import styled from 'styled-components'

const FooterStyled = styled.div`
  word-break: break-all;
  padding-left: 14px;
  text-align: center;
  min-height: 50px;
  background-color: red;
  color: white;
  @media(min-width:541px) {
    line-height: 50px;
  }
  line-height: 25px;
  font-size: 15px;
  z-index: 2;
  margin-top: auto;
  width: 100%;
  .fTitle{
    @media(min-width:959px){
      margin-left: 220px;
    }
    white-space: nowrap
  }
  .links{
    @media(min-width:541px) {
      float: right;
    }
    a{
      white-space: nowrap
    }
    margin-right: 14px;
    text-decoration: underline;
  }
`

export default FooterStyled
