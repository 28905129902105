import {setUserLanguage} from './userActions'
import * as actionTypes from './actionsType'

const setLanguageRequest = (languageData) => {
  return {
    type: actionTypes.SET_LANGUAGE_REQUEST,
    payload: languageData,
  }
}

export const setLanguageAction = (selectedLanguage, userDetails, lang) =>
  async(dispatch) => {
    if (userDetails) {
      dispatch(setUserLanguage(selectedLanguage.id, lang))
    } else if (selectedLanguage) {
      dispatch(setLanguageRequest(selectedLanguage))
    }
  }


