import apiInstance from '../config/api/axios'

export const getCountries = async(lang) => {
  const response = await apiInstance.get(`master/country?lang=${lang || 'en'}`)
  return response
}

export const getCities = async(lang) => {
  const response = await apiInstance.get(`master/city?lang=${lang || 'en'}`)
  return response
}

export const getPostalCodes = async(lang) => {
  const response = await apiInstance.get(`user/master/post-code?lang=${lang || 'en'}`)
  return response
}

export const getLanguagesList = async() => {
  const response = await apiInstance.post('master/language-list')
  return response
}

export const getRestroTimings = async(lang) => {
  const response = await apiInstance.post(`master/timing?lang=${lang || 'en'}`)
  return response
}
