import apiInstance from '../config/api/axios'
// import {setToken, getToken, removeToken} from '../utilities/authUtils'

export const getAddresses = async(lang) => {
  const config = {
    header: {
      accept: 'application/json',
    },
  }

  const response = await apiInstance.get(`addresses?lang=${lang || 'en'}`, config)
  return response
}

export const createAddress = async(addressData, lang) => {
  const config = {
    header: {
      accept: 'application/json',
    },
  }
  const data = new FormData()
  data.append('address1', addressData.address1)
  data.append('address2', addressData.address2)
  data.append('post_code', addressData.post_code)
  data.append('country_id', addressData.country_id)
  data.append('city_id', addressData.city_id)
  data.append('is_primary', addressData.is_primary ? 1 : 0)
  const response = await apiInstance.post(`addresses?lang=${lang || 'en'}`, data, config)
  return response
}

export const updateAddress = async(addressData, lang) => {
  const config = {
    header: {
      'accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  }
  const data = new URLSearchParams()
  data.append('address1', addressData.address1)
  data.append('address2', addressData.address2)
  data.append('post_code', addressData.post_code)
  data.append('country_id', addressData.country_id)
  data.append('city_id', addressData.city_id)
  data.append('is_primary', addressData.is_primary)
  const response = await apiInstance.put(`addresses/${addressData.id}?lang=${lang || 'en'}`, data, config)
  return response
}

export const makeAddressPrimary = async(addressData, lang) => {
  const config = {
    header: {
      'accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  }
  const data = new URLSearchParams()
  const response = await apiInstance.put(`address/primary/${addressData.id}?lang=${lang || 'en'}`, data, config)
  return response
}

export const getOrdersHistory = async(lang) => {
  const config = {
    header: {
      accept: 'application/json',
    },
  }
  const response = await apiInstance.post(`user/get-order-history?lang=${lang || 'en'}`, null, config)
  return response
}

export const updateUserLanguage = async(languageId, lang) => {
  const config = {
    header: {
      accept: 'application/json',
    },
  }
  const response = await apiInstance.get(`updateLanguage/${languageId}?lang=${lang || 'en'}`, config)
  return response
}


