import React from 'react'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import {Divider} from '@material-ui/core'
import DividerStyled from './divider.style'

const useStyles = makeStyles((theme) => ({
  root: {

  },
  customeDivider: {
    height: '1px',
  },
}))

function CustomDivider(props) {
  const classes = useStyles()
  const theme = useTheme()
  return (
    <DividerStyled className={classes.root} style={{
      width: props.width,
      backgroundColor: props.color || theme.palette.text.primary,
    }}
    >
      <Divider className={classes.customeDivider} />
    </DividerStyled>
  )
}

export default CustomDivider
