import styled from 'styled-components'

const StyledToggleButtonType = styled.div `
    display: inline-block;
    .toggle-btn {
        border-radius: 13px;
        border: 1px solid #8080808a;
        width: fit-content;
        padding: 5px 15px;
        display: inline-block;
        cursor: pointer;
        margin: 5px;
        background-color: #FFFFFF;
    }

    .selected {
        background-color: ${(props) => props.theme.palette.primary.main};
        border: none;
        color: ${(props) => props.theme.palette.secondary.main};
    }

`

export default StyledToggleButtonType
