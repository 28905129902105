import styled from 'styled-components'

const MyDiv = styled.div `
.profileTitle{
 // font-family: Proxima Nova;
  font-size: 22px;
  font-weight: 700;
  color: #000000;
  padding: 13px 66px 12px 66px;
  border-radius: 10px 10px 0 0;
  background-color: #F1F1F1;
  @media(max-width:767px){
    padding: 15px 25px;
  }
  @media(min-width:768px) and (max-width:1023px){
      padding: 15px 25px;
    }
}
.errorAddress{
  margin-top: 10px!important;
  line-height: 0;
  span {
    color:red;
  }
}
.addressList{
  padding: 10px 0px 0px 66px;
  @media(max-width:767px){
    padding: 0px;
    margin-top: 50px;
  }
  @media(min-width:768px) and (max-width:1023px){
    padding: 0px 25px;
  }
}
  .mobileAddList{
    @media(max-width:767px){
      box-shadow: 0 6px 30px 0 rgba(0,0,0,.12);
      padding: 15px 15px;
    }
  }
  .addressText{
    //font-family: Arial;
    font-size: 18px;
    font-weight: 400;
    color: #666666;
    width: fit-content;
    overflow-wrap: anywhere;
    @media(max-width:767px){
      font-size: 16px;
    }
  }
  .underline{
    text-decoration: underline;
    margin-top: 50px;
    display: block;
    cursor: pointer;
    @media(max-width:767px){
      margin-top: 0px;
    }
  }
  hr{
    width: 100%;
    margin-top: 46.50px;
    border: 0;
    border-top: 1px solid #707070;
  }
  .toggleBtn{
    border-radius: 20px;
    width: 144px;
    text-align: center;
    padding: 7px 0px;
    //font-family: Proxima Nova;
    font-size: 16px;
    margin-top: 10px;
    font-weight: 400;
    color: #FFFFFF;
    background-color: #ED4E53;
    display: inline-block;
    margin-left: 30px;
    @media(max-width:767px){
      margin-left: 0px;
    }
  }
  .makePrimary{
    background-color: #000000;
    border: none;
    color: #FFFFFF;
    cursor: pointer;
  }
  .cancelToggleBtn{
    background-color: #FFFFFF;
    color: #000000;
    cursor: pointer;
    border: 1px solid #70707020;
    width: 107px;
  }
  .actionsList{
    .toggleBtn{
      margin: 50px 13px 0px 0px;
      cursor: pointer;
    }
  }
  .addressDetailsBoxMain{
    margin-top: 50px;
    .addressDetailsRow{
      margin-top: 30px;
      display: flex;
      label{
       // font-family: Arial;
        font-size: 17px;
        font-weight: 400;
        color: #666666;
        width: 120px;
        display: block;
      }
    }
    .addressUpdateTitle{
      //font-family: Arial;
      font-size: 26px;
      font-weight: 400;
      color: #666666;
      margin-bottom: 13px;
    }
    .inputBox{
      width: 450px;
    }
    sup{
      //font-family: Arial;
      font-size: 17px;
      font-weight: 400;
      color: #FF0000;
    }
    input[type=text], select {
      padding: 14px 16px;
      display: inline-block;
      border: 1px solid #E9E9E9;
      box-sizing: border-box;
      outline: 0;
    }
    input[type=checkbox] {
      padding: 14px 16px;
      display: inline-block;
      border: 1px solid #E9E9E9;
      box-sizing: border-box;
      outline: 0;
    }
  }
}
.addMobileSet{
      @media(max-width:767px){
        padding:0px 15px;
      }
    }
.addNewAddress{
  display: flex;
  margin-top: 48px;
  cursor: pointer;
  .addIcon{
    height: 80px;
    width: 80px;
    border: 1px solid #000000;
    border-radius: 50%;
    text-align: center;
    padding-top: 40px;
    line-height: 0;
    //font-family: Proxima Nova;
    font-size: 30px;
    font-weight: 500;
    color: #000000;
    @media(max-width:767px){
      height: 50px;
    width: 50px;
    padding-top: 22px;
    }
    @media(min-width:768px) and (max-width:1023px){
      height: 60px;
      width: 60px;
      padding-top: 25px;
    }
  }
  .addTitle{
   // font-family: Proxima Nova;
    font-size: 28px;
    font-weight: 500;
    color: #0F1350;
    margin-left: 26px;
    margin-top: 21px;
    @media(max-width:767px){
      font-size: 22px;
      margin-left: 15px;
      margin-top: 10px;
    }
    @media(min-width:768px) and (max-width:1023px){
      font-size: 25px;
      margin-left: 15px;
      margin-top: 10px;
    }
  }
}
.spinner {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #bdbdbd7a;
  z-index: 1000;
  text-align: center;
}
`
export default MyDiv
