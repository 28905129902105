import * as authActions from './authActions'
import * as productActions from './productActions'
import * as configActions from './configActions'
import * as userActions from './userActions'
import * as masterActions from './masterActions'
import * as paymentActions from './paymentActions'
export const ActionCreators = Object.assign(
  {},
  authActions,
  productActions,
  configActions,
  userActions,
  masterActions,
  paymentActions,
)

