import styled from 'styled-components'

const ProductsStyled = styled.div `
    .cat_div {
        display: inline-block;
        font-size: 0.75rem;
        padding: 10px;
    }

    .cat_div img {
        padding: 0px;
        border: 5px solid ${(props) => props.theme.palette.primary.main};
        width: 100px;
        height: 100px;
        border-radius:50%;
    }
    .product-card-list{
        margin-top: 60px;
    }
    .spinner {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: #bdbdbda6;
        z-index: 1000;
      }
`
export default ProductsStyled
