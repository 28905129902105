export const USER_ADDRESS_GET_REQUEST = 'USER_ADDRESS_GET_REQUEST'
export const USER_ADDRESS_GET_FAILURE = 'USER_ADDRESS_GET_FAILURE'
export const USER_ADDRESS_GET_SUCCESS = 'USER_ADDRESS_GET_SUCCESS'

export const USER_ADDRESS_CREATE_REQUEST = 'USER_ADDRESS_CREATE_REQUEST'
export const USER_ADDRESS_CREATE_FAILURE = 'USER_ADDRESS_CREATE_FAILURE'
export const USER_ADDRESS_CREATE_SUCCESS = 'USER_ADDRESS_CREATE_SUCCESS'

export const USER_ADDRESS_UPDATE_REQUEST = 'USER_ADDRESS_UPDATE_REQUEST'
export const USER_ADDRESS_UPDATE_FAILURE = 'USER_ADDRESS_UPDATE_FAILURE'
export const USER_ADDRESS_UPDATE_SUCCESS = 'USER_ADDRESS_UPDATE_SUCCESS'

export const USER_LANGUAGE_UPDATE_REQUEST = 'USER_LANGUAGE_UPDATE_REQUEST'
export const USER_LANGUAGE_UPDATE_FAILURE = 'USER_LANGUAGE_UPDATE_FAILURE'
export const USER_LANGUAGE_UPDATE_SUCCESS = 'USER_LANGUAGE_UPDATE_SUCCESS'
