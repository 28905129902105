import React, {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import PropTypes from 'prop-types'
// import {useSelector} from 'react-redux'
import {makeStyles} from '@material-ui/core/styles'
import {AppBar, Toolbar, Button, MenuItem, Menu, IconButton} from '@material-ui/core'
import {Person as PersonIcon, ArrowDropDown, Menu as MenuIcon} from '@material-ui/icons'
import {useHistory, withRouter, NavLink} from 'react-router-dom'
import moment from 'moment'
import LanguageComponent from '../../inputs/language'
import {getLabelsBasedOnLanguage} from '../../../constants/common'
import * as routesNames from '../../../constants/routes'
import restroLogo from '../../../images/etsi-apla-logo.png'
// import {Link, useHistory} from 'react-router-dom'
// import {openSecurityPages} from '../../../constants/constant'
// import * as routes from '../../../constants/routes'
import StyledHeaderComponent from './header.styles'
// import logoImage from '../../../../images/set_protocol_logo.png'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    // flexGrow: 1,
    width: '25%',
  },
  grow: {
    flexGrow: 1,
  },
  logo: {
    display: 'block',
    textIndent: '-9999px',
  },
  loginButton: {
    //marginRight: theme.spacing(5),
  },
  username: {
    width: '50%',
  },
  menu: {
    '&': {
      top: '25px',
      right: '75px',
    },
  },
  logoDiv: {
    display: 'flex',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    objectFit: 'cover',
    width: '250px',
    height: '64px',
    // backgroundImage: `url(${logoImage})`,
  },
}))
const MAX_WIDTH_FOR_MOBILE = 959
const Header = (props) => {
  const {config} = props
  const classes = useStyles()
  const history = useHistory()
  // const history = useHistory()
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorElMenu, setAnchorElMenu] = useState(null)
  const [isMobile, setIsMobile] = useState(window.innerWidth < MAX_WIDTH_FOR_MOBILE)
  const masterState = useSelector((state) => state.masterState)
  const handleLoginBtnClick = () => {
    props.login(true)
  }
  const handleResize = () => {
    if (window.innerWidth < MAX_WIDTH_FOR_MOBILE) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }
  useEffect(() => {
    window.addEventListener('resize', handleResize)
  })
  const handleLogOut = () => {
    setAnchorEl(null)
    history.push(routesNames.LANDING)
    props.logout()
  }
  const handleprofile = () => {
    setAnchorEl(null)
    history.push(routesNames.PROFILE)
  }
  // const handleDashboard = () => {
  //   setAnchorEl(null)
  //   history.push(routesNames.LANDING)
  // }
  const handleLanguageChange = (selectedLanguage) => {
    props.changelang(selectedLanguage)
  }

  const handleMenuClick = (e) => {
    setAnchorElMenu(e.currentTarget)
  }
  const handleAuthMenuClick = (e) => {
    setAnchorEl(e.currentTarget)
  }
  const appLogoTitleView = () => {
    return (
      <NavLink to={routesNames.LANDING}>
        <img src={restroLogo} className="logoImg" alt="Restaurant" />
      </NavLink>
    )
  }
  const handleMenuIconClick = () => {
    setAnchorElMenu(null)
  }
  const appMenus = () => {
    return (
      <>
        {
          isMobile ? <>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleMenuClick}
              style={{float: 'right', marginTop: '4px'}}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={anchorElMenu}
              keepMounted
              open={Boolean(anchorElMenu)}
              onClose={handleMenuIconClick}
            >
              <MenuItem className="menuitem">
                <NavLink onClick={handleMenuIconClick} to={routesNames.LANDING} style={{textDecoration: 'none', padding: '6px 16px', color: 'inherit'}}
                  activeStyle={{color: 'inherit'}}
                >
                  {getLabelsBasedOnLanguage('home', config.code)}
                </NavLink>
              </MenuItem>
              <MenuItem className="menuitem">
                <NavLink onClick={handleMenuIconClick} to={routesNames.ABOUT_US} style={{textDecoration: 'none', padding: '6px 16px', color: 'inherit'}}
                  activeStyle={{color: 'inherit'}}
                >
                  {getLabelsBasedOnLanguage('aboutus', config.code)}
                </NavLink>
              </MenuItem>
              <MenuItem onClick={handleMenuIconClick} className="menuitem">
                <NavLink to={routesNames.CONTACT_US} style={{textDecoration: 'none', padding: '6px 16px', color: 'inherit'}}
                  activeStyle={{color: 'inherit'}}
                >
                  {getLabelsBasedOnLanguage('contactUs.menu', config.code)}
                </NavLink>
              </MenuItem>
            </Menu>
          </> : <>
            <MenuItem className="menuitem">
              <NavLink to={routesNames.LANDING} style={{textDecoration: 'none', padding: '6px 16px', color: 'inherit'}}
                activeStyle={{color: 'inherit'}}
              >
                {getLabelsBasedOnLanguage('home', config.code)}
              </NavLink>
            </MenuItem>
            <MenuItem className="menuitem">
              <NavLink to={routesNames.ABOUT_US} style={{textDecoration: 'none', padding: '6px 16px', color: 'inherit'}}
                activeStyle={{color: 'inherit'}}
              >
                {getLabelsBasedOnLanguage('aboutus', config.code)}
              </NavLink>
            </MenuItem>
            <MenuItem className="menuitem">
              <NavLink to={routesNames.CONTACT_US} style={{textDecoration: 'none', padding: '6px 16px', color: 'inherit'}}
                activeStyle={{color: 'inherit'}}
              >
                {getLabelsBasedOnLanguage('contactUs.menu', config.code)}
              </NavLink>
            </MenuItem>
          </>
        }
      </>
    )
  }

  const showRestroClosing = () => {
    const currentDate = moment()
    let isOpen = false
    let message = null
    masterState.restroTimings.data && masterState.restroTimings.data.map((timeObj) => {
      if (isOpen || message) {
        return
      }
      const startTime = timeObj.startTime.split(':')
      const startHours = parseInt(startTime[0], 10)
      const startMinutes = parseInt(startTime[1], 10)
      const startDate = moment().set('hours', startHours).set('minutes', startMinutes)
      const endTime = timeObj.endTime.split(':')
      const endHours = parseInt(endTime[0], 10)
      const endMinutes = parseInt(endTime[1], 10)
      const endDate = moment().set('hours', endHours).set('minutes', endMinutes)
      if (currentDate.isBefore(startDate)) {
        const minutesMsg = startMinutes > 0 ? `:${startMinutes}` : ''
        message = startHours === 12 ? `12${minutesMsg}pm` : startHours > 12 ? `${startHours % 12}${minutesMsg}pm` :
          `${startHours}${minutesMsg}am`
        message = `${getLabelsBasedOnLanguage('restroTimings.closeUntil', config.code)} ${message}.`
      } else if (currentDate.isAfter(startDate) && currentDate.isBefore(endDate)) {
        isOpen = true
      }
      return isOpen
    })
    if (masterState.restroTimings.data && masterState.restroTimings.data.length && !isOpen && !message) {
      message = `${getLabelsBasedOnLanguage('restroTimings.restOfTheDay', config.code)}`
    }
    return (
      message ? <span className="restroClosingMessage">{message}</span> : null
    )
  }
  const restorPhone = () => {
    return (<span className="headerlast restroPhone">
      +357 22263400
    </span>)
  }
  const authAreaView = () => {
    return (
      <div container className="navigator">
        <span className="headerlast">
          <LanguageComponent
            languages={masterState.languages.data || []}
            click={handleLanguageChange}
            selected={config.code}
          />
        </span>
        {
          isMobile ? null : restorPhone()
        }
        {props.user && props.user.name ?
          <Button style={{color: '#000000'}} aria-controls="simple-menu" aria-haspopup="true" onClick={handleAuthMenuClick}>
            {props.user.name}
            <ArrowDropDown aria-controls="simple-menu" aria-haspopup="true" onClick={handleAuthMenuClick} />
          </Button>
          :
          <Button onClick={handleLoginBtnClick} className={classes.loginButton}>
            <PersonIcon />
            {getLabelsBasedOnLanguage('login', config.code)}
          </Button>
        }
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          getContentAnchorEl={null}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem onClick={handleprofile}>{getLabelsBasedOnLanguage('profileMenu', config.code)}</MenuItem>
          {/* <MenuItem onClick={handleDashboard}>Dashboard</MenuItem> */}
          <MenuItem onClick={handleLogOut}>{getLabelsBasedOnLanguage('logoutMenu', config.code)}</MenuItem>
        </Menu>
      </div>
    )
  }

  const drawHeader = () => {
    return (
      <>
        {
          isMobile ? <>
            <div className="header-item grow">
              <div>
                {appMenus()}
                {authAreaView()}
              </div>
            </div>
          </> : <>
            <div className="header-item grow">
              {appMenus()}
            </div>
            <div className="header-item">
              {showRestroClosing()}
              {authAreaView()}
            </div>
          </>
        }
      </>
    )
  }
  return (
    <StyledHeaderComponent>
      <AppBar position="static" color="inherit">
        {
          isMobile ? <div className="mobileViewHeaderRow">
            {showRestroClosing()}
            {restorPhone()}
          </div> : null
        }
        <Toolbar>
          <div className="header-logo">
            {appLogoTitleView()}
          </div>
          {drawHeader()}
        </Toolbar>
      </AppBar>
    </StyledHeaderComponent>
  )
}

Header.propTypes = {
  login: PropTypes.func,
  logout: PropTypes.func,
  changelang: PropTypes.func,
  langcode: PropTypes.string,
  user: PropTypes.object,
}

export default withRouter(Header)


