/* eslint-disable max-len */
import React, {useState, useEffect} from 'react'
import _ from 'lodash'
import {Grid, Snackbar} from '@material-ui/core'
import {withRouter} from 'react-router-dom'
import {bindActionCreators} from 'redux'
import PropTypes from 'prop-types'
import {compose} from 'recompose'
import {connect, useSelector} from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'
import {Alert} from '@material-ui/lab'
import {ActionCreators} from '../../redux/actions'
import {getToken} from '../../utilities/authUtils'
import CategorySection from '../../components/surfaces/sections/products/category'
import {getLabelsBasedOnLanguage} from '../../constants/common'
import ProductCard from '../../components/surfaces/cards/product'
import ProductCart from '../../components/surfaces/productCart'
import Banner from '../../components/banner'
import * as ProductServices from '../../services/productServices'
import ProductsStyled from './products.styles'
const ProductPage = React.memo((props) => {
  const authState = useSelector((state) => state.authState)
  const paymentStatus = useSelector((state) => state.paymentState?.paymentstatus?.status)
  const [category, setCategory] = useState(0)
  const [categoryData, setCategoryData] = useState([])
  const [selectedOrders, setSelectedOrders] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(0)
  const [cartCost, setCartCost] = useState({
    totalAmount: 0,
    deliveryCost: 0,
    typeOfOrder: 0,
    paymentType: 0,
    comments: '',
    cartAddressId: null,
    otherCharges: [],
  })
  const [isLoading, setIsLoading] = useState(false)

  // Select the app State
  const productState = useSelector((state) => state.productState)
  const config = useSelector((state) => state.configState)
  const userInfoState = useSelector((state) => state.userState)

  const updateCartAddress = () => {
    if (selectedOrders.length > 0 && !cartCost.cartAddressId && userInfoState?.addresses?.data) {
      const primaryAddress = userInfoState.addresses.data.find((el) => el.is_primary === 1)
      if (primaryAddress) {
        setIsLoading(true)
        ProductServices.updateCartAddress(primaryAddress, config.language.code).then((res) => {
          if (res.status === 'success') {
            setCartCost({
              ...cartCost,
              totalAmount: parseFloat(res.total_amount),
              deliveryCost: parseFloat(res.delivery_amount),
              paymentType: parseInt(res.payment_type, 10),
              typeOfOrder: res.type_of_order,
              cartAddressId: res.address_id,
            })
          }
        }).catch((e) => {
          console.log(e.message)
        }).finally(() => {
          setIsLoading(false)
        })
      }
    }
  }

  const getCart = () => {
    setIsLoading(true)
    ProductServices.getCart(config.language.code.toLowerCase()).then((res) => {
      if (res.status === 'success') {
        const orderItems = []
        setCartCost({
          ...cartCost,
          totalAmount: parseFloat(res.total_amount),
          deliveryCost: parseFloat(res.delivery_amount),
          typeOfOrder: res.type_of_order,
          paymentType: res.payment_type || 1,
          comments: res.comments || '',
          cartAddressId: res.address_id,
          otherCharges: [{
            label: res.plastic_bag_label,
            charge: res.plastic_bag_charge ? parseFloat(res.plastic_bag_charge) : 0,
          }, {
            label: res.total_tax_label,
            charge: isNaN(res.total_tax_charge) ? 0 : parseFloat(res.total_tax_charge),
          }],
        })
        res.items.map((item) => {
          let selectedSize = null
          let selectedType = null
          if (item.category_size_price_id && item.products.sizes_price) {
            selectedSize = item.products.sizes_price.find((sizePrice) => sizePrice.id === item.category_size_price_id)
          }
          if (item.category_type_price_id && item.products.types_price) {
            selectedType = item.products.types_price.find((typePrice) => typePrice.id === item.category_type_price_id)
          }
          return orderItems.push({
            cartItemId: item.id,
            product: {
              ...item.products,
              price: item.price,
              selectedSize: selectedSize,
              selectedType: selectedType,
            },
            selected_attributes: item.selected_attributes,
            qty: item.quantity,
          })
        })
        setSelectedOrders([...orderItems])
        !res.address_id && updateCartAddress()
      }
    }).catch((e) => {
      console.log(e.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  // Once the component will mount
  useEffect(() => {
    props.actions.getCategoriesAction(config.language.code.toLowerCase())
    setCategoryData([])
    setCategory(0)
    getCart()
  }, [config.language.code])

  useEffect(() => {
    selectedOrders.length === 0 && getCart()
  }, [getToken('cart')])

  useEffect(() => {
    authState.signin.data && authState.signin.data.cart_id && getCart()
  }, [authState.signin.data])

  useEffect(() => {
    if (productState?.categories?.data?.categories) {
      setCategoryData(productState?.categories?.data?.categories)
      setCategory(selectedCategory || productState?.categories?.data?.categories[0]?.id)
    }
  }, [productState.categories.data])

  useEffect(() => {
    if (category > 0) {
      props.actions.getProductsAction(category, config.language.code.toLowerCase())
    }
  }, [category])

  useEffect(() => {
    updateCartAddress()
  }, [userInfoState.addresses])

  const onCategorySelection = (id) => {
    setSelectedCategory(id)
    setCategory(id)
  }
  const onOrderClick = (product) => {
    setSelectedOrders([...selectedOrders, {
      ...product,
      attributeGroups: productState.products.data.attributeGroups,
      isNew: true,
      qty: 1,
    }])
  }
  const onOrderDelete = (cartItemId, updatedCartCost) => {
    const productsList = [...selectedOrders]
    const selectedProductIndex = productsList.findIndex((prod) => prod.cartItemId === cartItemId)
    if (selectedProductIndex > -1) {
      productsList.splice(selectedProductIndex, 1)
      setSelectedOrders(productsList)
    }
    setCartCost({
      ...cartCost,
      ...updatedCartCost,
    })
  }
  const onNewItemAdded = (product) => {
    const productsList = [...selectedOrders]
    const selectedProductIndex = productsList.findIndex((prod) => prod.isNew === true)
    if (selectedProductIndex > -1) {
      setCartCost({
        ...cartCost,
        ...product.cartCost,
      })
      productsList.splice(selectedProductIndex, 1)
      const attributes = []
      product.attributes.map((attribute) => {
        const productsAttributeGroups = product.product.attributeGroups
        productsAttributeGroups.length !== 0 && productsAttributeGroups.map((attributeGroup) => {
          attributeGroup.attributes && attributeGroup.attributes.length && attributeGroup.attributes.map((productAttribute) => {
            if (productAttribute.attribute_id.toString() === attribute.category_arttibutes_id.toString()) {
              attributes.push(productAttribute)
            }
            return productAttribute
          })
          return attributeGroup
        })
        return attributes
      })
      setSelectedOrders([...productsList, {
        cartItemId: product.cartItemId,
        product: {
          ...product.product,
          selectedIndex: null,
        },
        selected_attributes: attributes,
        qty: product.qty,
      }])
    }
  }
  const onItemUpdate = (product) => {
    const productsList = [...selectedOrders]
    const selectedProductIndex = productsList.findIndex((prod) => prod.cartItemId === product.cartItemId)
    if (selectedProductIndex > -1) {
      const attributes = []
      product.attributes.map((attribute) => {
        const productsAttributeGroups = productsList[selectedProductIndex].product.attributeGroups
        productsAttributeGroups.length !== 0 && productsAttributeGroups.map((attributeGroup) => {
          attributeGroup.attributes && attributeGroup.attributes.length && attributeGroup.attributes.map((productAttribute) => {
            if (productAttribute.attribute_id.toString() === attribute.category_arttibutes_id.toString()) {
              attributes.push(productAttribute)
            }
            return productAttribute
          })
          return attributeGroup
        })
        return attributes
      })
      productsList[selectedProductIndex].qty = product.qty
      productsList[selectedProductIndex].selected_attributes = attributes
      productsList[selectedProductIndex].product = {
        ...productsList[selectedProductIndex].product,
        price: product.price,
      }
      setSelectedOrders([...productsList])
      setCartCost({
        ...cartCost,
        ...product.cartCost,
      })
    }
  }
  const updateDeliveryOption = (product) => {
    setCartCost({
      ...cartCost,
      ...product.cartCost,
    })
  }

  const onCartAddressUpdate = (product) => {
    setCartCost({
      ...cartCost,
      ...product.cartCost,
    })
  }

  const onCommentUpdate = (comment) => {
    setCartCost({
      ...cartCost,
      comments: comment,
    })
  }
  const onClearCart = () => {
    setSelectedOrders([])
    setCartCost({
      totalAmount: 0,
      deliveryCost: 0,
      typeOfOrder: 0,
      paymentType: 0,
      comments: '',
      cartAddressId: null,
      otherCharges: [],
    })
  }
  const closeSnackbar = () => {
    props.actions.cleanUpPaymentStatus()
  }
  const onCancelNewItem = () => {
    const productsList = [...selectedOrders]
    const selectedProductIndex = productsList.findIndex((prod) => prod.isNew === true)
    if (selectedProductIndex > -1) {
      productsList.splice(selectedProductIndex, 1)
      setSelectedOrders([...productsList])
    }
  }
  return (
    <ProductsStyled>
      {isLoading || productState?.products?.loading ? <div className="spinner">
        <CircularProgress style={{height: '40px', width: '40px', zIndex: 1000, position: 'absolute', top: '50%'}} />
      </div> : null
      }
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        open={paymentStatus} autoHideDuration={6000} onClose={closeSnackbar}
      >
        <Alert onClose={closeSnackbar} severity={paymentStatus}>
          {getLabelsBasedOnLanguage('paymentStatusCheck.error', props.code)}
        </Alert>
      </Snackbar>
      <Banner />
      <CategorySection
        categories={categoryData}
        theme={props.theme}
        selected={category}
        OnSelection={onCategorySelection}
        code={config.language.code}
      />
      <div style={{padding: '20px'}}>
        <Grid container className="product-card-list" spacing={2}>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Grid container spacing={2}>
              {productState.products && productState.products.data && _.map(productState.products.data.products, (models, index) => {
                return (
                  <Grid item xs={12} sm={6} md={6} lg={4} key={`product_cart_${index}`}>
                    <ProductCard
                      key={index}
                      product={models}
                      typePrices={models.types_price}
                      sizePrices={models.sizes_price}
                      code={config.language.code}
                      onOrderClick={onOrderClick}
                    />
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            {
              <ProductCart products={selectedOrders} onOrderDelete={onOrderDelete} cartCost={cartCost}
                onNewItemAdded={onNewItemAdded} onClearCart={onClearCart} onItemUpdate={onItemUpdate}
                updateDeliveryOption={updateDeliveryOption} onCommentUpdate={onCommentUpdate}
                onCartAddressUpdate={onCartAddressUpdate} code={config.language.code} onCancelNewItem={onCancelNewItem}
              />
            }
          </Grid>
        </Grid>
      </div>
    </ProductsStyled>
  )
})
ProductPage.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
}

const mapDispatchToProps = (dispath) => ({
  actions: bindActionCreators(ActionCreators, dispath),
})

export default React.memo(compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(ProductPage))
