import React, {useState, useEffect} from 'react'
import {useSelector, connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {compose} from 'recompose'
import {withRouter} from 'react-router-dom'
import {CssBaseline, Container, makeStyles} from '@material-ui/core'
import Header from '../../../components/surfaces/header'
import Footer from '../../../components/surfaces/footer'
import AuthenticationModal from '../../../views/Authentication'
import {fetchErrorMsg} from '../../../constants/common'
import SnackbarComponent from '../../../components/feedback/snackbar/snackbar'
import {ActionCreators} from '../../../redux/actions'
import DefaultStyled from './default.styles'


const useStyles = makeStyles((theme) => ({

  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: theme.background.paper,
  },

  main: {
    paddingBottom: theme.spacing(6),
  },

}))

const AppLayout = (props) => {
  const classes = useStyles()
  const config = useSelector((state) => state.configState)
  const authState = useSelector((state) => state.authState)
  const masterState = useSelector((state) => state.masterState)
  const [isAuthModal, setAuthModal] = useState(false)
  const [user, setUser] = useState(null)
  const [lang, setLang] = useState('EN')
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')

  // Start Sign in //
  useEffect(() => {
    if (authState?.signin?.data) {
      setMessage(authState.signin.data.message)
      setStatus('success')
    }
    if (authState?.signin?.error?.message !== 'Invalid request.' && authState?.signin?.error?.status === 'failure') {
      let msg = fetchErrorMsg(authState.signin.error?.message)
      setMessage(msg)
      setStatus('error')
    }
  }, [authState.signin])
  // End Sign in //

  // Start Sign up //
  useEffect(() => {
    if (authState?.signup?.data) {
      setMessage(authState.signup.data.message)
      setStatus('success')
    }
    if (authState?.signup?.error?.message !== 'Invalid request.' && authState?.signup?.error?.status === 'failure') {
      let msg = fetchErrorMsg(authState.signup.error?.message)
      setMessage(msg)
      setStatus('error')
    }
  }, [authState.signup])
  // End Sign up //

  // Start Verify Otp //
  useEffect(() => {
    if (authState?.verify_otp_request.data) {
      setMessage(authState.verify_otp_request.data)
      setStatus('success')
    }
    if (authState?.verify_otp_request?.error?.message !== 'Invalid request.' && authState?.verify_otp_request?.error?.status === 'failure') {
      let msg = fetchErrorMsg(authState.verify_otp_request.error?.message)
      setMessage(msg)
      setStatus('error')
    }
  }, [authState.verify_otp_request])
  // End Verify Otp //

  // Start Forgot Password//
  useEffect(() => {
    if (authState?.forgotPassword?.data) {
      setMessage(authState.forgotPassword.data)
      setStatus('success')
    }
    if (authState?.forgotPassword?.error?.message !== 'Invalid request.' && authState?.forgotPassword?.error?.status === 'failure') {
      let msg = fetchErrorMsg(authState.forgotPassword.error?.message)
      setMessage(msg)
      setStatus('error')
    }
  }, [authState.forgotPassword])
  // End Forgot Password//

  // Start Change Pasword//
  useEffect(() => {
    if (authState?.changePassword?.data) {
      setMessage(authState.changePassword.data)
      setStatus('success')
    }
    if (authState?.changePassword?.error?.message !== 'Invalid request.' && authState?.changePassword?.error?.status === 'failure') {
      let msg = fetchErrorMsg(authState.changePassword.error?.message)
      setMessage(msg)
      setStatus('error')
    }
  }, [authState.changePassword])
  // End Change Pasword//

  const handleCleanUp = () => {
    setTimeout(() => {
      setMessage('')
      setStatus('')
    }, 2500)
    props.actions.cleanUpState()
  }
  const handleSnackBarClose = () => {
    setTimeout(() => {
      setMessage('')
      setStatus('')
    }, 2500)
  }
  const handleLoginBtnClick = (val) => {
    setAuthModal(val)
  }

  const handleLanguageChange = (selectedLanguage) => {
    props.actions.setLanguageAction(selectedLanguage, authState.session.data, config.language.code)
  }

  const handleLogOut = () => {
    setUser('')
    props.actions.logout()
  }
  useEffect(() => {
    props.actions.checkSession(config.language.code)
    props.actions.getCountryList(config.language.code)
    props.actions.getCityList(config.language.code)
    props.actions.getPostalCodes(config.language.code)
    props.actions.getLanguagesList()
    props.actions.getRestroTimings(config.language.code)
  }, [])
  useEffect(() => {
    if (config.language.code) {
      setLang(config.language.code)
    }
  }, [config.language])
  useEffect(() => {
    if (authState?.session?.data) {
      setUser(authState.session.data)
    }
  }, [authState.session])
  useEffect(() => {
    if (masterState.languages.data && authState?.session?.data && !authState?.session?.loading) {
      if (authState?.session?.data.language_id) {
        const selectedLangObj = masterState.languages.data.find((lang) => lang.id === authState?.session?.data.language_id)
        if (selectedLangObj) {
          props.actions.setLanguageAction(selectedLangObj, null, config.language.code)
        }
      }
    }
  }, [authState.session, masterState.languages])
  const AuthModel = () => {
    return (
      <AuthenticationModal
        open={isAuthModal}
        close={handleLoginBtnClick}
        message={message}
        status={status}
        handleCleanUp={handleCleanUp}
        handleSnackBarClose={handleSnackBarClose}
      />
    )
  }

  return (
    <DefaultStyled className={classes.root}>
      {isAuthModal && AuthModel()}
      <Header
        position="static"
        login={handleLoginBtnClick}
        langcode={lang}
        changelang={handleLanguageChange}
        config={config.language}
        user={user}
        logout={handleLogOut}
      />
      <CssBaseline />
      <Container maxWidth={false} disableGutters className={classes.main} >
        {/* <Banner /> */}
        {props.children}
      </Container>
      <SnackbarComponent
        message={message}
        severity={status}
        duration={2500}
        open={message.length > 0}
        close={handleSnackBarClose}
      />
      <Footer config={config.language} />
    </DefaultStyled>
  )
}

const mapDispatchToProps = (dispath) => ({
  actions: bindActionCreators(ActionCreators, dispath),
})

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(AppLayout)

