import React, {useState, useEffect} from 'react'
import {compose} from 'recompose'
import {connect, useSelector} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {bindActionCreators} from 'redux'
import PropTypes from 'prop-types'
import {Dialog, DialogContent, DialogTitle, IconButton, makeStyles} from '@material-ui/core'
import {Close} from '@material-ui/icons'
import SignInForm from '../../components/forms/signin'
import SignUpForm from '../../components/forms/signup'
import FogotPasswordForm from '../../components/forms/forgotpassword'
import VerifyOtpForm from '../../components/forms/verifyotp'
import UpdatePasswordForm from '../../components/forms/updatepassword'
import {ActionCreators} from '../../redux/actions'
import {ErrorTimeOut} from '../../constants/constant'
import AuthModal from './authmodal.styles'
const useStyles = makeStyles((theme) => ({
  closeBtn: {
    margin: '-16px -24px 0 0',
    float: 'right',
  },
}),
)


const AuthenticationModal = (props) => {
  const {open} = props
  const classes = useStyles()
  const [view, setView] = useState(0)
  const authState = useSelector((state) => state.authState)
  const config = useSelector((state) => state.configState)

  useEffect(() => {
    return () => {
      props.actions.cleanUpState()
    }
  }, [])

  // Start Sign in //
  useEffect(() => {
    if (authState?.signin?.data) {
      setTimeout(() => {
        props.close(false)
      }, 500)
    }
  }, [authState.signin])
  // End Sign in //

  // Start Sign up //
  useEffect(() => {
    if (authState?.signup?.data) {
      setTimeout(() => {
        setView(3)
      }, ErrorTimeOut)
    }
  }, [authState.signup])
  // End Sign up //

  // Start Verify Otp //
  useEffect(() => {
    if (authState?.verify_otp_request?.data) {
      if (authState.validation.type === 1) {
        setTimeout(() => {
          setView(0)
        }, ErrorTimeOut)
      } else if (authState.validation.type === 0) {
        setTimeout(() => {
          setView(4)
        }, ErrorTimeOut)
      }
    }
  }, [authState.verify_otp_request])
  // End Verify Otp //

  // Start Forgot Password//
  useEffect(() => {
    if (authState?.forgotPassword?.data) {
      setTimeout(() => {
        setView(3)
      }, ErrorTimeOut)
    }
  }, [authState.forgotPassword])
  // End Forgot Password//

  // Start Change Pasword//
  useEffect(() => {
    if (authState?.changePassword?.data) {
      setTimeout(() => {
        setView(0)
      }, ErrorTimeOut)
    }
  }, [authState.changePassword])
  // End Change Pasword//

  const handleClose = () => {
    setView(0)
    props.close(false)
  }

  const handleView = (val) => {
    setView(val)
  }

  const handleUserSignIn = (form) => {
    props.actions.signInAction(form.phone, form.password, config.language.code)
  }

  const handleUserSignUp = (form) => {
    props.actions.signUpAction(form, config.language.code)
  }

  const handleForgotPassword = (form) => {
    props.actions.forgotPassword(form, config.language.code)
  }

  const handleOtpVerification = (otp) => {
    props.actions.verifyOtpAction(otp, authState.validation.type, config.language.code)
  }

  const handleChangePassword = (form) => {
    let type = 0
    props.actions.changePassword(form, type, config.language.code)
  }
  const selectView = () => {
    switch (view) {
      case 0:
        return (
          <SignInForm
            view={handleView}
            submit={handleUserSignIn}
            code={config.language.code}
            msg={props.message}
            status={props.status}
            closeMsg={props.handleSnackBarClose}
            cleanUp={props.handleCleanUp}
            isLoading={authState?.signin?.loading}
            error={authState?.signin?.error?.errors}
          />
        )
      case 1:
        return (
          <SignUpForm
            view={handleView}
            submit={handleUserSignUp}
            close={handleClose}
            code={config.language.code}
            msg={props.message}
            status={props.status}
            closeMsg={props.handleSnackBarClose}
            cleanUp={props.handleCleanUp}
            error={authState?.signup?.error?.errors}
            isLoading={authState?.signup?.loading}
          />
        )
      case 2:
        return (
          <FogotPasswordForm
            view={handleView}
            submit={handleForgotPassword}
            code={config.language.code}
            msg={props.message}
            status={props.status}
            closeMsg={props.handleSnackBarClose}
            cleanUp={props.handleCleanUp}
            error={authState?.forgotPassword?.error?.errors}
            isLoading={authState?.forgotPassword?.loading}
          />
        )
      case 3:
        return (
          <VerifyOtpForm
            view={handleView}
            submit={handleOtpVerification}
            code={config.language.code}
            msg={props.message}
            status={props.status}
            closeMsg={props.handleSnackBarClose}
            cleanUp={props.handleCleanUp}
            error={authState?.verify_otp_request?.error?.errors}
            isLoading={authState?.verify_otp_request?.loading}
          />
        )
      case 4:
        return (
          <UpdatePasswordForm
            view={handleView}
            submit={handleChangePassword}
            code={config.language.code}
            msg={props.message}
            status={props.status}
            closeMsg={props.handleSnackBarClose}
            cleanUp={props.handleCleanUp}
            error={authState?.changePassword?.error?.errors}
            isLoading={authState?.changePassword?.loading}
          />
        )
      default:
        return (
          <div>Login Form</div>
        )
    }
  }


  return (
    <AuthModal>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
      >
        <DialogTitle>
          <IconButton className={classes.closeBtn} onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {selectView()}
        </DialogContent>
      </Dialog>
    </AuthModal>
  )
}

AuthenticationModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
}

const mapDispatchToProps = (dispath) => ({
  actions: bindActionCreators(ActionCreators, dispath),
})

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(AuthenticationModal)
