import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import {InputBase, InputLabel} from '@material-ui/core'


const useStyles = makeStyles((theme) => ({
  root: {
    padding: '5px 5px',
    display: 'flex',
    alignItems: 'center',
    width: 'inherit',
    margin: '5px 0px 10px 0px',
    borderRadius: '3px',
    border: '2px solid #c4c4c4ba',
    boxShadow: 'none',
  },
  input: {
    flex: 1,
    width: '92%',
  },
  icons: {
    float: 'right',
    display: 'inline-block',
    width: '8%',
    marginTop: '10px',
  },
}))

const CustomInput = (props) => {
  const classes = useStyles()

  return (
    <>
      <div>
        {props.label && <InputLabel>
          {props.label}
        </InputLabel>}
        <div className={classes.root}>

          <InputBase
            className={classes.input}
            onChange={props.onChange}
            error={props.error}
            success={props.success}
            {...props}
          />
          {props && props.icon &&
          <div className={classes.icons}>
            {props.icon}
          </div>}
        </div>
      </div>
    </>
  )
}

CustomInput.propTypes = {
  placeholder: PropTypes.string,
  icon: PropTypes.object,
  onChange: PropTypes.func,
  type: PropTypes.string,
  error: PropTypes.string,
}

export default (CustomInput)

