import React, {useState} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {Button, Menu, MenuItem} from '@material-ui/core'
import StyledLanguageComponent from './language.styles'

const LanguageComponent = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelection = (selectedLanguage) => {
    props.click(selectedLanguage)
    handleClose()
  }

  return (
    <StyledLanguageComponent>
      <Button className="lang_btn" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <span className="lang_label">{props.selected ? props.selected.toUpperCase() : ''}</span>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {_.map(props.languages, (model, index) => {
          return (
            <MenuItem key={index} onClick={() => handleSelection(model)}>{model.lang_code.toUpperCase() === 'EN' ? 'English' : 'Ελληνικά'}</MenuItem>
          )
        })}
      </Menu>
    </StyledLanguageComponent>
  )
}

LanguageComponent.propTypes = {
  selected: PropTypes.string,
  languages: PropTypes.array,
  click: PropTypes.func,
}

export default LanguageComponent

