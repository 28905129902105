/* eslint-disable import/namespace */
import * as userServices from '../../services/userServices'
import * as actionTypes from './actionsType'

const getAddressRequest = () => {
  return {
    type: actionTypes.USER_ADDRESS_GET_REQUEST,
  }
}

export const getAddressSuccess = (address) => {
  return {
    type: actionTypes.USER_ADDRESS_GET_SUCCESS,
    payload: address,
  }
}

const getAddressFailure = (error) => {
  return {
    type: actionTypes.USER_ADDRESS_GET_FAILURE,
    payload: error,
  }
}

const createAddressRequest = () => {
  return {
    type: actionTypes.USER_ADDRESS_CREATE_REQUEST,
  }
}

const createAddressSuccess = (address) => {
  return {
    type: actionTypes.USER_ADDRESS_CREATE_SUCCESS,
    payload: address,
  }
}

const createAddressFailure = (error) => {
  return {
    type: actionTypes.USER_ADDRESS_CREATE_FAILURE,
    payload: error,
  }
}

const updateAddressRequest = () => {
  return {
    type: actionTypes.USER_ADDRESS_UPDATE_REQUEST,
  }
}

const updateAddressSuccess = (address) => {
  return {
    type: actionTypes.USER_ADDRESS_UPDATE_SUCCESS,
    payload: address,
  }
}

const updateAddressFailure = (error) => {
  return {
    type: actionTypes.USER_ADDRESS_UPDATE_FAILURE,
    payload: error,
  }
}

const updateLanguageRequest = () => {
  return {
    type: actionTypes.USER_LANGUAGE_UPDATE_REQUEST,
  }
}

const updateLanguageSuccess = (address) => {
  return {
    type: actionTypes.USER_LANGUAGE_UPDATE_SUCCESS,
    payload: address,
  }
}

const updateLanguageFailure = (error) => {
  return {
    type: actionTypes.USER_LANGUAGE_UPDATE_FAILURE,
    payload: error,
  }
}

export const getUserAddressAction = (lang) =>
  async(dispatch) => {
    dispatch(getAddressRequest())
    try {
      const responseData = await userServices.getAddresses(lang)
      if (responseData?.status?.toUpperCase() === 'SUCCESS') {
        dispatch(getAddressSuccess(responseData.user))
      } else {
        dispatch(getAddressFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(getAddressFailure(error))
    }
  }

export const createUserAddressAction = (address, lang) =>
  async(dispatch) => {
    dispatch(createAddressRequest())
    try {
      const responseData = await userServices.createAddress(address, lang)
      if (responseData?.status?.toUpperCase() === 'SUCCESS') {
        dispatch(createAddressSuccess(responseData.user))
      } else {
        dispatch(createAddressFailure(responseData))
      }
    } catch (error) {
      dispatch(createAddressFailure(error))
    }
  }

export const updateUserAddressAction = (addressData, lang) =>
  async(dispatch) => {
    dispatch(updateAddressRequest())
    try {
      const responseData = await userServices.updateAddress(addressData, lang)
      if (responseData?.status?.toUpperCase() === 'SUCCESS') {
        dispatch(updateAddressSuccess(addressData)) //NEED TO CHANGE response data
      } else {
        dispatch(updateAddressFailure(responseData))
      }
    } catch (error) {
      dispatch(updateAddressFailure(error))
    }
  }

export const makeAddressPrimary = (address, lang) =>
  async(dispatch) => {
    dispatch(updateAddressRequest())
    try {
      const responseData = await userServices.makeAddressPrimary(address, lang)
      if (responseData?.status?.toUpperCase() === 'SUCCESS') {
        dispatch(updateAddressSuccess(responseData)) //NEED TO CHANGE response data
      } else {
        dispatch(updateAddressFailure(responseData.message))
      }
    } catch (error) {
      dispatch(updateAddressFailure(error))
    }
  }

export const setUserLanguage = (languageId, lang) =>
  async(dispatch) => {
    dispatch(updateLanguageRequest())
    try {
      const responseData = await userServices.updateUserLanguage(languageId, lang)
      if (responseData?.status?.toUpperCase() === 'SUCCESS') {
        dispatch(updateLanguageSuccess(languageId))
      } else {
        dispatch(updateLanguageFailure(responseData.message))
      }
    } catch (error) {
      dispatch(updateLanguageFailure(error))
    }
  }
