import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {Grid, Typography} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import CustomInput from '../../inputs/textbox'
import CustomButton from '../../inputs/button'
import {getLabelsBasedOnLanguage} from '../../../constants/common'
// import SnackbarComponent from '../../../components/feedback/snackbar/snackbar'
import ForgotPasswordFormStyled from './forgotpassword.styles'


const FogotPasswordForm = (props) => {
  const {code} = props
  const [form, setForm] = useState({
    phone: '',
  })
  useEffect(() => {
    return () => {
      props.cleanUp()
    }
  }, [])
  const handleChange = (e) => {
    let data = {
      ...form,
      [e.target.name]: e.target.value,
    }
    setForm(data)
  }

  // const changeView = (n) => {
  //   props.view(n)
  // }

  const handleSignIn = () => {
    props.submit(form)
  }

  return (
    <ForgotPasswordFormStyled>
      <form>
        {/* <SnackbarComponent
          message={msg}
          severity={status}
          duration={1000}
          open={msg.length > 0}
          close={props.closeMsg}
        /> */}
        {props.isLoading ? <div className="spinner">
          <CircularProgress style={{height: '40px', width: '40px', position: 'fixed', top: '50%', left: '49%'}} />
        </div> : null
        }
        <Grid container>
          <Grid item lg={1} md={1} sm={1} xs={1} />
          <Grid item lg={10} md={10} sm={10} xs={10}>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12} className="center_element">
                <Typography
                  variant="subtitle1"
                >
                  {getLabelsBasedOnLanguage('forgotpassword.subtitle', code)}
                </Typography>
                <br />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <CustomInput
                  label={`${getLabelsBasedOnLanguage('inputs.labels.phone', code)}*`}
                  type="number"
                  name="phone"
                  value={form.phone}
                  multiple={false}
                  onChange={handleChange}
                  error={null}
                />
                {props.error && <span className="error">{props.error.phone}</span>}
              </Grid>
              {props.validationHandling && <span>{props.validationHandling.phone}</span>}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <CustomButton
                  label={getLabelsBasedOnLanguage('inputs.buttons.send', code)}
                  onClick={handleSignIn}
                  size="large"
                  color="primary"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={1} />
        </Grid>
      </form>
    </ForgotPasswordFormStyled>
  )
}

FogotPasswordForm.propTypes = {
  // view: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  code: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  msg: PropTypes.string.isRequired,
  cleanUp: PropTypes.func.isRequired,
  closeMsg: PropTypes.func.isRequired,
}

export default FogotPasswordForm
