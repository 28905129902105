import React from 'react'
import {useTheme} from '@material-ui/core'
import './App.css'
import Routes from './Routes'

const App = () => {
  const theme = useTheme()
  return (
    <div className="App">
      <Routes theme={theme} />
      {/* {Routes} */}
    </div>
  )
}

export default App
