import styled from 'styled-components'

const ProductCardStyled = styled.div `
    height: 100%;
    .product {
        padding: 17px;
        height: 100%;
        font-size: 0.80rem;
        border-radius: 20px;
        box-shadow: rgb(0 0 0 / 16%) 0px 3px 24px;
        background-color: #F9F9F9;
    }
    .prod_image {
        // padding: 10px;
        // border: 5px solid ${(props) => props.theme.palette.primary.main};
        padding: 17px;
       height: 196px;
       width: 100%;
    }

    .prod_image img {
       height: inherit;
       width: inherit;
    }

    .prod_name {
        margin-top: 17px;
        font-weight: 700;
        font-size: 1rem;
        padding: 10px;
    }

    .label {
        font-weight: 700;
    }

    .req{
        color: #FF0000;
    }
    .descOnly{
        text-align: center!important;
        font-size: 16px!important;
    }
    .prod_details > div{
        text-align: left;
        padding: 10px 0px;
        font-size: 0.80rem;
    }

    .prod_attribute > div {
        text-align: left;
        font-size: 0.70rem;
    }

    .prod_attribute .label {
        display: inline-block;
    }
    .prod_order{
        padding: 23px 0px 5px 0px;
    }
`
export default ProductCardStyled
