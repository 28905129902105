import axios from 'axios'
import {getToken} from '../../utilities/authUtils'
const {REACT_APP_API_URL} = process.env

const apiInstance = axios.create({
  baseURL: REACT_APP_API_URL,
  crossDomain: false,
})

apiInstance.interceptors.request.use((config) => {
  const token = getToken('token')
  config.headers.Authorization = token ? `Bearer ${token}` : ''
  return config
})

// Add a response interceptor
apiInstance.interceptors.response.use((response) => {
  let responseData = {}
  if (response.status && response.status === 200) {
    const data = Array.isArray(response.data.payload) ? {
      data: response.data.payload,
    } : {
      ...response.data.payload,
    }
    responseData = {
      ...response.data.meta,
      ...data,
    }
  }
  return responseData
}, (error) => {
  let errorData = {}
  errorData = {
    ...error.response.data.meta,
    errors: error.response.data.payload,
  }
  return errorData
})

export default apiInstance
