import React from 'react'
import {NavLink} from 'react-router-dom'
import * as routesNames from '../../../constants/routes'
import {getLabelsBasedOnLanguage} from '../../../constants/common'
import FooterStyled from './footer.styles'
export default function Footer(props) {
  const {config} = props
  return (
    <FooterStyled>
      <span className="fTitle">
        Gia souvlaki etsi apla, all rights reserved
      </span>
      <span className="links">
        <NavLink to={routesNames.TERMS_AND_CONDITIONS} style={{textDecoration: 'none', padding: '6px 16px', color: 'inherit'}}
          activeStyle={{color: 'inherit'}}
        >
          {getLabelsBasedOnLanguage('termsOfUse', config.code)}
        </NavLink>
      </span>
      <span className="links">
        <NavLink to={routesNames.PRIVACY_POLICY} style={{textDecoration: 'none', padding: '6px 16px', color: 'inherit'}}
          activeStyle={{color: 'inherit'}}
        >
          {getLabelsBasedOnLanguage('privacyPolicy', config.code)}
        </NavLink>
      </span>
    </FooterStyled >
  )
}
