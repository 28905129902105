export const fullPageView = ['/signin', '/signup', '/forget-password']

export const openSecurityPages = ['/signin', '/signup', '/forget-password']

export const MonthConstants = [
  {id: 1, name: 'Jan'},
  {id: 2, name: 'Feb'},
  {id: 3, name: 'Mar'},
  {id: 4, name: 'Apr'},
  {id: 5, name: 'May'},
  {id: 6, name: 'Jun'},
  {id: 7, name: 'Jul'},
  {id: 8, name: 'Aug'},
  {id: 9, name: 'Sep'},
  {id: 10, name: 'Oct'},
  {id: 11, name: 'Nov'},
  {id: 12, name: 'Dev'},
]

export const TitleConstants = [
  {id: 1, name: 'Mr.'},
  {id: 2, name: 'Ms.'},
  {id: 3, name: 'Mrs.'},
]
export const CountriesConstants = [
  {id: 1, name: 'India'},
  {id: 2, name: 'UK'},
  {id: 3, name: 'USA'},
]

export const CardTypeConstant = [
  {id: 1, name: 'Visa Credit'},
  {id: 2, name: 'MasterCard'},
]

export const Menus = [
  {name: 'Profile'},
  {name: 'LogOut'},
]

export const TimeZones = [
  {
    id: 1,
    name: 'UTC 10:00, Athens',
  },
  {
    id: 2,
    name: 'IST 15:30, Kolkatta',
  },
]

export const Titles = [
  {
    id: 'Mr',
    name: 'Mr.',
  },
  {
    id: 'Miss',
    name: 'Miss.',
  },
  {
    id: 'Mrs',
    name: 'Mrs.',
  },
]

export const Days = [
  {
    id: '1',
    name: '1',
  },
  {
    id: '2',
    name: '2',
  },
  {
    id: '3',
    name: '3',
  },
  {
    id: '4',
    name: '4',
  },
  {
    id: '5',
    name: '5',
  },
  {
    id: '6',
    name: '6',
  },
  {
    id: '7',
    name: '7',
  },
  {
    id: '8',
    name: '8',
  },
  {
    id: '9',
    name: '9',
  },
  {
    id: '10',
    name: '10',
  },
  {
    id: '11',
    name: '11',
  },
  {
    id: '12',
    name: '12',
  },
  {
    id: '13',
    name: '13',
  },
  {
    id: '14',
    name: '14',
  },
  {
    id: '15',
    name: '15',
  },
  {
    id: '16',
    name: '16',
  },
  {
    id: '17',
    name: '17',
  },
  {
    id: '18',
    name: '18',
  },
  {
    id: '19',
    name: '19',
  },
  {
    id: '20',
    name: '20',
  },
  {
    id: '21',
    name: '21',
  },
  {
    id: '22',
    name: '22',
  },
  {
    id: '23',
    name: '23',
  },
  {
    id: '24',
    name: '24',
  },
  {
    id: '25',
    name: '25',
  },
  {
    id: '26',
    name: '26',
  },
  {
    id: '27',
    name: '27',
  },
  {
    id: '28',
    name: '28',
  },
  {
    id: '29',
    name: '29',
  },
  {
    id: '30',
    name: '30',
  },
  {
    id: '31',
    name: '31',
  },
]

export const Hours = [
  {
    id: '1',
    name: '1',
  },
  {
    id: '2',
    name: '2',
  },
  {
    id: '3',
    name: '3',
  },
  {
    id: '4',
    name: '4',
  },
  {
    id: '5',
    name: '5',
  },
  {
    id: '6',
    name: '6',
  },
  {
    id: '7',
    name: '7',
  },
  {
    id: '8',
    name: '8',
  },
  {
    id: '9',
    name: '9',
  },
  {
    id: '10',
    name: '10',
  },
  {
    id: '11',
    name: '11',
  },
  {
    id: '12',
    name: '12',
  },
  {
    id: '13',
    name: '13',
  },
  {
    id: '14',
    name: '14',
  },
  {
    id: '15',
    name: '15',
  },
  {
    id: '16',
    name: '16',
  },
  {
    id: '17',
    name: '17',
  },
  {
    id: '18',
    name: '18',
  },
  {
    id: '19',
    name: '19',
  },
  {
    id: '20',
    name: '20',
  },
  {
    id: '21',
    name: '21',
  },
  {
    id: '22',
    name: '22',
  },
  {
    id: '23',
    name: '23',
  },
  {
    id: '24',
    name: '24',
  },
]

export const EventStatus = [
  {id: 'all', name: 'ALL'},
  {id: 'draft', name: 'Draft'},
  {id: 'publish', name: 'Published'},
  {id: 'cancel', name: 'Cancelled'},
]

export const EventDashboardData = [
  {
    id: 1,
    title: 'Live',
    value: 200,
  },
  {
    id: 2,
    title: 'Draft',
    value: 23,
  },
  {
    id: 3,
    title: 'Past',
    value: 70,
  },
  {
    id: 4,
    title: 'Monthly Sale',
    value: 20450,
  },
]

export const currencies = [
  {
    id: 'USD',
    name: 'Dollar',
  },
  {
    id: 'Euro',
    name: 'Euro',
  },
  {
    id: 'Rupee',
    name: 'Rupee',
  },
  {
    id: 'Dinar',
    name: 'Dinar',
  },
]

export const discountType = [
  {
    id: 'percent',
    name: 'Percentage',
  },
  {
    id: 'fixed',
    name: 'Fixed',
  },
]

export const TabList = ['Overview', 'Tickets']

export const ErrorTimeOut = 3000
