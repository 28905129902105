import React, {useState, useEffect} from 'react'
import Proptypes from 'prop-types'
import {Card, Snackbar} from '@material-ui/core'
import {Alert} from '@material-ui/lab'
// import _ from 'lodash'
import CustomDivider from '../../../divider'
import CustomToggleButton from '../../../lab/togglebutton'
import CustomToggleButtonType from '../../../lab/togglebuttontype'
import {getLabelsBasedOnLanguage} from '../../../../constants/common'
// import CustomButton  from '../../../inputs/button'
import StyledToggleButton from '../../../lab/togglebutton/togglebutton.styles'
import ProductCardStyled from './productcard.styles'


const ProductCard = React.memo((props) => {

  const {product, code} = props
  const [selectedType, setSelectedType] = useState({price: 0})
  const [selectedSize, setSelectedSize] = useState({price: 0})
  const [showMessage, setShowMessage] = React.useState({
    message: '',
    type: 'success',
    open: false,
  })
  // const [price, setPrice] = useState('')
  const typeArr = props.typePrices && props.typePrices.map((typesPrice, index) => {
    return {
      type: typesPrice.type,
      type_id: typesPrice.type_id,
      category_id: typesPrice.category_id,
      price: typesPrice.price,
      attributes: typesPrice.attributes,
      included_fries: typesPrice.included_fries,
    }
  })
  const sizeArr = props.sizePrices && props.sizePrices.map((sizesPrice, index) => {
    return {
      size: sizesPrice.size,
      size_id: sizesPrice.size_id,
      product_id: product.id,
      price: sizesPrice.price,
    }
  })
  const setDefaultSizeAndPrice = () => {
    if (typeArr && typeArr.length === 1) {
      setSelectedType(typeArr[0])
    } else {
      setSelectedType({price: 0})
    }
    if (sizeArr && sizeArr.length === 1) {
      setSelectedSize(sizeArr[0])
    } else {
      setSelectedSize({price: 0})
    }

  }
  useEffect(() => {
    setDefaultSizeAndPrice()
  }, [])
  const handleTypeChange = (type) => {
    setSelectedType(type)
  }

  const handleSizeChange = (size) => {
    setSelectedSize(size)
  }
  const handleCloseAlert = () => {
    setShowMessage({
      ...showMessage,
      open: false,
    })
  }
  const onOrderClick = () => {
    if (
      (typeArr && typeArr.length && !selectedType.type_id)
      ||
      (sizeArr && sizeArr.length && !selectedSize.size_id)
    ) {
      let msg = ''
      if (typeArr && typeArr.length && !selectedType.type_id) {
        msg = 'Type'
      }
      if (sizeArr && sizeArr.length && !selectedSize.size_id) {
        msg += msg.length ? ' and  Size' : 'Size'
      }
      setShowMessage({
        type: 'error',
        open: true,
        message: `Please select ${msg}.`,
      })
      return
    }
    props.onOrderClick({
      product,
      selectedType,
      selectedSize,
    })
    setDefaultSizeAndPrice()
  }
  return (
    <ProductCardStyled>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        open={showMessage.open} autoHideDuration={6000} onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity={showMessage.type}>
          {showMessage.message}
        </Alert>
      </Snackbar>
      <Card className="product">
        <div className="prod_image">
          <img src={product.fullUrl} alt={'Product'} />
        </div>
        <div className="prod_name">{product.name}</div>
        <div className="prod_details">
          {product.description &&
            <div className={product.ingredients ? '' : 'descOnly'}>
              {
                product.ingredients ? <div className="label">
                  {getLabelsBasedOnLanguage('product.description', code)} :
                </div> : null
              }
              {product.description}
            </div>
          }
          {product.ingredients &&
            <div>
              <div className="label">{getLabelsBasedOnLanguage('product.ingredients', code)}:</div>
              {product.ingredients}
            </div>
          }
        </div>
        <div className="prod_attribute">
          {
            typeArr && typeArr.length ? <div>
              <div className="label">{`${getLabelsBasedOnLanguage('product.type', code)}`} <span className="req">
                *</span>
              </div>
              <CustomToggleButtonType
                onChange={handleTypeChange}
                selected={selectedType}
                buttons={typeArr}
                theme={props.theme}
              />
            </div> : null
          }
          {
            sizeArr && sizeArr.length ? <div>
              <div className="label">{`${getLabelsBasedOnLanguage('product.size', code)}`} <span className="req">
                *</span>
              </div>
              <CustomToggleButton
                onChange={handleSizeChange}
                selected={selectedSize}
                buttons={sizeArr}
                theme={props.theme}
              />
            </div> : null
          }
        </div>
        <CustomDivider
          color={'#a9a6a6'}
        />
        <div className="prod_order">
          <StyledToggleButton >
            <div className="toggle-btn price">
              €{(product.price + selectedSize.price + selectedType.price).toFixed(2)}
            </div>
          </StyledToggleButton>
          <StyledToggleButton >
            <div className="toggle-btn selected" onClick={() => onOrderClick()}>
              {`${getLabelsBasedOnLanguage('orderLabel', code)}`}
            </div>
          </StyledToggleButton>
        </div>
      </Card>
    </ProductCardStyled>
  )
})

ProductCard.propTypes = {
  selected: Proptypes.object,
  theme: Proptypes.object,
  OnChange: Proptypes.func,
  product: Proptypes.object,
  code: Proptypes.string,
}

export default ProductCard
