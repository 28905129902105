import * as masterServices from '../../services/masterServices'
import * as actionTypes from './actionsType'

const getCountryListRequest = () => {
  return {
    type: actionTypes.GET_COUNTRY_REQUEST,
  }
}

const getCountryListSuccess = (countries) => {
  return {
    type: actionTypes.GET_COUNTRY_SUCCESS,
    payload: countries,
  }
}

const getCountryListFailure = (error) => {
  return {
    type: actionTypes.GET_COUNTRY_FAILURE,
    payload: error,
  }
}

const getCityListRequest = () => {
  return {
    type: actionTypes.GET_CITY_REQUEST,
  }
}

const getCityListSuccess = (cities) => {
  return {
    type: actionTypes.GET_CITY_SUCCESS,
    payload: cities,
  }
}

const getCityListFailure = (error) => {
  return {
    type: actionTypes.GET_CITY_FAILURE,
    payload: error,
  }
}

const getPostalCodesRequest = () => {
  return {
    type: actionTypes.GET_POSTAL_CODE_REQUEST,
  }
}

const getPostalCodesSuccess = (cities) => {
  return {
    type: actionTypes.GET_POSTAL_CODE_SUCCESS,
    payload: cities,
  }
}

const getPostalCodesFailure = (error) => {
  return {
    type: actionTypes.GET_POSTAL_CODE_FAILURE,
    payload: error,
  }
}

const getLanguagesRequest = () => {
  return {
    type: actionTypes.GET_LANGUAGES_REQUEST,
  }
}

const getLanguagesSuccess = (languages) => {
  return {
    type: actionTypes.GET_LANGUAGES_SUCCESS,
    payload: languages,
  }
}

const getLanguagesFailure = (error) => {
  return {
    type: actionTypes.GET_LANGUAGES_FAILURE,
    payload: error,
  }
}

const getRestroTimingsRequest = () => {
  return {
    type: actionTypes.GET_RESTRO_TIMING_REQUEST,
  }
}

const getRestroTimingsSuccess = (languages) => {
  return {
    type: actionTypes.GET_RESTRO_TIMING_SUCCESS,
    payload: languages,
  }
}

const getRestroTimingsFailure = (error) => {
  return {
    type: actionTypes.GET_RESTRO_TIMING_FAILURE,
    payload: error,
  }
}

export const getCountryList = (lang) =>
  async(dispatch) => {
    dispatch(getCountryListRequest())
    try {
      const responseData = await masterServices.getCountries(lang)
      if (responseData?.status?.toUpperCase() === 'SUCCESS') {
        dispatch(getCountryListSuccess(responseData.data))
      } else {
        dispatch(getCountryListFailure(responseData))
      }
    } catch (error) {
      dispatch(getCountryListFailure(error))
    }
  }

export const getCityList = (lang) =>
  async(dispatch) => {
    dispatch(getCityListRequest())
    try {
      const responseData = await masterServices.getCities(lang)
      if (responseData?.status?.toUpperCase() === 'SUCCESS') {
        dispatch(getCityListSuccess(responseData.data))
      } else {
        dispatch(getCityListFailure(responseData))
      }
    } catch (error) {
      dispatch(getCityListFailure(error))
    }
  }

export const getPostalCodes = (lang) =>
  async(dispatch) => {
    dispatch(getPostalCodesRequest())
    try {
      const responseData = await masterServices.getPostalCodes(lang)
      if (responseData?.status?.toUpperCase() === 'SUCCESS') {
        dispatch(getPostalCodesSuccess(responseData.user.post_code))
      } else {
        dispatch(getPostalCodesFailure(responseData))
      }
    } catch (error) {
      dispatch(getPostalCodesFailure(error))
    }
  }

export const getLanguagesList = () =>
  async(dispatch) => {
    dispatch(getLanguagesRequest())
    try {
      const responseData = await masterServices.getLanguagesList()
      if (responseData?.status?.toUpperCase() === 'SUCCESS') {
        dispatch(getLanguagesSuccess(responseData.languages))
      } else {
        dispatch(getLanguagesFailure(responseData))
      }
    } catch (error) {
      dispatch(getLanguagesFailure(error))
    }
  }

export const getRestroTimings = (lang) =>
  async(dispatch) => {
    dispatch(getRestroTimingsRequest())
    try {
      const responseData = await masterServices.getRestroTimings(lang)
      if (responseData?.status?.toUpperCase() === 'SUCCESS') {
        dispatch(getRestroTimingsSuccess(responseData.timings))
      } else {
        dispatch(getRestroTimingsFailure(responseData))
      }
    } catch (error) {
      dispatch(getRestroTimingsFailure(error))
    }
  }
