import React from 'react'
import PropTypes from 'prop-types'
import {Snackbar, IconButton} from '@material-ui/core'
import {Close as CloseIcon} from '@material-ui/icons'
import {Alert} from '@material-ui/lab'
import {ErrorTimeOut} from '../../../../constants/constant'
import StyledSnackbar from './snackbar.styles'

const SnackbarComponent = (props) => {
  const {message, severity, open} = props
  const handleClose = () => {
    props.close()
  }
  const closeBtn = () => {
    return (
      <>
        <IconButton
          aria-label="close"
          color="inherit"
          className="close"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </>
    )
  }
  return (
    <StyledSnackbar>
      <Snackbar open={open} autoHideDuration={ErrorTimeOut} onClose={handleClose} action={closeBtn()}>
        <Alert severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </StyledSnackbar>
  )
}

SnackbarComponent.propTypes = {
  message: PropTypes.string,
  severity: PropTypes.string,
  duration: PropTypes.number,
  open: PropTypes.bool,
  close: PropTypes.func,
}

export default SnackbarComponent
