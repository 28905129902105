import styled from 'styled-components'

const MyDiv = styled.div `
text-align: left;
padding: 80px 20px 80px 0;
.contactUsTitle{
  font-size: 20px;
  margin-left: 20px;
  @media(max-width:959px){
    padding: 0 20px;
  }
}
.paragraph{
  height: fit-content;
  padding: 2rem;
  background: rgb(255, 255, 255);
  border: 0.05rem solid rgb(232, 232, 232);
  box-sizing: border-box;
  box-shadow: rgb(28 28 28 / 8%) 0px 0.4rem 0.8rem;
  border-radius: 1.2rem;
  width: fit-content;
  margin-top: 30px;
  margin-left: 20px;
  div{
    margin-bottom: 10px;
    font-size:16px;
    span{
      font-weight: 600;
    }
  }
}
.google-map-code{
  height: 100%;
  width: 100%;
  iframe{
    width: inherit;
    height: inherit;
  }
}
`
export default MyDiv
