import styled from 'styled-components'

const SignUpFormStyled = styled.div`
    .forgot_password {
        text-decoration: underline;
        cursor: pointer;
    }

    .center_element {
        text-align: center;
    }
    .error{
        display: block;
        color: #ff0000;
        text-align: left;
        font-size: 11px;
    }
    .spinner {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: #bdbdbda6;
        z-index: 1000;
      }
`

export default SignUpFormStyled
