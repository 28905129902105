import styled from 'styled-components'

// ${props => props.theme.main}

const BannerStyled = styled.div`
.bannerImg{
  width: 100%;
  @media(max-width: 767px){
    height: auto;
  }
  height: 600px;
}
`

export default BannerStyled
