import styled from 'styled-components'

const StyledLanguageComponent = styled.div`
   .lang_label {
       padding: 10px;
    }
    .lang_btn:hover {
        background: white;
    }

`

export default StyledLanguageComponent

