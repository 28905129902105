import apiInstance from '../config/api/axios'
import {setToken, getToken, removeToken} from '../utilities/authUtils'

export const categories = async(lang) => {
  const config = {
    header: {
      accept: 'application/json',
    },
  }

  const response = await apiInstance.get(`categories?lang=${lang || 'en'}`, config)
  return response
}


export const products = async(categoryID, lang) => {
  const config = {
    header: {
      accept: 'application/json',
    },
  }

  const response = await apiInstance.get(`product?category_id=${categoryID}&lang=${lang || 'en'}`, config)
  return response
}

export const createCart = async(cartData, lang) => {
  const config = {
    header: {
      accept: 'application/json',
    },
  }
  const data = new FormData()
  data.append('type_of_order', cartData.type_of_order)
  data.append('product_id', cartData.product_id)
  data.append('category_id', cartData.category_id)
  data.append('category_type_price_id', cartData.category_type_price_id)
  data.append('category_size_price_id', cartData.category_size_price_id)
  data.append('quantity', cartData.quantity)
  data.append('payment_type', cartData.payment_type)
  for (let i = 0; i < cartData.attributes.length; i++) {
    data.append('category_attributes_id[]', cartData.attributes[i].attribute_id)
  }
  const cartId = getToken('cart')
  if (cartId) {
    data.append('cart_id', cartId)
  }
  const response = await apiInstance.post(`carts?lang=${lang || 'en'}`, data, config)
  if (response.status === 'success') {
    setToken('cart', response.id)
  }
  return response
}

export const getCart = async(lang) => {
  const cartId = getToken('cart')
  if (cartId) {
    const config = {
      header: {
        accept: 'application/json',
      },
    }
    const response = await apiInstance.get(`carts/list/${cartId}?lang=${lang || 'en'}`, config)
    return response
  } else {
    return {}
  }
}

export const deleteCartItem = async(itemId, lang) => {
  const cartId = getToken('cart')
  if (cartId) {
    const config = {
      header: {
        accept: 'application/json',
      },
    }
    const response = await apiInstance.delete(`cart/${cartId}/item/${itemId}?lang=${lang || 'en'}`, config)
    return response
  } else {
    return {}
  }
}

export const deleteCart = async(lang) => {
  const cartId = getToken('cart')
  if (cartId) {
    const config = {
      header: {
        accept: 'application/json',
      },
    }
    const response = await apiInstance.delete(`carts/${cartId}?lang=${lang || 'en'}`, config)
    if (response.status === 'success') {
      removeToken('cart')
    }
    return response
  } else {
    return {}
  }
}

export const updateCartItem = async(cartData, lang) => {
  const cartId = getToken('cart')
  if (!cartId) {
    throw new Error('No cart found. Create Cart first.')
  }
  const config = {
    header: {
      'accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  }
  let data = new URLSearchParams()
  data.append('type_of_order', cartData.type_of_order)
  data.append('quantity', cartData.quantity)
  for (let i = 0; i < cartData.attributes.length; i++) {
    data.append('category_attributes_id[]', cartData.attributes[i].id)
  }
  const response = await apiInstance.put(`carts/${cartId}/${cartData.cartItemId}?lang=${lang || 'en'}`, data, config)
  return response
}

export const assignCartToUser = async(lang) => {
  const cartId = getToken('cart')
  if (cartId) {
    const response = await apiInstance.get(`cart/assign/${cartId}?lang=${lang || 'en'}`)
    return response
  }
  return {}
}


export const updateDeliveryOption = async(typeOfOrder, lang) => {
  const cartId = getToken('cart')
  if (cartId) {
    const config = {
      header: {
        'accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
    let data = new URLSearchParams()
    data.append('cart_id', cartId)
    data.append('type_of_order', typeOfOrder)
    const response = await apiInstance.put(`carts/order-type?lang=${lang || 'en'}`, data, config)
    return response
  }
  return {}
}

export const updatePaymentOption = async(paymentType, lang) => {
  const cartId = getToken('cart')
  if (cartId) {
    const config = {
      header: {
        'accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
    let data = new URLSearchParams()
    data.append('cart_id', cartId)
    data.append('payment_type', paymentType)
    const response = await apiInstance.put(`carts/payment-type?lang=${lang || 'en'}`, data, config)
    return response
  }
  return {}
}

export const updateComments = async(comment, lang) => {
  const cartId = getToken('cart')
  if (cartId) {
    const config = {
      header: {
        'accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
    let data = new URLSearchParams()
    data.append('cart_id', cartId)
    data.append('comment', comment)
    const response = await apiInstance.put(`carts/comment?lang=${lang || 'en'}`, data, config)
    return response
  }
  return {}
}

export const updateCartAddress = async(address, lang) => {
  const cartId = getToken('cart')
  if (cartId) {
    const config = {
      header: {
        'accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
    let data = new URLSearchParams()
    data.append('cart_id', cartId)
    data.append('address_id', address.id)
    const response = await apiInstance.put(`carts/address/change?lang=${lang || 'en'}`, data, config)
    return response
  } else {
    return {}
  }
}

export const placeOrder = async(lang) => {
  const cartId = getToken('cart')
  if (cartId) {
    const config = {
      header: {
        accept: 'application/json',
      },
    }
    let data = new FormData()
    data.append('cart_id', cartId)
    const response = await apiInstance.post(`user/order-process?lang=${lang || 'en'}`, data, config)
    if (response.status && response.status.toUpperCase() === 'SUCCESS') {
      removeToken('cart')
    }
    return response
  } else {
    return {}
  }
}

export const checkPostalCode = async(postalCode, lang) => {
  const config = {
    header: {
      accept: 'application/json',
    },
  }
  const response = await apiInstance.get(`post-code/search?postcode=${postalCode}&lang=${lang || 'en'}`, config)
  return response
}

